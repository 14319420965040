<template>
  <section class="dados">
    <div class="quadro">
      <div class="icone">
        <Svgs nome="alunos" />
      </div>
      <div class="nome">
        <h3>{{ quantidadeAlunos }}</h3>
        <p>Alunos nessa área de membros</p>
        <button @click="router.push(`/areas/${storeAreas.area?.hotlink}/alunos`)">
          Ver alunos
          <Svgs nome="setinha" />
        </button>
      </div>
    </div>

    <div class="quadro">
      <div class="icone">
        <Svgs nome="webhook" />
      </div>
      <div class="nome">
        <h3>{{ quantidadeIntegracoes }}</h3>
        <p>Integrações nessa área de membros</p>
        <button @click="router.push(`/areas/${storeAreas.area?.hotlink}/integracoes`)">
          Ver integrações
          <Svgs nome="setinha" />
        </button>
      </div>
    </div>

    <div class="quadro">
      <div class="icone">
        <Svgs nome="sino" />
      </div>
      <div class="nome">
        <h3>{{ quantidadeNotificacoes }}</h3>
        <p>Notificações nessa área</p>
        <button @click="router.push(`/areas/${storeAreas.area?.hotlink}/notificacoes`)">
          Ver notificações
          <Svgs nome="setinha" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreAreas } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const router = useRouter()

const quantidadeAlunos = computed(() => {
  const quantidade = storeAreas.area?.quantidadeAlunos || 0
  return `${quantidade} ${quantidade === 1 ? 'aluno' : 'alunos'}`
})

const quantidadeIntegracoes = computed(() => {
  const quantidade = storeAreas.area?.quantidadeIntegracoes || 0
  return `${quantidade} ${quantidade === 1 ? 'integração' : 'integrações'}`
})

const quantidadeNotificacoes = computed(() => {
  const quantidade = storeAreas.area?.quantidadeNotificacoesNaoLidas || 0
  return `${quantidade} ${quantidade === 1 ? 'notificação não lida' : 'notificações não lidas'}`
})
</script>

<style scoped>
section.dados {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px 0 30px;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: calc(33.33% - 10px);
  background-color: var(--cor-branco);
  border-radius: 10px;
  padding: 40px;
  position: relative;
}

.quadro .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 20px;
  background: linear-gradient(0deg, var(--degrade-azul));
}

.quadro .icone svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-branco-fixo);
}

.quadro .nome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.quadro .nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-preto);
  margin: 20px 0 5px 0;
}

.quadro .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  width: 100%;
  margin: 0 0 20px 0;
}

.quadro button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  padding: 15px 15px 15px 20px;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-cinza-2);
  border-radius: 50px;
  transition: all 0.3s;
}

.quadro button:hover {
  border: 2px solid var(--cor-azul-forte);
}

.quadro button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-cinza);
  margin: 0 0 0 10px;
}

@media screen and (max-width: 1000px) {
  section.dados {
    flex-direction: column;
    padding: 0 20px 0 20px;
  }

  .quadro {
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 10px;
    padding: 20px 20px;
    width: 100%;
    margin: 0 0 20px 0;
  }

  .quadro .icone {
    width: 60px;
    min-width: 60px;
    height: 80px;
    border-radius: 20px;
  }

  .quadro .icone svg {
    width: 25px;
    min-width: 25px;
  }

  .quadro .nome {
    align-items: flex-start;
    text-align: left;
    padding: 0 0 0 15px;
  }

  .quadro .nome h3 {
    font-size: var(--f2);
    margin: 0 0 5px 0;
  }

  .quadro .nome p {
    font-size: var(--f1);
    max-width: 300px;
    margin: 0 0 10px 0;
  }

  .quadro button {
    padding: 10px 10px 10px 15px;
    font-size: var(--f1);
  }

  .quadro button svg {
    width: 12px;
    min-width: 12px;
  }
}
</style>
