<template>
  <section class="topo">
    <div class="titulo">
      <div class="icone">
        <Svgs nome="sino" />
      </div>
      <div class="nome">
        <h2>Notificações</h2>
        <p>{{ textoNotificacoes }}</p>
      </div>
    </div>
    <div class="opcoes">
      <BotaoPerfil />
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStorePainel } from '@stores'
import Svgs from '@svgs'
import BotaoPerfil from '@components/global/elementos/BotaoPerfil.vue'

const storePainel = useStorePainel()

const textoNotificacoes = computed(() => {
  const quantidade = storePainel.estatisticas?.quantidadeNotificacoesNaoLidas || 0
  return `${quantidade} ${quantidade === 1 ? 'notificação' : 'notificações'} ${quantidade === 1 ? 'não lida' : 'não lidas'}`
})
</script>

<style scoped>
section.topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
}

.titulo {
  display: flex;
  align-items: center;
}

.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid var(--cor-cinza-3);
  background-color: var(--cor-branco);
  margin: 0 15px 0 0;
}

.titulo .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-preto);
}

.titulo .nome h2 {
  font-family: var(--estilo);
  font-size: var(--f3);
  color: var(--cor-preto);
  text-transform: uppercase;
}

.titulo .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}

.opcoes {
  display: flex;
  align-items: center;
}

.notificacao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--cor-branco);
  margin: 0 0 0 15px;
  position: relative;
}

.notificacao svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-cinza);
  transition: all 0.3s;
}

.notificacao:hover svg {
  fill: var(--cor-roxo);
}

.notificacao.nova span {
  opacity: 1;
  visibility: visible;
}

.notificacao span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--cor-vermelho);
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0;
  visibility: hidden;
  animation: piscandoSpan 1s ease-in-out infinite;
  transition: all 0.3s;
}

@keyframes piscandoSpan {
  0% {
    box-shadow: 0 0 5px 0 var(--cor-vermelho);
    transform: scale(1);
  }

  50% {
    box-shadow: 0 0 15px 0 var(--cor-vermelho);
    transform: scale(1.1);
  }

  100% {
    box-shadow: 0 0 15px 0 var(--cor-vermelho);
    transform: scale(1);
  }
}

@media screen and (max-width: 1000px) {
  section.topo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
  }

  .titulo {
    display: flex;
    align-items: center;
  }

  .titulo .icone {
    width: 35px;
    height: 35px;
    margin: 0 10px 0 0;
  }

  .titulo .icone svg {
    width: 15px;
    min-width: 15px;
  }

  .titulo .nome h2 {
    font-size: var(--f2);
  }

  .titulo .nome p {
    font-size: var(--f1);
    margin: 5px 0 0 0;
  }

  .notificacao {
    width: 40px;
    height: 40px;
    margin: 0 0 0 10px;
  }

  .notificacao svg {
    width: 20px;
    min-width: 20px;
  }
}
</style>
