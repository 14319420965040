<template>
  <section class="topo">
    <div class="icones">
      <Svgs nome="icone"/>
      <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || ''}')`"></div>
    </div>
    <h2>Planos Level Member</h2>
    <p>Escolha abaixo o plano ideal para o seu negócio</p>
  </section>
</template>

<script setup>
import { useStorePerfil } from '@stores'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()
</script>

<style scoped>
section.topo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 60px 30px 30px 30px;
  background-color: var(--cor-gelo);
  z-index: 2;
}

.icones {
  display: flex;
  align-items: center;
}

.icones .foto {
  width: 45px;
  min-width: 45px;
  height: 45px;
  min-height: 45px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.icones svg {
  width: 35px;
  min-width: 35px;
  margin: 0 25px 0 0;
}

h2 {
  font-family: var(--estilo);
  font-size: var(--f5);
  color: var(--cor-preto);
  text-transform: uppercase;
  margin: 20px 0 10px 0;
}

p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

@media screen and (max-width: 1000px) {
  section.topo {
    padding: 40px 20px 20px 20px;
  }

  .icones .foto {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
  }

  .icones svg {
    width: 30px;
    min-width: 30px;
    margin: 0 20px 0 0;
  }

  h2 {
    font-size: var(--f3);
    margin: 20px 0 10px 0;
  }

  p {
    font-size: var(--f1);
  }
}
</style>
