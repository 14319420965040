<template>
  <Modal nome="editarCurso">
    <label>
      Nome do curso
      <span>*</span>
    </label>
    <input v-model="state.nome" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Curso Marketing" @keydown.enter="criarCurso" />

    <label>
      Descrição do curso
      <span>*</span>
    </label>
    <input v-model="state.descricao" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Curso com foco em marketing" @keydown.enter="criarCurso" />

    <label>URL da página de vendas (opcional)</label>
    <div class="input">
      <Svgs class="icone" nome="link" />
      <input v-model="state.urlPaginaVenda" type="url" spellCheck="false" autocomplete="false" placeholder="https://link.com" @keydown.enter="criarCurso" />
    </div>

    <label>
      URL caso esteja bloqueado
      <span>*</span>
    </label>
    <div class="inputInstrucao">
      <p>Caso o aluno não tenha acesso a esse curso, defina o link para o qual ele deve ir ao clicar no curso</p>
      <div class="input">
        <Svgs class="icone" nome="link" />
        <input v-model="state.urlBloqueio" type="url" spellCheck="false" autocomplete="false" placeholder="https://link.com" @keydown.enter="criarCurso" />
      </div>
    </div>

    <label>
      Imagem de capa do curso
      <span>*</span>
    </label>
    <div class="inputFile">
      <button @click="carregarImagem()" :disabled="state.uploadImagem.desabilitarBotao">
        <span></span>
        <Svgs nome="arquivo" />
        <h3>Subir Imagem</h3>
        <p>Tamanho: 600x200</p>
        <UploadImagem :dados="state.uploadImagem" ref="uploadImagem" @atualizarDadosImagem="atualizarDadosImagem($event)" />
      </button>
      <div class="previa" :style="`background-image: url('${state.imagemCapa || ''}')`"></div>
    </div>

    <label class="link">
      Imagem do certificado (opcional)
      <button>
        <Svgs nome="download" />
        Baixar Modelo
      </button>
    </label>
    <div class="inputFile">
      <button @click="carregarImagemCertificado()" :disabled="state.uploadImagemCertificado.desabilitarBotao">
        <span></span>
        <Svgs nome="arquivo" />
        <h3>Subir Imagem</h3>
        <p>Tamanho: 600x200</p>
        <UploadImagem :dados="state.uploadImagemCertificado" ref="uploadImagemCertificado" @atualizarDadosImagem="atualizarDadosImagemCertificado($event)" />
      </button>
      <div class="previa" :style="`background-image: url('${state.imagemCertificado || ''}')`"></div>
    </div>

    <SalvarModal nome="Salvar Alterações" @click="salvarDados()" />

    <ExclusaoModal tipo="curso" @confirmarExclusao="excluirCurso()" />
  </Modal>
</template>

<script setup>
import { inject, reactive, ref, onMounted, watch, computed } from 'vue'
import { useStoreAreas, useStoreCursos, useStoreModal } from '@stores'
import { helperTexto } from '@helpers'

import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import ExclusaoModal from '@components/global/elementos/ExclusaoModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import UploadImagem from '@components/global/upload/image.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('editarCurso'))
const uploadImagem = ref(null)
const uploadImagemCertificado = ref(null)

const state = reactive({
  nome: '',
  descricao: '',
  hotlink: '',
  imagemCapa: '',
  imagemCertificado: '',
  urlPaginaVenda: '',
  urlBloqueio: '',
  uploadImagem: {
    tamanhoMaximo: 1920,
    urlImagem: '',
    desabilitarBotao: false
  },
  uploadImagemCertificado: {
    tamanhoMaximo: 1920,
    urlImagem: '',
    desabilitarBotao: false
  }
})

function atualizarDadosImagem(dados) {
  if (typeof dados.desabilitarBotao === 'boolean') state.uploadImagem.desabilitarBotao = dados.desabilitarBotao
  if (typeof dados.urlImagem === 'string') {
    state.uploadImagem.urlImagem = dados.urlImagem
    state.imagemCapa = state.uploadImagem.urlImagem
  }
}

function carregarImagem() {
  uploadImagem?.value?.enviarImagem()
}

function atualizarDadosImagemCertificado(dados) {
  if (typeof dados.desabilitarBotao === 'boolean') state.uploadImagemCertificado.desabilitarBotao = dados.desabilitarBotao
  if (typeof dados.urlImagem === 'string') {
    state.uploadImagemCertificado.urlImagem = dados.urlImagem
    state.imagemCertificado = state.uploadImagemCertificado.urlImagem
  }
}

function carregarImagemCertificado() {
  uploadImagemCertificado?.value?.enviarImagem()
}

function nomeParaHotlink() {
  state.hotlink = helperTexto.converterEmHotlink(state.nome)
}

async function salvarDados() {
  if (!validarDados()) return

  nomeParaHotlink()

  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id

  const payload = {
    nome: state.nome,
    descricao: state.descricao,
    hotlink: state.hotlink,
    imagemCapa: state.imagemCapa,
    imagemCertificado: state.imagemCertificado,
    urlPaginaVenda: state.urlPaginaVenda,
    urlBloqueio: state.urlBloqueio
  }

  const editadoOk = await storeCursos.alterarDados(idArea, idCurso, payload)
  if (editadoOk !== true) return

  emitter.emit('atualizarCursos')
  storeModal.fecharModal('editarCurso')
  limparCampos()
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.descricao || !state.urlBloqueio || !state.imagemCapa) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

async function excluirCurso() {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const delecaoOk = await storeCursos.deletar(idArea, idCurso)
  if (delecaoOk !== true) return

  emitter.emit('atualizarCursos')
  storeModal.fecharModal('editarCurso')
}

function limparCampos() {
  state.nome = ''
  state.descricao = ''
  state.hotlink = ''
  state.urlPaginaVenda = ''
  state.urlBloqueio = ''
  state.imagemCapa = ''
  state.imagemCertificado = ''
}

function preencherDados() {
  state.nome = storeCursos.curso?.nome
  state.descricao = storeCursos.curso?.descricao
  state.hotlink = storeCursos.curso?.hotlink
  state.urlPaginaVenda = storeCursos.curso?.urlPaginaVenda
  state.urlBloqueio = storeCursos.curso?.urlBloqueio
  state.imagemCapa = storeCursos.curso?.imagemCapa
  state.imagemCertificado = storeCursos.curso?.imagemCertificado
}

async function pegarIdCurso() {
  const idArea = storeAreas.area?._id
  if (dadosModal.value.id) {
    await storeCursos.receberPorId(idArea, dadosModal.value.id)
    preencherDados()
  }
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarIdCurso()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

label.link {
  display: flex;
  align-items: center;
}

label button {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-roxo);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  opacity: 0.6;
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-roxo);
  margin: 0 5px 0 0;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

.excluirModal {
  margin: 30px 0 0 0;
}
</style>
