<template>
  <section class="limites">
    <div class="assinatura">
      <div class="icone">
        <img
          :src="`https://arquivos.levelmember.com/imagens/planos/plano-${storePerfil.usuario?.assinatura?.plano || 'default'}.png`">
      </div>
      <div class="nome">
        <h3>Plano {{ storePerfil.usuario?.assinatura?.plano }}</h3>
        <p>Assinatura {{ storePerfil.usuario?.assinatura?.frequencia }}</p>
      </div>
      <div class="areas">
        <p>{{ storeAreas.areas?.length }} / {{ storePerfil.limiteAreas }} <span>áreas de membros</span></p>
        <div class="progresso">
          <div class="atual" :style="{ width: progresso + '%' }"></div>
        </div>
      </div>
      <button @click="abrirModal(
        'criarArea',
        'degrade',
        'camadas',
        'Criar área',
        'Preencha os dados e crie sua área'
      )">
        <Svgs nome="mais" />
        <p>Criar nova área de membros</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { watch, computed } from 'vue'
import { useStoreModal, useStoreAreas, useStorePerfil } from '@stores'
import Svgs from '@svgs'

const storeModal = useStoreModal()
const storeAreas = useStoreAreas()
const storePerfil = useStorePerfil()

function abrirModal(nome, cor, icone, titulo, subtitulo) {
  storeModal.abrirModal(nome, cor, icone, titulo, subtitulo)
}

function carregarLimites(plano) {
  if (plano === 'essencial') {
    storePerfil.limiteAlunos = '1.200'
    storePerfil.limiteAreas = '1'
  } else if (plano === 'pro') {
    storePerfil.limiteAlunos = '5.000'
    storePerfil.limiteAreas = '3'
  } else if (plano === 'expert') {
    storePerfil.limiteAlunos = '25.000'
    storePerfil.limiteAreas = '15'
  } else if (plano === 'free') {
    storePerfil.limiteAlunos = '250'
    storePerfil.limiteAreas = '1'
  }
}

const progresso = computed(() => {
  const totalAreas = parseInt(storePerfil.limiteAreas);
  const areasUsadas = storeAreas.areas?.length || 0;

  return totalAreas > 0 ? (areasUsadas / totalAreas) * 100 : 0;
});

watch(
  () => storePerfil.usuario?.assinatura?.plano,
  (novoPlano) => {
    if (novoPlano) {
      carregarLimites(novoPlano);
    }
  },
  { immediate: true }
);
</script>

<style scoped>
section.limites {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 30px 20px 30px;
}

.assinatura {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  background-color: var(--cor-branco);
  padding: 20px;
}

.assinatura .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-cinza-3);
}

.assinatura .icone img {
  width: 100%;
  max-width: 30px;
}

.assinatura .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
}

.assinatura .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
}

.assinatura .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}

.assinatura .areas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 30px 0 0;
  border-right: 1px solid var(--cor-cinza-2);
}

.assinatura .areas p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 0 0 5px 0;
}

.assinatura .areas .progresso {
  width: 100%;
  min-width: 200px;
  height: 10px;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
}

.assinatura .areas .progresso .atual {
  height: 10px;
  width: 20%;
  background: linear-gradient(90deg, var(--degrade));
  border-radius: 10px;
}

.assinatura button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  border-radius: 10px;
  background-color: var(--cor-azul-forte);
  margin: 0 0 0 20px;
  transition: all 0.3s;
}

.assinatura button:hover {
  background-color: var(--cor-azul-forte-escuro);
}

.assinatura button svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}

.assinatura button p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

@media screen and (max-width: 1000px) {
  section.limites {
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }

  .assinatura {
    flex-wrap: wrap;
    padding: 20px;
  }

  .assinatura .icone {
    width: 50px;
    height: 50px;
  }

  .assinatura .icone img {
    max-width: 25px;
  }

  .assinatura .nome {
    padding: 0 0 0 20px;
  }

  .assinatura .areas {
    flex-direction: row;
    height: auto;
    width: 100%;
    padding: 20px 0 0 0;
    margin: 0;
    border-right: none;
  }

  .assinatura .areas p {
    display: flex;
    justify-content: space-between;
    font-size: var(--f1);
    line-height: 1;
    text-align: center;
    min-width: 160px;
    padding: 0 15px 0 0;
    margin: 0;
  }

  .assinatura .areas .progresso {
    width: 100%;
    min-width: auto;
  }

  .assinatura button {
    padding: 13px 18px;
    margin: 15px 0 0 0;
  }

  .assinatura button svg {
    width: 15px;
    min-width: 15px;
    margin: 0 7px 0 0;
  }

  .assinatura button p {
    font-size: var(--f1);
  }

}
</style>
