<template>
  <section class="topo">
    <div class="titulo">
      <div class="icone">
        <Svgs nome="alunos" />
      </div>
      <div class="nome">
        <h2>Seus alunos</h2>
        <p>{{ storeAlunos.alunos?.length }} {{ textoAlunos(storeAlunos.alunos?.length) }} nessa área de membros</p>
      </div>
    </div>
    <div class="opcoes" ref="lista">
      <button class="adicionar" @click="state.listaOpcoes = !state.listaOpcoes">
        <Svgs nome="setinha" />
        <p>Criar aluno</p>
      </button>
      <div class="lista" :class="{ mostrar: state.listaOpcoes }">
        <button
          @click="
            abrirModal(
              'criarAluno', // nome do modal
              'degrade-azul', // cor do icone
              'mais', // nome do icone
              'Criar aluno', // titulo
              'Cadastre um aluno manualmente' // subtitulo
            )
          ">
          <Svgs nome="mais" />
          <p>Criar manualmente</p>
        </button>
        <button
          @click="
            abrirModal(
              'importarAlunos', // nome do modal
              'degrade-azul', // cor do icone
              'exportar', // nome do icone
              'Importar alunos', // titulo
              'Importe alunos de outro local' // subtitulo
            )
          ">
          <Svgs nome="exportar" />
          <p>Importar alunos</p>
        </button>
        <button
          class="breve"
          @click="
            abrirModal(
              'exportarAlunos', // nome do modal
              'degrade-azul', // cor do icone
              'download', // nome do icone
              'Exportar alunos', // titulo
              'Exporte seus alunos da Level' // subtitulo
            )
          ">
          <Svgs nome="download" />
          <p>Exportar alunos</p>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { helperClicouFora } from '@helpers'
import { useStoreModal, useStoreAlunos } from '@stores'
import { reactive, ref } from 'vue'
import Svgs from '@svgs'

const state = reactive({
  listaOpcoes: false
})

const lista = ref(null)

helperClicouFora.clicouFora([{ alvo: lista, retornoChamada: () => (state.listaOpcoes = false) }])

const storeModal = useStoreModal()
const storeAlunos = useStoreAlunos()

const textoAlunos = (quantidade) => {
  return `${quantidade === 1 ? 'aluno' : 'alunos'}`
}

function abrirModal(nome, cor, icone, titulo, subtitulo) {
  storeModal.abrirModal(nome, cor, icone, titulo, subtitulo)
}
</script>

<style scoped>
section.topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
}

.titulo {
  display: flex;
  align-items: center;
}

.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid var(--cor-cinza-3);
  background-color: var(--cor-branco);
  margin: 0 15px 0 0;
}

.titulo .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-preto);
}

.titulo .nome h2 {
  font-family: var(--estilo);
  font-size: var(--f3);
  color: var(--cor-preto);
  text-transform: uppercase;
}

.titulo .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}

.opcoes {
  display: flex;
  align-items: center;
  position: relative;
}

.opcoes .lista {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 60px;
  right: 0;
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  border-radius: 10px;
  background-color: var(--cor-branco);
  box-shadow: 0 5px 10px 0 var(--cor-gelo);
  padding: 10px 20px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: scale(0.9);
  transition: all 0.3s;
}

.opcoes .lista.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: scale(1);
}

.opcoes .lista button {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px 0;
}

.opcoes .lista button.breve {
  opacity: 0.4;
  pointer-events: none;
}

.opcoes .lista button:hover p {
  color: var(--cor-azul-forte);
}

.opcoes .lista button:hover svg {
  fill: var(--cor-azul-forte);
}

.opcoes .lista button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.opcoes .lista button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  transition: all 0.3s;
}

.opcoes button.adicionar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  border-radius: 10px;
  background-color: var(--cor-azul-forte);
  margin: 0 0 0 20px;
  transition: all 0.3s;
}

.opcoes button.adicionar:hover {
  background-color: var(--cor-azul-forte-escuro);
}

.opcoes button.adicionar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  transform: rotate(90deg);
  margin: 0 10px 0 0;
}

.opcoes button.adicionar p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

.opcoes button.tutorial {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: var(--cor-branco);
  margin: 0 0 0 15px;
  padding: 15px 20px;
  position: relative;
}

.opcoes button.tutorial p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-preto);
}

.opcoes button.tutorial svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza);
  transition: all 0.3s;
  margin: 0 10px 0 0;
}

.opcoes button.tutorial:hover svg {
  fill: var(--cor-roxo);
}

@media screen and (max-width: 1000px) {
  section.topo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
  }

  .titulo {
    display: flex;
    align-items: center;
  }

  .titulo .icone {
    width: 35px;
    height: 35px;
    margin: 0 10px 0 0;
  }

  .titulo .icone svg {
    width: 15px;
    min-width: 15px;
  }

  .titulo .nome h2 {
    font-size: var(--f2);
  }

  .titulo .nome p {
    font-size: var(--f1);
    margin: 5px 0 0 0;
  }

  .opcoes {
    padding: 20px 0 0 0;
  }

  .opcoes .lista {
    top: 70px;
    right: auto;
    left: 0;
  }

  .opcoes button.adicionar {
    padding: 13px 20px;
    margin: 0;
  }

  .opcoes button.adicionar p {
    font-size: var(--f1);
  }

  .opcoes button.adicionar svg {
    margin: 0 8px 0 0;
    width: 13px;
    min-width: 13px;
  }

  .opcoes button.tutorial {
    margin: 0 0 0 10px;
    padding: 13px 20px;
  }

  .opcoes button.tutorial svg {
    width: 13px;
    min-width: 13px;
    margin: 0 8px 0 0;
  }
}
</style>
