<template>
  <section class="modulos">
    <div class="elemento" v-for="modulo in storeModulos.modulos" :key="modulo._id">
      <div class="modulo">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="`background-image: url('${modulo.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`"></div>
          </div>
        </div>
        <div class="info">
          <h5>{{ modulo.posicao }}º</h5>
          <div class="nome">
            <h3>{{ modulo.nome }}</h3>
            <p>{{ modulo.descricao }}</p>
          </div>
          <div class="item" v-if="modulo.quantidadeAulas">
            <Svgs nome="play" />
            <p>{{ formatarQuantidadeAulas(modulo.quantidadeAulas) }}</p>
          </div>
          <div class="item" v-if="modulo.totalDuracao">
            <Svgs nome="relogio" />
            <p>{{ formatarDuracao(modulo.totalDuracao) }} de conteúdo</p>
          </div>
          <div class="botoes">
            <button
              class="editar"
              @click="
                abrirModalEdicao(
                  'editarModulo', // nome do modal
                  'degrade-azul', // cor do icone
                  'editar', // nome do icone
                  'Editar Módulo', // titulo
                  modulo.nome, // subtitulo
                  modulo._id // ID
                )
              ">
              <p>Editar</p>
              <Svgs nome="editar" />
            </button>
            <button class="acessar" @click="router.push(`/areas/${storeAreas.area?.hotlink}/${storeCursos.curso?.hotlink}/${modulo.hotlink}`)">
              <p>Aulas</p>
              <Svgs nome="play" />
            </button>
            <button
              class="duplicar"
              @click="
                abrirModalEdicao(
                  'duplicarModulo', // nome do modal
                  'degrade-azul', // cor do icone
                  'duplicar', // nome do icone
                  'Duplicar Módulo', // titulo
                  'Crie uma cópia desse módulo', // subtitulo
                  modulo._id // ID
                )
              ">
              <p>Duplicar</p>
              <Svgs nome="duplicar" />
            </button>
          </div>
        </div>
      </div>
      <div class="caminho um">
        <span class="um"></span>
        <span class="dois"></span>
        <span class="tres"></span>
      </div>
      <div class="caminho dois">
        <span class="um"></span>
        <span class="dois"></span>
      </div>
      <div class="caminho tres">
        <span class="um"></span>
        <span class="dois"></span>
      </div>
    </div>
  </section>
</template>

<script setup>
import { inject, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreModal, useStoreAreas, useStoreCursos, useStoreModulos } from '@stores'
import Svgs from '@svgs'

const storeModulos = useStoreModulos()
const storeModal = useStoreModal()
const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const router = useRouter()
const emmiter = inject('emitter')

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}

function formatarDuracao(totalMinutos) {
  if (totalMinutos >= 60) {
    const horas = Math.floor(totalMinutos / 60)
    const minutos = totalMinutos % 60
    return minutos > 0 ? `${horas}h e ${minutos}min` : `${horas}h`
  }
  return `${totalMinutos}min`
}

function formatarQuantidadeAulas(totalAulas) {
  return `${totalAulas} aula${totalAulas > 1 ? 's' : ''}`
}

async function carregarModulos() {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id

  if (idArea && idCurso) {
    storeModulos.receberTodos(idArea, idCurso)
  }
}

watch(
  () => [storeAreas.area?._id, storeCursos.curso?._id],
  ([idArea, idCurso]) => {
    if (idArea && idCurso) {
      carregarModulos()
    }
  },
  { immediate: true }
)

onMounted(() => {
  emmiter.on('atualizarModulos', carregarModulos)
})
</script>

<style scoped>
section.modulos {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  padding: 10px 30px 30px 30px;
}

.elemento {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.elemento:nth-child(3n + 1) {
  justify-content: flex-start;
  width: 50%;
  padding: 150px 100px 0 0;
}

.elemento:nth-child(3n + 2) {
  justify-content: flex-end;
  width: 50%;
  padding: 150px 100px 0 0;
}

.elemento:nth-child(3n) {
  justify-content: center;
  width: 100%;
  padding: 250px 100px 100px 0;
}

.caminho {
  position: absolute;
  z-index: 1;
}

.elemento:nth-last-child(1) .caminho {
  display: none !important;
}

/* Caminho elementos A */

.elemento:nth-child(3n + 1) .caminho.dois {
  display: none;
}

.elemento:nth-child(3n + 1) .caminho.tres {
  display: none;
}

.elemento:nth-child(3n + 1) .caminho.um {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100dvw - 1015px);
  left: 350px;
  bottom: 100px;
}

.elemento:nth-child(3n + 1) .caminho.um span.um {
  width: 50%;
  height: 2px;
  background-color: transparent;
  border-top: 3px dashed var(--cor-cinza-2);
  margin: 0 0 100px 0;
}

.elemento:nth-child(3n + 1) .caminho.um span.dois {
  width: 2px;
  height: 100px;
  background-color: transparent;
  border-left: 3px dashed var(--cor-cinza-2);
}

.elemento:nth-child(3n + 1) .caminho.um span.tres {
  width: 50%;
  height: 2px;
  background-color: transparent;
  border-top: 3px dashed var(--cor-cinza-2);
  margin: 100px 0 0 0;
}

/* Caminho elementos B */

.elemento:nth-child(3n + 2) .caminho.um {
  display: none;
}

.elemento:nth-child(3n + 2) .caminho.tres {
  display: none;
}

.elemento:nth-child(3n + 2) .caminho.dois {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: calc(50dvw - 480px);
  right: 150px;
  bottom: -200px;
}

.elemento:nth-child(3n + 2) .caminho.dois span.um {
  width: 2px;
  height: 200px;
  background-color: transparent;
  border-left: 3px dashed var(--cor-cinza-2);
}

.elemento:nth-child(3n + 2) .caminho.dois span.dois {
  width: 100%;
  height: 2px;
  background-color: transparent;
  border-bottom: 3px dashed var(--cor-cinza-2);
}

/* Caminho elementos C */

.elemento:nth-child(3n) .caminho.um {
  display: none;
}

.elemento:nth-child(3n) .caminho.tres {
  display: none;
}

.elemento:nth-child(3n) .caminho.dois {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(50dvw - 480px);
  left: 255px;
  bottom: 0;
}

.elemento:nth-child(3n) .caminho.dois span.um {
  width: calc(100% - 105px);
  height: 2px;
  background-color: transparent;
  border-bottom: 3px dashed var(--cor-cinza-2);
}

.elemento:nth-child(3n) .caminho.dois span.dois {
  width: 2px;
  height: 200px;
  background-color: transparent;
  border-left: 3px dashed var(--cor-cinza-2);
}

.modulo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 270px;
}

.modulo .capa {
  width: 100%;
  max-width: 270px;
  position: absolute;
  top: -170px;
  right: -100px;
  z-index: 2;
}

.modulo .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 100%;
}

.modulo .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.modulo .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-branco);
  border-radius: 10px;
  width: 100%;
  z-index: 3;
  position: relative;
}

.modulo .info h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cor-gelo);
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-cinza);
  text-align: center;
}

.modulo .info .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.modulo .info .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
  line-height: 1.5;
}

.modulo .info .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
}

.modulo .info .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 20px 0 20px;
}

.modulo .info .item p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
}

.modulo .info .item svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza);
  margin: 0 5px 0 0;
}

.modulo .info .botoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px 20px 20px;
}

.modulo .info .botoes button {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  width: calc(33.33% - 2.5px);
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-cinza-2);
  padding: 12px 0;
  border-radius: 10px;
  transition: all 0.3s;
}

.modulo .info .botoes button:hover {
  border: 2px solid var(--cor-azul-forte);
}

.modulo .info .botoes button svg {
  width: 25px;
  min-width: 25px;
  max-height: 25px;
  fill: var(--cor-preto);
  margin: 0 0 10px 0;
}

.modulo .info .botoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-preto);
  transition: all 0.3s;
}

@media screen and (max-width: 1000px) {
  section.modulos {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 30px 20px;
  }

  .elemento:nth-child(1) {
    padding: 150px 60px 0 0 !important;
  }

  .elemento:nth-child(3n + 1) {
    justify-content: center;
    width: 100%;
    padding: 250px 60px 0 0;
  }

  .elemento:nth-child(3n + 2) {
    justify-content: center;
    width: 100%;
    padding: 250px 60px 0 0;
  }

  .elemento:nth-child(3n) {
    justify-content: center;
    width: 100%;
    padding: 250px 60px 0 0;
  }

  /* Caminho elementos A */

  .elemento:nth-child(3n + 1) .caminho.um {
    width: 100dvw;
    left: auto;
    right: -50px;
    bottom: -100px;
  }

  .elemento:nth-child(3n + 1) .caminho.um span.um {
    display: none;
  }

  .elemento:nth-child(3n + 1) .caminho.um span.dois {
    width: 2px;
    height: 100px;
    background-color: transparent;
  }

  .elemento:nth-child(3n + 1) .caminho.um span.tres {
    display: none;
  }

  /* Caminho elementos B */

  .elemento:nth-child(3n + 2) .caminho.dois {
    align-items: center;
    justify-content: center;
    width: 100dvw;
    right: -50px;
    bottom: -100px;
  }

  .elemento:nth-child(3n + 2) .caminho.dois span.um {
    width: 2px;
    height: 100px;
  }

  .elemento:nth-child(3n + 2) .caminho.dois span.dois {
    display: none;
  }

  /* Caminho elementos C */

  .elemento:nth-child(3n) .caminho.dois {
    align-items: center;
    justify-content: center;
    width: 100dvw;
    left: auto;
    right: -50px;
    bottom: -100px;
  }

  .elemento:nth-child(3n) .caminho.dois span.um {
    display: none;
  }

  .elemento:nth-child(3n) .caminho.dois span.dois {
    width: 2px;
    height: 100px;
  }

  .modulo {
    max-width: 280px;
  }

  .modulo .capa {
    top: -140px;
    right: -60px;
  }
}
</style>
