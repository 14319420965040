<template>
  <nav :class="{ ativo: state.menuAberto }">
    <div class="titulo">
      <Svgs @click="abrirPagina('/painel')" nome="logo-v3" />
      <h3>Olá produtor, fique a vontade em sua plataforma</h3>
      <button class="menu" :class="{ ativo: state.menuAberto }" @click="state.menuAberto = !state.menuAberto" ref="menu">
        <span class="um"></span>
        <span class="dois"></span>
      </button>
    </div>
    <div class="opcoes">
      <a :class="{ ativo: $route.path === '/painel' }" @click="abrirPagina('/painel')">
        <Svgs nome="casa" />
        <p>Painel</p>
      </a>
      <a :class="{ ativo: $route.path === '/areas' }" @click="abrirPagina('/areas')">
        <Svgs nome="camadas" />
        <p>Áreas de Membros</p>
      </a>
      <a :class="{ ativo: $route.path === '/assinatura' }" @click="abrirPagina('/assinatura')">
        <Svgs nome="medalha" />
        <p>Planos</p>
      </a>
      <a class="notificacoes" :class="{ ativo: $route.path === '/notificacoes', novo: storePainel.estatisticas?.quantidadeNotificacoesNaoLidas > 0 }" @click="abrirPagina('/notificacoes')">
        <span></span>
        <Svgs nome="sino" />
        <p>Notificações</p>
      </a>
      <a href="https://wa.me/551831993340" target="_blank">
        <Svgs nome="whatsapp" />
        <p>Suporte Level</p>
      </a>
    </div>
    <a class="perfil" @click="abrirPagina('/perfil')">
      <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`"></div>
      <div class="texto">
        <h3>{{ storePerfil.usuario?.nome.split(' ')[0] }}</h3>
        <div class="assinatura">
          <p>Plano {{ storePerfil.usuario?.assinatura?.plano }}</p>
        </div>
      </div>
      <Svgs nome="setinha" />
    </a>
  </nav>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useStorePerfil, useStorePainel } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const router = useRouter()
const storePerfil = useStorePerfil()
const storePainel = useStorePainel()

const menu = ref()
const state = reactive({
  menuAberto: false
})

function fecharMenu() {
  state.menuAberto = false
}

function abrirPagina(pagina) {
  const url = window.location.pathname
  fecharMenu()

  if (pagina === url) return
  else router.push(pagina)
}
</script>

<style scoped>
nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100dvh;
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  background-color: var(--cor-branco);
  z-index: 10;
}

nav .titulo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 30px 30px 20px 30px;
  border-bottom: 2px solid var(--cor-cinza-3);
}

nav .titulo svg {
  width: 100px;
  max-width: 100px;
}

nav .titulo h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  line-height: 1.5;
  margin: 15px 0 0 0;
}

nav .titulo button.menu {
  display: none;
  position: absolute;
  right: 20px;
  width: 35px;
  height: 35px;
  background-color: transparent;
}

nav .titulo button.menu span {
  position: absolute;
  background-color: var(--cor-preto);
  border-radius: 10px;
  height: 3px;
  transition: all 0.5s;
}

nav .titulo button.menu:hover span {
  background-color: var(--cor-cinza-2);
}

nav .titulo button.menu span.um {
  top: 10px;
  right: 0;
  width: 25px;
}

nav .titulo button.menu span.dois {
  bottom: 10px;
  right: 0;
  width: 35px;
}

nav.ativo .titulo button.menu span {
  background-color: var(--cor-vermelho);
}

nav.ativo .titulo button.menu span.um {
  transform: rotate(45deg);
  width: 35px;
  top: 16px;
}

nav.ativo .titulo button.menu span.dois {
  transform: rotate(-45deg);
  bottom: 16px;
}

nav .opcoes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 10px 0 0 0;
  margin-bottom: auto;
}

nav .opcoes a,
nav .opcoes button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  width: 100%;
  padding: 15px 30px;
  position: relative;
  transition: all 0.3s;
}

nav .opcoes button:hover {
  opacity: 0.6;
}
nav .opcoes a.breve,
nav .opcoes button.breve {
  opacity: 0.5;
  pointer-events: none;
}

nav .opcoes button svg {
  fill: var(--cor-ciano) !important;
}

nav .opcoes button p {
  color: var(--cor-ciano) !important;
}

nav .opcoes a.ativo,
nav .opcoes button.ativo {
  pointer-events: none;
}

nav .opcoes a.ativo svg,
nav .opcoes button.ativo svg {
  fill: var(--cor-azul-forte);
}

nav .opcoes a.breve p {
  opacity: 0.6;
}

nav .opcoes a svg,
nav .opcoes button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza);
  margin: 0 10px 0 0;
  z-index: 1;
}

nav .opcoes a p,
nav .opcoes button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  z-index: 1;
  transition: all 0.3s;
}

nav .opcoes a:hover p,
nav .opcoes button:hover p {
  color: var(--cor-cinza);
}

nav .opcoes a.ativo p,
nav .opcoes button.ativo p {
  color: var(--cor-azul-forte);
}

nav .opcoes a.notificacoes {
  position: relative;
}

nav .opcoes a.notificacoes.novo span {
  display: flex;
}

nav .opcoes a.notificacoes span {
  display: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--cor-vermelho);
  position: absolute;
  top: 10px;
  left: 30px;
  animation: zoomSpan 1s linear infinite;
}

@keyframes zoomSpan {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

nav a.perfil {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 30px;
  border-top: 2px solid var(--cor-cinza-2);
  width: 100%;
  transition: all 0.3s;
}

nav a.perfil:hover .foto {
  transform: scale(1.1);
}

nav a.perfil .foto {
  width: 35px;
  height: 35px;
  min-width: 35px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-color: var(--cor-cinza-2);
  transition: all 0.3s;
}

nav a.perfil svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza);
  position: absolute;
  transform: rotate(0deg);
  right: 20px;
  transition: all 0.3s;
}

nav a.perfil:hover svg {
  fill: var(--cor-roxo);
}

nav a.perfil .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
}

nav a.perfil .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

nav a.perfil .texto .assinatura {
  margin: 5px 0 0 0;
}

nav a.perfil .texto .assinatura p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  text-transform: capitalize;
}

nav a.perfil .texto .assinatura.unlimited p {
  color: #ad86e3;
}

nav a.perfil .texto .assinatura.diamond p {
  color: #03fff7;
}

nav a.perfil .texto .assinatura.gold p {
  color: #f7e760;
}

nav a.perfil .texto .assinatura.silver p {
  color: #666666;
}

nav a.perfil .texto .assinatura.test p {
  color: #00c94d;
}

nav a.perfil .texto .assinatura {
  display: flex;
  align-items: center;
}

nav a.perfil .texto .assinatura img {
  width: 12px;
  max-width: 12px;
  margin: 0 5px 0 0;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  nav {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    max-height: 60px;
    height: 70px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border-bottom: 2px solid var(--cor-cinza-2);
  }

  nav .linha {
    width: 100%;
    height: 5px;
  }

  nav .titulo {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px 0 25px;
    border-bottom: none;
  }

  nav .titulo svg {
    width: 90px;
    min-width: 90px;
  }

  nav .titulo h3 {
    display: none;
  }

  nav .titulo button.menu {
    display: flex;
  }

  nav .opcoes {
    width: calc(100% - 40px);
    padding: 0 0 10px 0;
    margin-bottom: 0;
    position: fixed;
    top: 140px;
    left: 0px;
    background-color: var(--cor-branco);
    border-radius: 0 0 10px 10px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s;
  }

  nav.ativo .opcoes {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    left: 20px;
  }

  nav .opcoes a,
  nav .opcoes button {
    padding: 15px 20px;
  }

  nav .opcoes a svg,
  nav .opcoes button svg {
    width: 12px;
    min-width: 12px;
  }

  nav .opcoes a.notificacoes span {
    left: 20px;
  }

  nav a.perfil {
    padding: 0 20px;
    width: calc(100% - 40px);
    height: 70px;
    min-height: 70px;
    transition: all 0.3s;
    position: fixed;
    top: 70px;
    left: 40px;
    border-radius: 10px 10px 0 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    background-color: var(--cor-branco);
    border-top: none;
    border-bottom: 1px solid var(--cor-cinza-2);
    transition: all 0.5s;
  }

  nav.ativo a.perfil {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    left: 20px;
  }

  nav a.perfil .foto {
    width: 35px;
    height: 35px;
    min-width: 35px;
  }
}
</style>
