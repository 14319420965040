<template>
  <section class="suporte">

    <div class="tutoriais" @click="router.push('/tutoriais')">
      <div class="icone">
        <Svgs nome="play" />
      </div>
      <div class="nome">
        <h3>Tutoriais Level</h3>
        <p>Aprenda a usar a tecnologia Level</p>
      </div>
    </div>

    <a class="whatsapp" href="https://wa.me/551831993340" target="_blank">
      <div class="icone">
        <div class="svg">
          <Svgs nome="whatsapp" />
        </div>
        <img src="https://arquivos.levelmember.com/imagens/identidade/perfil.png">
        <span></span>
      </div>
      <div class="nome">
        <h3>Suporte Level</h3>
        <p>Precisa de Ajuda? Chame nosso time que vamos te auxiliar</p>
      </div>
    </a>

  </section>
</template>

<script setup>
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const router = useRouter()
</script>

<style scoped>
section.suporte {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px 60px 30px;
  background-color: var(--cor-gelo);
  z-index: 2;
}

.whatsapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: calc(60% - 20px);
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-branco);
  border-radius: 10px;
  padding: 40px;
  position: relative;
  transition: all 0.3s;
}

.whatsapp:hover {
  border: 2px solid var(--cor-azul-forte);
}

.whatsapp .icone {
  display: flex;
  align-items: center;
  position: relative;
}

.whatsapp .icone .svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 10px;
  border: 2px solid var(--cor-cinza-3);
}

.whatsapp .icone .svg svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-preto);
}

.whatsapp .icone img {
  width: 60px;
  min-width: 60px;
  border-radius: 10px;
  margin: 0 0 0 10px;
}

.whatsapp .icone span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--cor-verde);
  box-shadow: 0 0 15px 0 var(--cor-verde);
  position: absolute;
  top: 0;
  left: 0;
}

.whatsapp .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding: 0 0 0 10px;
  margin-right: auto;
}

.whatsapp .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-preto);
  margin: 0 0 5px 0;
}

.whatsapp .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
}

.tutoriais {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 40%;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-branco);
  border-radius: 10px;
  padding: 40px;
  transition: all 0.3s;
}

.tutoriais:hover {
  border: 2px solid var(--cor-azul-forte);
}

.tutoriais .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 10px;
  border: 2px solid var(--cor-cinza-3);
  margin: 0 15px 0 0;
}

.tutoriais .icone svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-preto);
}

.tutoriais .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

.tutoriais .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-preto);
  margin: 0 0 5px 0;
}

.tutoriais .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  width: 100%;
  max-width: 280px;
}

@media screen and (max-width: 1000px) {
  section.suporte {
    flex-direction: column;
    padding: 0 20px 60px 20px;
  }

  .whatsapp {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 35px 20px;
  }

  .whatsapp .icone {
    display: flex;
    align-items: center;
    position: relative;
  }

  .whatsapp .icone .svg {
    width: 60px;
    min-width: 60px;
    height: 60px;
  }

  .whatsapp .icone .svg svg {
    width: 20px;
    min-width: 20px;
  }

  .whatsapp .icone img {
    width: 60px;
    min-width: 60px;
  }

  .whatsapp .nome {
    align-items: center;
    text-align: center;
    padding: 20px 0 10px 0;
    margin-right: 0;
  }

  .tutoriais {
    text-align: left;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    margin: 0 0 20px 0;
  }

  .tutoriais .icone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 15px 0 0;
    box-shadow: 0 5px 10px 0 var(--cor-cinza-2);
  }

  .tutoriais .icone svg {
    width: 20px;
    min-width: 20px;
  }

  .tutoriais .nome h3 {
    margin: 5px 0 5px 0;
  }

  .tutoriais .nome p {
    font-size: var(--f1);
    max-width: 100%;
    margin: 0 0 0 0;
  }

}
</style>
