<template>
  <Modal nome="editarPerfil">
    <label>
      Nome
      <span>*</span>
    </label>
    <input v-model="state.nome" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: João da Silva" @keydown.enter="salvarPerfil" />

    <label>
      Whatsapp
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="whatsapp" />
      <input type="tel" spellCheck="false" placeholder="(00) 00000-0000" v-mask="mascaraAtual" v-model="numeroTelefone" @keydown.enter="salvarPerfil" />
    </div>

    <label>
      Foto de perfil
      <span>*</span>
    </label>
    <div class="inputFile">
      <button @click="carregarImagem()" :disabled="state.uploadImagem.desabilitarBotao">
        <span></span>
        <Svgs nome="arquivo" />
        <h3>Subir Imagem</h3>
        <p>Tamanho: 600x600</p>
        <UploadImagem :dados="state.uploadImagem" ref="uploadImagem" @atualizarDadosImagem="atualizarDadosImagem($event)" />
      </button>
      <div class="previa" :style="`background-image: url('${state.imagemPerfil || ''}')`"></div>
    </div>

    <SalvarModal nome="Salvar Perfil" @click="salvarPerfil()" />
  </Modal>
</template>

<script setup>
import { inject, onMounted, reactive, ref, watch } from 'vue'
import { useStorePerfil, useStoreModal } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import UploadImagem from '@components/global/upload/image.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const uploadImagem = ref(null)
const numeroTelefone = ref('')
const mascaraAtual = ref('(##) #####-####')
const storePerfil = useStorePerfil()
const storeModal = useStoreModal()

const state = reactive({
  nome: '',
  whatsapp: '',
  imagemPerfil: '',
  uploadImagem: {
    tamanhoMaximo: 1920,
    urlImagem: '',
    desabilitarBotao: false
  }
})

function atualizarDadosImagem(dados) {
  if (typeof dados.desabilitarBotao === 'boolean') state.uploadImagem.desabilitarBotao = dados.desabilitarBotao
  if (typeof dados.urlImagem === 'string') {
    state.uploadImagem.urlImagem = dados.urlImagem
    state.imagemPerfil = state.uploadImagem.urlImagem
  }
}

function carregarImagem() {
  uploadImagem?.value?.enviarImagem()
}

watch(numeroTelefone, (novoValor) => {
  const digitos = novoValor.replace(/\D/g, '')
  if (digitos.length <= 10) {
    mascaraAtual.value = '(##) ####-####'
  } else {
    mascaraAtual.value = '(##) #####-####'
  }
})

async function salvarPerfil() {
  if (!validarDados()) return

  const payload = {
    nome: state.nome,
    whatsapp: numeroTelefone.value,
    imagemPerfil: state.imagemPerfil
  }

  const adicionadoOk = await storePerfil.alterarDados(payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarPerfil')
  storeModal.fecharModal('editarPerfil')
}

function validarDados() {
  let mensagem
  if (!state.nome || !numeroTelefone.value || !state.imagemPerfil) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

function carregarDados() {
  state.nome = storePerfil.usuario?.nome || ''
  state.whatsapp = storePerfil.usuario?.contato?.whatsapp || ''
  numeroTelefone.value = storePerfil.usuario?.contato?.whatsapp || ''
  state.imagemPerfil = storePerfil.usuario?.imagemPerfil || ''
}

function limparCampos() {
  state.nome = ''
  state.whatsapp = ''
  state.imagemPerfil = ''
  numeroTelefone.value = ''
}

onMounted(() => {
  watch(
    () => storePerfil.usuario,
    (newUsuario) => {
      if (newUsuario) {
        carregarDados()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
  emitter.on('carregarCampos', carregarDados)
})
</script>

<style scoped>
.titulo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(0deg, var(--degrade));
}

.titulo .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}

.titulo .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
}

.titulo .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
  line-height: 1.5;
}

.titulo .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
}

label {
  margin: 20px 0 10px 0;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

@media screen and (max-width: 1000px) {
  .titulo .nome h3 {
    font-size: var(--f2);
  }

  .titulo .nome p {
    font-size: var(--f1);
  }
}
</style>
