<template>
  <section class="modulo">
    <div class="quadro">
      <div class="capa" @click="router.push(`/areas/${storeAreas.area?.hotlink}/${storeCursos.curso?.hotlink}`)">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${storeModulos.modulo?.imagemCapa}')`"></div>
        </div>
      </div>
      <div class="nome" @click="router.push(`/areas/${storeAreas.area?.hotlink}/${storeCursos.curso?.hotlink}`)">
        <h3>{{ storeModulos.modulo?.nome }}</h3>
        <p>{{ storeModulos.modulo?.descricao }}</p>
      </div>
      <div class="parte aulas">
        <Svgs nome="play" />
        <h3>
          {{ storeModulos.modulo?.quantidadeAulas }}
          <span>{{ textoAulas(storeModulos.modulo?.quantidadeAulas) }}</span>
          nesse módulo
        </h3>
      </div>
    </div>
    <div class="posicao" v-if="storeAulas.aulas?.length > 1">
      <h3>Altere a ordem das aulas</h3>
      <button
        @click="
          abrirModal(
            'editarPosicaoAulas', // nome do modal
            'degrade-azul', // cor do icone
            'cursor', // nome do icone
            'Posição das Aulas', // titulo
            'Altere a ordem das aulas' // subtitulo
          )
        ">
        <Svgs nome="cursor" />
        <p>Editar posição</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStoreModal, useStoreAreas, useStoreCursos, useStoreModulos, useStoreAulas } from '@stores'
import Svgs from '@svgs'

const router = useRouter()

const storeModal = useStoreModal()
const storeCursos = useStoreCursos()
const storeAreas = useStoreAreas()
const storeModulos = useStoreModulos()
const storeAulas = useStoreAulas()

function abrirModal(nome, cor, icone, titulo, subtitulo) {
  storeModal.abrirModal(nome, cor, icone, titulo, subtitulo)
}

const textoAulas = (quantidade) => {
  return `${quantidade === 1 ? 'aula' : 'aulas'}`
}
</script>

<style scoped>
section.modulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 30px 20px 30px;
}

.posicao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid var(--cor-cinza-2);
}

.posicao h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  line-height: 1.5;
}

.posicao button {
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  background-color: transparent;
  transition: all 0.3s;
}

.posicao button:hover svg {
  fill: var(--cor-azul-forte-escuro);
}

.posicao button:hover p {
  color: var(--cor-azul-forte-escuro);
}

.posicao button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-azul-forte);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.posicao button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-azul-forte);
  transition: all 0.3s;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-branco);
  width: 100%;
  padding: 20px 30px 20px 20px;
  border-radius: 10px;
}

.quadro .capa {
  width: 100%;
  max-width: 70px;
  cursor: pointer;
  transition: all 0.3s;
}

.quadro .capa:hover {
  opacity: 0.6;
}

.quadro .capa .tamanho {
  position: relative;
  padding-bottom: 56.52%;
  width: 100%;
}

.quadro .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-cinza-2);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.quadro .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.quadro .nome:hover {
  opacity: 0.6;
}

.quadro .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
}

.quadro .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}

.quadro .parte {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quadro .parte h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

.quadro .parte svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-preto);
  margin: 0 10px 0 0;
}

@media screen and (max-width: 1000px) {
  section.modulo {
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }

  .posicao {
    padding: 15px;
  }

  .posicao h3 {
    font-size: var(--f1);
  }

  .posicao button svg {
    width: 10px;
    min-width: 10px;
    margin: 0 8px 0 0;
  }

  .posicao button p {
    font-size: var(--f1);
  }

  .quadro {
    flex-wrap: wrap;
    padding: 15px;
  }

  .quadro .capa {
    width: 100%;
    max-width: 60px;
  }

  .quadro .nome {
    width: calc(100% - 120px);
    padding: 0 0 0 15px;
  }

  .quadro .nome h3 {
    font-size: var(--f2);
  }

  .quadro .nome p {
    font-size: var(--f1);
  }

  .quadro .parte {
    width: 100%;
    padding: 30px 0 10px 0;
  }

  .quadro .parte h3 {
    font-size: var(--f1);
  }

  .quadro .parte svg {
    width: 13px;
    min-width: 13px;
    margin: 0 8px 0 0;
  }
}
</style>
