<template>

  <!-- Período Fixo Mobile -->
  <div class="periodo fixo" :class="{ ativo: state.periodicidade, mostrar: state.scrollPeriodo }" @click="togglePeriodicidade()">
    <h3>Mensal</h3>
    <div class="toggle">
      <span></span>
    </div>
    <h3>Anual</h3>
    <div class="tag">
      <p>2 MESES GRÁTIS</p>
    </div>
  </div>

  <section class="planos">
    <!-- Período -->
    <div class="periodo" :class="{ ativo: state.periodicidade }" @click="togglePeriodicidade()">
      <h3>Mensal</h3>
      <div class="toggle">
        <span></span>
      </div>
      <h3>Anual</h3>
      <div class="tag">
        <p>2 MESES GRÁTIS</p>
      </div>
    </div>
    <!-- Quadros -->
    <div class="quadros" :class="{ animacao: state.periodicidade }">

      <div class="quadro" :class="plano.nome" v-for="plano in state.planos" :key="plano">
        <div class="tipo">
          <img :src="`https://arquivos.levelmember.com/imagens/planos/plano-${plano.nome || 'default'}.png`">
          <h3>{{ plano.nome }}</h3>
        </div>
        <div class="preco">
          <h4>{{ plano.precificacao }}</h4>
          <h2>{{ plano.valor }}</h2>
          <h3>{{ plano.centavos }}</h3>
          <p>{{ plano.periodicidade }}</p>
        </div>
        <div class="descricao">
          <h3>{{ plano.parcelado }}</h3>
          <p>{{ plano.descricao }}</p>
        </div>
        <div class="beneficios">
          <div class="item" :class="{ bloqueado: !beneficio.ativo }" v-for="beneficio in plano.beneficios">
            <div class="icone">
              <Svgs :nome="`${beneficio.icone || 'check'}`"/>
            </div>
            <p>{{ beneficio.texto }}</p>
          </div>
        </div>
        <div class="botao">
          <a :href="plano.link" target="_blank">{{ plano.botao }}</a>
        </div>
      </div>

    </div>
  </section>
</template>

<script setup>
import { reactive, onMounted, onUnmounted } from 'vue'
import Svgs from '@svgs'

const state = reactive({
  periodicidade: false,
  scrollPeriodo: '',
  planos: {
    essencial: {
      nome: 'essencial',
      precificacao: 'R$',
      valor: '197',
      periodicidade: '/ por mês',
      parcelado: '',
      descricao: 'Ideal para projetos em crescimento com pouca demanda',
      beneficios: {
        um: {
          ativo: true,
          icone: 'camadas',
          texto: '1 área de membros'
        },
        dois: {
          ativo: true,
          icone: 'alunos',
          texto: '1.200 alunos'
        },
        tres: {
          ativo: true,
          icone: 'whatsapp',
          texto: 'Suporte 24 horas'
        },
        quatro: {
          ativo: true,
          icone: 'curso',
          texto: 'Criação de cursos ilimitados'
        },
        cinco: {
          ativo: true,
          icone: 'modulo',
          texto: 'Criação de módulos ilimitados'
        },
        seis: {
          ativo: true,
          icone: 'play',
          texto: 'Criação de aulas ilimitadas'
        },
        sete: {
          ativo: true,
          icone: 'editar',
          texto: 'Personalização ilimitada'
        },
        oito: {
          ativo: true,
          icone: 'medalha',
          texto: 'Acesso á tutoriais Level'
        },
        nove: {
          ativo: true,
          icone: 'template',
          texto: 'Templates prontos de áreas'
        },
        dez: {
          ativo: false,
          icone: 'estrela',
          texto: 'Templates premium exclusivos'
        },
        onze: {
          ativo: false,
          icone: 'usuario',
          texto: 'Call de Implementação (1h30)'
        },
        doze: {
          ativo: false,
          icone: 'seta',
          texto: 'Acesso antecipado á novidades'
        },
      },
      botao: 'Assinar Essencial Mensal',
      link: 'https://google.com',
    },
    pro: {
      nome: 'pro',
      precificacao: 'R$',
      valor: '497',
      periodicidade: '/ por mês',
      parcelado: '',
      descricao: 'Ideal para grandes projetos com alta demanda de áreas',
      beneficios: {
        um: {
          ativo: true,
          icone: 'camadas',
          texto: '3 áreas de membros'
        },
        dois: {
          ativo: true,
          icone: 'alunos',
          texto: '5.000 alunos'
        },
        tres: {
          ativo: true,
          icone: 'whatsapp',
          texto: 'Suporte 24 horas'
        },
        quatro: {
          ativo: true,
          icone: 'curso',
          texto: 'Criação de cursos ilimitados'
        },
        cinco: {
          ativo: true,
          icone: 'modulo',
          texto: 'Criação de módulos ilimitados'
        },
        seis: {
          ativo: true,
          icone: 'play',
          texto: 'Criação de aulas ilimitadas'
        },
        sete: {
          ativo: true,
          icone: 'editar',
          texto: 'Personalização ilimitada'
        },
        oito: {
          ativo: true,
          icone: 'medalha',
          texto: 'Acesso á tutoriais Level'
        },
        nove: {
          ativo: true,
          icone: 'template',
          texto: 'Templates prontos de áreas'
        },
        dez: {
          ativo: true,
          icone: 'estrela',
          texto: 'Templates premium exclusivos'
        },
        onze: {
          ativo: false,
          icone: 'usuario',
          texto: 'Call de Implementação (1h30)'
        },
        doze: {
          ativo: false,
          icone: 'seta',
          texto: 'Acesso antecipado á novidades'
        },
      },
      botao: 'Assinar Pro Mensal',
      link: 'https://google.com',
    },
    expert: {
      nome: 'expert',
      precificacao: 'R$',
      valor: '997',
      periodicidade: '/ por mês',
      parcelado: '',
      descricao: 'Ideal para projetos consolidados com demanda escalável',
      beneficios: {
        um: {
          ativo: true,
          icone: 'camadas',
          texto: '15 áreas de membros'
        },
        dois: {
          ativo: true,
          icone: 'alunos',
          texto: '25.000 alunos'
        },
        tres: {
          ativo: true,
          icone: 'whatsapp',
          texto: 'Suporte 24 horas dedicado'
        },
        quatro: {
          ativo: true,
          icone: 'curso',
          texto: 'Criação de cursos ilimitados'
        },
        cinco: {
          ativo: true,
          icone: 'modulo',
          texto: 'Criação de módulos ilimitados'
        },
        seis: {
          ativo: true,
          icone: 'play',
          texto: 'Criação de aulas ilimitadas'
        },
        sete: {
          ativo: true,
          icone: 'editar',
          texto: 'Personalização ilimitada'
        },
        oito: {
          ativo: true,
          icone: 'medalha',
          texto: 'Acesso á tutoriais Level'
        },
        nove: {
          ativo: true,
          icone: 'template',
          texto: 'Templates prontos de áreas'
        },
        dez: {
          ativo: true,
          icone: 'estrela',
          texto: 'Templates premium exclusivos'
        },
        onze: {
          ativo: true,
          icone: 'usuario',
          texto: 'Call de Implementação (1h30)'
        },
        doze: {
          ativo: true,
          icone: 'seta',
          texto: 'Acesso antecipado á novidades'
        },
      },
      botao: 'Assinar Expert Mensal',
      link: 'https://google.com',
    },
  }
})

function togglePeriodicidade() {
  state.periodicidade = !state.periodicidade

  for (const key in state.planos) {
    if (state.periodicidade) {
      const valorMensal = parseFloat(state.planos[key].valor)
      const valorAnual = Math.floor(valorMensal * 10)
      const valorParcelado = Math.floor(valorAnual / 12)
      state.planos[key].valor = valorParcelado
      state.planos[key].precificacao = '12x de R$'
      state.planos[key].parcelado = `ou R$ ${valorAnual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} à vista / Anualmente`
      state.planos[key].periodicidade = ''
      state.planos.essencial.botao = 'Assinar Essencial Anual'
      state.planos.essencial.link = 'https://google.com'
      state.planos.pro.botao = 'Assinar Pro Anual'
      state.planos.pro.link = 'https://google.com'
      state.planos.expert.botao = 'Assinar Expert Anual'
      state.planos.expert.link = 'https://google.com'
    } else {
      const valorAnual = parseFloat(state.planos[key].valor) * 12
      const valorMensalOriginal = Math.floor(valorAnual / 10)
      state.planos[key].valor = valorMensalOriginal
      state.planos[key].precificacao = 'R$'
      state.planos[key].parcelado = ''
      state.planos[key].periodicidade = '/ por mês'
      state.planos.essencial.botao = 'Assinar Essencial Mensal'
      state.planos.essencial.link = 'https://google.com'
      state.planos.pro.botao = 'Assinar Pro Mensal'
      state.planos.pro.link = 'https://google.com'
      state.planos.expert.botao = 'Assinar Expert Mensal'
      state.planos.expert.link = 'https://google.com'
    }
  }
}

function scrollTela() {
  state.scrollPeriodo = window.scrollY >= 200
}

onMounted(() => {
  window.addEventListener('scroll', scrollTela)
})

onUnmounted(() => {
  window.removeEventListener('scroll', scrollTela)
})

</script>

<style scoped>
section.planos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 30px 80px 30px;
  background: radial-gradient(circle, transparent 10%, var(--cor-gelo) 50%);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 2;
}

.periodo.fixo {
  display: none;
  position: fixed;
  width: 100%;
  top: 55px;
  left: 0;
  z-index: 3;
  padding: 20px 20px 15px 20px;
  background-color: var(--cor-gelo);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.periodo.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.periodo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 30px 0;
  cursor: pointer;
}

.periodo h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

.periodo .toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 30px;
  background-color: var(--cor-cinza-2);
  border-radius: 50px;
  position: relative;
  margin: 0 15px;
  cursor: pointer;
  transition: all 0.3s;
}

.periodo.ativo .toggle {
  background-color: var(--cor-verde);
}

.periodo.ativo .toggle span {
  left: 30px;
  background-color: var(--cor-branco-fixo);
}

.periodo.ativo .tag p {
  color: var(--cor-branco-fixo);
}

.periodo.ativo .tag {
  background-color: var(--cor-verde);
}

.periodo .toggle span {
  position: absolute;
  left: 0;
  width: 30px;
  height: 30px;
  background-color: var(--cor-branco-fixo);
  border-radius: 50%;
  transition: all 0.3s;
}

.periodo .tag {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: var(--cor-branco);
  margin: 0 0 0 10px;
}

.periodo .tag p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-preto);
  transition: all 0.3s;
}

.quadros {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  animation: efeitoQuadros 0.6s linear;
}

.quadros.animacao {
  animation: efeitoQuadrosDois 0.6s linear;
}

@keyframes efeitoQuadrosDois {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes efeitoQuadros {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.quadros .quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(33.33% - 10px);
  background-color: var(--cor-branco-transparente);
  border: 1px solid var(--cor-cinza-2);
  border-radius: 10px;
  transition: all 0.3s;
}

.quadros .quadro.pro {
  border: 1px solid #C272F8;
}

.quadros .quadro .tipo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 30px 30px 0 30px;
}
.quadros .quadro .tipo img {
  width: 100%;
  max-width: 40px;
  margin: 0 10px 0 0;
}

.quadros .quadro .tipo h3 {
  font-family: var(--estilo);
  font-size: var(--f3);
  color: var(--cor-preto);
  text-transform: uppercase;
}

.quadros .quadro .preco {
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 30px 30px 0 30px;
}
.quadros .quadro .preco h4 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-preto);
  line-height: 1;
  padding: 0 0 5px 0;
}
.quadros .quadro .preco h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-preto);
  line-height: 1;
}
.quadros .quadro .preco h2 {
  font-family: var(--bold);
  font-size: var(--f6);
  color: var(--cor-preto);
  line-height: 1;
  margin: 0 6px 0 7px;
}
.quadros .quadro .preco p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1;
  padding: 0 0 3px 0;
}
.quadros .quadro .descricao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 15px 30px 0 30px;
}
.quadros .quadro .descricao h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  line-height: 1.5;
  margin: 0 0 5px 0;
}
.quadros .quadro .descricao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  width: 100%;
  max-width: 300px;
}
.quadros .quadro .beneficios {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 15px 30px 30px 30px;
}
.quadros .quadro .beneficios .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 0 0;
}
.quadros .quadro .beneficios .item.bloqueado p {
  color: var(--cor-cinza);
  text-decoration: line-through;
}
.quadros .quadro .beneficios .item.bloqueado .icone {
  border: 1px solid var(--cor-cinza-2) !important;
}
.quadros .quadro .beneficios .item.bloqueado .icone svg {
  fill: var(--cor-cinza);
}
.quadros .quadro .beneficios .item .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid var(--cor-cinza-2);
  margin: 0 10px 0 0;
}
.quadros .quadro .beneficios .item .icone svg {
  width: 13px;
  min-width: 13px;
  max-height: 13px;
  fill: var(--cor-preto);
}
.quadros .quadro .beneficios .item p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}
.quadros .quadro.essencial .beneficios .item .icone {
  border: 1px solid #FEE56F;
}
.quadros .quadro.pro .beneficios .item .icone {
  border: 1px solid #C272F8;
}
.quadros .quadro.expert .beneficios .item .icone {
  border: 1px solid #75E1FE;
}
.quadros .quadro .botao {
  display: flex;
  width: 100%;
  padding: 0 30px 30px 30px;
}
.quadros .quadro .botao a {
  text-align: center;
  width: 100%;
  font-family: var(--estilo);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  text-transform: uppercase;
  padding: 20px 10px;
  border-radius: 10px;
  transition: all 0.3s;
}
.quadros .quadro .botao a:hover {
  filter: brightness(120%);
}
.quadros .quadro.essencial .botao a {
  background-color: #af9b36;
}
.quadros .quadro.pro .botao a {
  background-color: #872dc3;
}
.quadros .quadro.expert .botao a {
  background-color: #2f9ab7;
}
@media screen and (max-width: 1000px) {
  section.planos {
    padding: 0 20px 60px 20px;
  }

  .periodo.fixo {
    display: flex;
  }

  .periodo {
    padding: 0 0 20px 0;
  }

  .periodo h3 {
    font-size: var(--f1);
  }

  .periodo .toggle {
    margin: 0 10px;
  }

  .periodo .tag {
    padding: 4px 8px;
  }

  .quadros {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .quadros .quadro {
    width: 100%;
  }

  .quadros .quadro.pro {
    margin: 30px 0;
  }

  .quadros .quadro .tipo {
    padding: 20px 20px 0 20px;
  }
  .quadros .quadro .tipo img {
    max-width: 30px;
  }

  .quadros .quadro .tipo h3 {
    font-size: var(--f2);
  }

  .quadros .quadro .preco {
    padding: 20px 20px 0 20px;
  }
  .quadros .quadro .descricao {
    padding: 15px 20px 0 20px;
  }
  .quadros .quadro .descricao p {
    font-size: var(--f1);
  }
  .quadros .quadro .beneficios {
    padding: 15px 20px 20px 20px;
  }
  .quadros .quadro .beneficios .item .icone {
    width: 25px;
    height: 25px;
    margin: 0 8px 0 0;
  }
  .quadros .quadro .beneficios .item .icone svg {
    width: 11px;
    min-width: 11px;
    max-height: 11px;
  }
  .quadros .quadro .botao {
    padding: 0 20px 20px 20px;
  }
}
</style>
