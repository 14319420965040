<template>
  <section class="topo">
    <div class="titulo">
      <div class="icone">
        <Svgs nome="camadas" />
      </div>
      <div class="nome">
        <h2>Suas áreas de membros</h2>
        <p>2 áreas de membros criadas</p>
      </div>
    </div>
    <div class="opcoes">
      <button class="perfil" @click="router.push('/perfil')">
        <div class="nome">
          <h3>Eduardo</h3>
          <p>eduardo@lecdt.com</p>
        </div>
        <div class="foto"></div>
      </button>
      <button class="notificacao" @click="router.push('/notificacoes')">
        <Svgs nome="sino" />
        <span></span>
      </button>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router'
import Svgs from '../../../global/svgs/Svgs.vue'

const router = useRouter()
</script>

<style scoped>
section.topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
}

.titulo {
  display: flex;
  align-items: center;
}

.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: linear-gradient(0deg, var(--degrade));
  margin: 0 15px 0 0;
}

.titulo .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}

.titulo .nome h2 {
  font-family: var(--estilo);
  font-size: var(--f3);
  color: var(--cor-preto);
}

.titulo .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  margin: 8px 0 0 0;
}

.opcoes {
  display: flex;
  align-items: center;
}

.opcoes .perfil {
  display: flex;
  align-items: center;
  background-color: transparent;
}

.opcoes .perfil:hover .foto {
  transform: scale(1.1);
}

.opcoes .perfil .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.opcoes .perfil .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

.opcoes .perfil .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}

.opcoes .perfil .foto {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cor-cinza-2);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

.notificacao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--cor-branco);
  margin: 0 0 0 15px;
  position: relative;
}

.notificacao svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-cinza);
  transition: all 0.3s;
}

.notificacao:hover svg {
  fill: var(--cor-roxo);
}

.notificacao.nova span {
  opacity: 1;
  visibility: visible;
}

.notificacao span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--cor-vermelho);
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0;
  visibility: hidden;
  animation: piscandoSpan 1s ease-in-out infinite;
  transition: all 0.3s;
}

@keyframes piscandoSpan {
  0% {
    box-shadow: 0 0 5px 0 var(--cor-vermelho);
    transform: scale(1);
  }

  50% {
    box-shadow: 0 0 15px 0 var(--cor-vermelho);
    transform: scale(1.1);
  }

  100% {
    box-shadow: 0 0 15px 0 var(--cor-vermelho);
    transform: scale(1);
  }
}

@media screen and (max-width: 1000px) {
  section.topo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
  }

  .titulo {
    display: flex;
    align-items: center;
  }

  .titulo .icone {
    width: 35px;
    height: 35px;
    margin: 0 10px 0 0;
  }

  .titulo .icone svg {
    width: 15px;
    min-width: 15px;
  }

  .titulo .nome h2 {
    font-size: var(--f2);
  }

  .titulo .nome p {
    font-size: var(--f1);
    margin: 5px 0 0 0;
  }

  .opcoes .perfil .nome {
    display: none;
  }

  .opcoes .perfil .foto {
    width: 35px;
    height: 35px;
    margin: 0;
  }

  .notificacao {
    width: 40px;
    height: 40px;
    margin: 0 0 0 10px;
  }

  .notificacao svg {
    width: 20px;
    min-width: 20px;
  }
}
</style>
