<template>
  <section class="opcoes">
    <button @click="alterarTema()">
      <div class="icone">
        <Svgs nome="modo"/>
      </div>
      <div class="nome">
        <h3>{{ textoTema() }}</h3>
        <p>Mude a tonalidade de <span>cor da sua plataforma</span></p>
      </div>
    </button>
    <button @click="abrirModal(
      'editarPerfil', // nome do modal
      'degrade', // cor do icone
      'editar', // nome do icone
      'Editar perfil', // titulo
      'Altere os seus dados Level', // subtitulo
    )">
      <div class="icone">
        <Svgs nome="editar"/>
      </div>
      <div class="nome">
        <h3>Editar Perfil</h3>
        <p>Altere seus dados <span>pessoais na Level Member</span></p>
      </div>
    </button>
    <button @click="abrirModal(
      'alterarSenha', // nome do modal
      'degrade', // cor do icone
      'editar', // nome do icone
      'Editar senha', // titulo
      'Altere sua senha na Level', // subtitulo
    )">
      <div class="icone">
        <Svgs nome="cadeado"/>
      </div>
      <div class="nome">
        <h3>Alterar Senha</h3>
        <p>Altere sua senha <span>na Level Member</span></p>
      </div>
    </button>
    <button class="sair" @click="sairDaConta()">
      <div class="icone">
        <Svgs nome="sair"/>
      </div>
      <div class="nome">
        <h3>Sair Agora</h3>
        <p>Deslogue de <span>sua conta Level</span></p>
      </div>
    </button>
  </section>
</template>

<script setup>
import { reactive } from 'vue'
import { useStoreModal } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const router = useRouter()
const storeModal = useStoreModal()

function abrirModal(nome, cor, icone, titulo, subtitulo) {
  storeModal.abrirModal(nome, cor, icone, titulo, subtitulo)
}

const state = reactive({
  tema: JSON.parse(localStorage.getItem('personalizacao-level-member'))?.tema
})

function textoTema() {
  if (state.tema === 'claro') return 'Modo Escuro'
  else if (state.tema === 'escuro') return 'Modo Claro'
  else return 'tema'
}

function alterarTema() {
  const dadosLocalStorage = JSON.parse(localStorage.getItem('personalizacao-level-member'))

  if (state.tema === 'claro') state.tema = 'escuro'
  else if (state.tema === 'escuro') state.tema = 'claro'

  localStorage.setItem('personalizacao-level-member', JSON.stringify({ ...dadosLocalStorage, tema: state.tema }))
  document.body.setAttribute('tema', state.tema)
}

function sairDaConta() {
  localStorage.removeItem("level-member-produtor-token")
  router.push('/')
}
</script>

<style scoped>
section.opcoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 30px 30px 30px;
  background-color: var(--cor-gelo);
  z-index: 2;
}

button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(25% - 15px);
  padding: 60px 30px;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-branco);
  border-radius: 10px;
  transition: all 0.3s;
}

button:hover {
  border: 2px solid var(--cor-azul-forte);
}

button.sair:hover {
  border: 2px solid var(--cor-vermelho);
}

button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 10px;
  border: 2px solid var(--cor-cinza-3);
}

button svg {
  width: 25px;
  min-width: 25px;
  max-height: 25px;
  fill: var(--cor-preto);
  transition: all 0.3s;
}

button .nome {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

button h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-preto);
  text-align: center;
  line-height: 1.5;
  margin: 20px 0 5px 0;
}

button p {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  max-width: 200px;
}

@media screen and (max-width: 1000px) {

  section.opcoes {
    flex-direction: column;
    padding: 0 20px 0 20px;
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 25px 25px;
    margin: 0 0 20px 0;
  }

  button svg {
    width: 30px;
    min-width: 30px;
    max-height: 30px;
    fill: var(--cor-cinza-2);
    transition: all 0.3s;
  }

  button .nome {
    align-items: flex-start;
    margin: 0 0 0 15px;
  }

  button h3 {
    font-size: var(--f2);
    margin: 0 0 2px 0;
  }

  button p {
    display: inherit;
    flex-direction: row;
    text-align: left;
    font-size: var(--f1);
    max-width: 100%;
  }

}
</style>
