<template>
  <div id="notificacoes">
    <Loading :class="{ mostrar: state.verLoading }" />
    <ModalVerNotificacao />
    <SectionTopo />
    <SectionFiltros />
    <SectionLista />
  </div>
</template>

<script setup>
import { inject, onMounted, reactive, watch } from 'vue'
import { useStoreNotificacoes } from '@stores'

import Loading from '@components/global/loading/Loading.vue'
import ModalVerNotificacao from '@components/app/notificacoes/modals/VerNotificacao.vue'
import SectionTopo from '@components/app/notificacoes/partials/SectionTopo.vue'
import SectionLista from '@components/app/notificacoes/partials/SectionLista.vue'
import SectionFiltros from '@components/app/notificacoes/partials/SectionFiltros.vue'

const emitter = inject('emitter')
const storeNotificacoes = useStoreNotificacoes()

const state = reactive({
  verLoading: true
})

async function carregarDados() {
  await storeNotificacoes.receberTodos()
  setTimeout(() => {
    state.verLoading = false
  }, 300)
}

onMounted(() => {
  carregarDados()
  emitter.on('atualizarNotificacoes', carregarDados)
})
</script>

<style scoped>
#notificacoes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 250px;
}

@media screen and (max-width: 1000px) {
  #notificacoes {
    padding: 60px 0 0 0;
  }
}
</style>
