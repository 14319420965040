<template>
  <section class="comentarios">
    <div class="comentar">
      <div class="input">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`"></div>
        <textarea v-model="state.textoComentario" rows="1" spellcheck="false" placeholder="Adicione um comentário" v-redimensionar-textarea @focus="mostrarBotoes" @blur="ocultarBotoes"></textarea>
      </div>
      <div class="botoes" v-if="state.mostrarBotoes">
        <button class="cancelar" @click="cancelarComentario">Cancelar</button>
        <button class="enviar" @click="comentar">Comentar</button>
      </div>
    </div>

    <div class="comentario" v-for="comentario in storeComentarios.comentarios" :id="comentario.idComentarioPrincipal" :key="comentario._id">
      <div class="foto" :style="`background-image: url('${comentario.autor?.aluno?.imagemPerfil || comentario.autor?.produtor?.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`"></div>
      <div class="nome">
        <h4 v-if="comentario.autor?.produtor">ADM</h4>
        <h3>{{ comentario.autor?.aluno?.nome || comentario.autor?.produtor?.nome }}</h3>
        <p>{{ formatarData(comentario.dataRegistro) }}</p>
      </div>
      <div class="texto">
        <p>{{ comentario.texto }}</p>
      </div>

      <button class="responder" @click="mostrarRespondendo(comentario._id)">Responder</button>

      <div class="respondendo" v-if="state.comentarioRespondendo === comentario._id">
        <div class="input">
          <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`"></div>
          <textarea v-model="state.textoResposta" rows="1" spellcheck="false" placeholder="Adicione um comentário" v-redimensionar-textarea></textarea>
        </div>
        <div class="botoes">
          <button class="cancelar" @click="cancelarResposta">Cancelar</button>
          <button class="enviar" @click="responder(comentario._id)">Comentar</button>
        </div>
      </div>

      <div class="resposta" v-for="resposta in comentario.respostas" :id="resposta._id" :key="resposta._id">
        <div class="foto" :style="`background-image: url('${resposta.autor?.aluno?.imagemPerfil || resposta.autor?.produtor?.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`"></div>
        <div class="nome">
          <h4 v-if="resposta.autor?.produtor">ADM</h4>
          <h3>{{ resposta.autor?.aluno?.nome || resposta.autor?.produtor?.nome }}</h3>
          <p>{{ formatarData(resposta.dataRegistro) }}</p>
        </div>
        <div class="texto">
          <p>{{ resposta.texto }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, watch, reactive, inject } from 'vue'
import { useStoreAreas, useStoreCursos, useStoreModulos, useStoreAulas, useStoreComentarios, useStorePerfil } from '@stores'

const storeAulas = useStoreAulas()
const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModulos = useStoreModulos()
const storeComentarios = useStoreComentarios()
const storePerfil = useStorePerfil()
const emitter = inject('emitter')

const state = reactive({
  textoComentario: '',
  textoResposta: '',
  loading: false,
  mostrarBotoes: false,
  mostrarBotoesResposta: false,
  comentarioRespondendo: null
})

const mostrarBotoes = () => {
  state.mostrarBotoes = true
}

const ocultarBotoes = () => {
  if (state.textoComentario === '') {
    state.mostrarBotoes = false
  }
}

const cancelarComentario = () => {
  state.textoComentario = ''
  state.mostrarBotoes = false
}

const mostrarRespondendo = (idComentario) => {
  state.comentarioRespondendo = idComentario
  state.mostrarBotoesResposta = true
}

const cancelarResposta = () => {
  state.textoResposta = ''
  state.mostrarBotoesResposta = false
  state.comentarioRespondendo = null
}

async function comentar() {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id
  const idAula = storeAulas.aula?._id

  const payload = {
    texto: state.textoComentario,
    link: 'https://mber.live'
  }

  if (idArea && idCurso && idModulo && idAula) {
    const comentadoOk = await storeComentarios.comentar(idArea, idCurso, idModulo, idAula, payload)
    if (comentadoOk !== true) return

    state.textoComentario = ''
    state.mostrarBotoes = false
    emitter.emit('atualizarComentarios')
  }
}

async function responder(idComentario) {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id
  const idAula = storeAulas.aula?._id

  console.log(idComentario)

  const payload = {
    texto: state.textoResposta,
    link: 'https://mber.live'
  }

  if (idArea && idCurso && idModulo && idAula && idComentario) {
    const respondidoOk = await storeComentarios.responder(idArea, idCurso, idModulo, idAula, idComentario, payload)
    if (respondidoOk !== true) return

    state.textoResposta = ''
    state.mostrarBotoesResposta = false
    state.comentarioRespondendo = null
    emitter.emit('atualizarComentarios')
  }
}

async function carregarComentarios() {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id
  const idAula = storeAulas.aula?._id

  if (idArea && idCurso && idModulo && idAula) {
    const carregadoOk = await storeComentarios.receberTodos(idArea, idCurso, idModulo, idAula)
    if (carregadoOk !== true) return
  }
}

function formatarData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há segundos'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()
    const hours = String(dataNotificacao.getHours()).padStart(2, '0')
    const minutes = String(dataNotificacao.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} às ${hours}h${minutes}`
  }
}

watch(
  () => [storeAreas.area?._id, storeCursos.curso?._id, storeModulos.modulo?._id, , storeAulas.aula?._id],
  ([idArea, idCurso, idModulo, idAula]) => {
    if ((idArea && idCurso && idModulo, idAula)) {
      carregarComentarios()
    }
  },
  { immediate: true }
)

onMounted(() => {
  emitter.on('atualizarComentarios', carregarComentarios)
})
</script>

<style scoped>
section.comentarios {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 30px 0 20px 0;
}

.comentar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.input {
  align-items: flex-start;
}

.input textarea {
  padding: 20px;
}

.input .foto {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin: 15px 20px 0 0;
}

.botoes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 0 0 0;
}

.botoes button.enviar {
  font-size: var(--f2);
  font-family: var(--bold);
  padding: 12px 20px;
  border-radius: 10px;
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul-forte);
  transition: all 0.3s;
}

.botoes button.enviar:hover {
  background-color: var(--cor-azul-forte-escuro);
}

.botoes button.cancelar {
  font-size: var(--f2);
  font-family: var(--regular);
  color: var(--cor-cinza);
  background-color: transparent;
  padding: 10px;
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.botoes button.cancelar:hover {
  color: var(--cor-preto);
}

.respondendo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 15px 0 0 0;
}

.respondendo .input {
  padding: 0 0 0 50px;
}

.comentario {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 0 55px;
  margin: 40px 0 0 0;
  position: relative;
}

.comentario .foto {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cor-cinza-2);
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
}

.comentario .nome {
  display: flex;
  align-items: center;
}

.comentario .nome h4 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-azul-forte);
  margin: 0 5px 0 0;
}

.comentario .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-preto);
  margin: 0 5px 0 0;
}

.comentario .nome.produtor h3 {
  color: var(--cor-verde);
}

.comentario .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
}

.comentario .texto {
  display: flex;
  padding: 5px 0 0 0;
}

.comentario .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  word-break: break-all;
  line-height: 1.5;
}

.comentario button.responder {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  background-color: var(--cor-branco);
  border: 1px solid var(--cor-cinza-2);
  padding: 8px 15px;
  border-radius: 10px;
  margin: 10px 0 0 0;
  transition: all 0.3s;
}

.comentario button.responder:hover {
  color: var(--cor-preto);
  border: 1px solid var(--cor-azul-forte);
}

.resposta {
  position: relative;
  padding: 0 0 0 45px;
  margin: 30px 0 0 0;
}

.resposta .foto {
  width: 35px;
  height: 35px;
}

.resposta .nome h3 {
  font-size: var(--f1);
}

.resposta .nome h4 {
  font-size: var(--f1);
}

.resposta .texto p {
  font-size: var(--f1);
  word-break: break-all;
}

@media screen and (max-width: 1000px) {
  section.comentarios {
    padding: 20px 0 20px 0;
  }

  .input textarea {
    padding: 15px;
  }

  .input .foto {
    width: 35px;
    min-width: 35px;
    height: 35px;
    margin: 12px 10px 0 0;
  }

  .botoes button.enviar {
    padding: 11px 18px;
  }

  .respondendo {
    padding: 12px 0 0 0;
  }

  .respondendo .input {
    padding: 0 0 0 40px;
  }

  .comentario {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 0 0 45px;
    margin: 35px 0 0 0;
    position: relative;
  }

  .comentario .foto {
    width: 35px;
    min-width: 35px;
    height: 35px;
  }

  .comentario button.responder {
    padding: 7px 13px;
    margin: 8px 0 0 0;
  }

  .resposta {
    position: relative;
    padding: 0 0 0 40px;
    margin: 25px 0 0 0;
  }

  .resposta .foto {
    width: 30px;
    min-width: 30px;
    height: 30px;
    margin: 3px 0 0 0;
  }
}
</style>
