<template>
  <section class="limites">
    <div class="assinatura">
      <div class="icone">
        <img
          :src="`https://arquivos.levelmember.com/imagens/planos/plano-${storePerfil.usuario?.assinatura?.plano || 'default'}.png`">
      </div>
      <div class="nome">
        <h3>Plano {{ storePerfil.usuario?.assinatura?.plano }}</h3>
        <p>Assinatura {{ storePerfil.usuario?.assinatura?.frequencia }}</p>
      </div>
      <div class="areas">
        <p>{{ storeAreas.areas?.length }} / {{ storePerfil.limiteAreas }} <span>áreas de membros</span></p>
        <div class="progresso">
          <div class="atual" :style="`width: ${percentualAreas}%`"></div>
        </div>
      </div>
      <div class="alunos">
        <p>{{ storePainel.estatisticas?.quantidadeAlunos }} / {{ storePerfil.limiteAlunos }} <span>alunos</span></p>
        <div class="progresso">
          <div class="atual" :style="`width: ${percentualAlunos}%`"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, watch, computed } from 'vue'
import { useStorePerfil, useStoreAreas, useStorePainel } from '@stores'

const storePerfil = useStorePerfil()
const storeAreas = useStoreAreas()
const storePainel = useStorePainel()

function carregarAreas() {
  storeAreas.receberTodos()
}

function carregarLimites(plano) {
  if (plano === 'essencial') {
    storePerfil.limiteAlunos = '1.200'
    storePerfil.limiteAreas = '1'
  } else if (plano === 'pro') {
    storePerfil.limiteAlunos = '5.000'
    storePerfil.limiteAreas = '3'
  } else if (plano === 'expert') {
    storePerfil.limiteAlunos = '25.000'
    storePerfil.limiteAreas = '15'
  } else if (plano === 'free') {
    storePerfil.limiteAlunos = '250'
    storePerfil.limiteAreas = '1'
  }
}

const formatedLimiteAlunos = computed(() => {
  return parseInt(storePerfil.limiteAlunos?.replace(/\./g, '') || 1)
})

const percentualAreas = computed(() => {
  const totalAreas = storeAreas.areas?.length || 0
  const limiteAreas = storePerfil.limiteAreas || 1
  return Math.min((totalAreas / limiteAreas) * 100, 100).toFixed(2)
})

const percentualAlunos = computed(() => {
  const totalAlunos = storePainel.estatisticas?.quantidadeAlunos || 0
  const limiteAlunos = formatedLimiteAlunos.value
  return (totalAlunos / limiteAlunos) * 100
})

watch(
  () => storePerfil.usuario?.assinatura?.plano,
  (novoPlano) => {
    if (novoPlano) {
      carregarLimites(novoPlano);
    }
  },
  { immediate: true }
);

onMounted(() => {
  carregarAreas()
})
</script>

<style scoped>
section.limites {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 30px 15px 30px;
  background-color: var(--cor-gelo);
  z-index: 2;
}

.assinatura {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-branco);
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

.assinatura .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-cinza-3);
}

.assinatura .icone img {
  width: 100%;
  max-width: 35px;
}

.assinatura .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 20px;
}

.assinatura .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
  text-transform: capitalize;
}

.assinatura .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
  text-transform: capitalize;
}

.assinatura .areas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 30px 0 0;
  border-right: 1px solid var(--cor-cinza-2);
}

.assinatura .areas p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 0 0 5px 0;
}

.assinatura .areas .progresso {
  width: 100%;
  min-width: 200px;
  height: 10px;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
}

.assinatura .areas .progresso .atual {
  height: 10px;
  width: 20%;
  background: linear-gradient(90deg, var(--degrade));
  border-radius: 10px;
}

.assinatura .alunos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 30px 0 30px;
}

.assinatura .alunos p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 0 0 5px 0;
}

.assinatura .alunos .progresso {
  width: 100%;
  min-width: 200px;
  height: 10px;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
}

.assinatura .alunos .progresso .atual {
  height: 10px;
  width: 20%;
  background: linear-gradient(90deg, var(--degrade-azul));
  border-radius: 10px;
}

@media screen and (max-width: 1000px) {
  section.limites {
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }

  .assinatura {
    flex-wrap: wrap;
    padding: 20px;
  }

  .assinatura .icone {
    width: 50px;
    height: 50px;
  }

  .assinatura .icone img {
    max-width: 25px;
  }

  .assinatura .nome {
    padding: 0 0 0 20px;
  }

  .assinatura .areas {
    height: auto;
    width: 50%;
    padding: 20px 20px 0 0;
    margin: 20px 0 0 0;
  }

  .assinatura .areas p {
    display: flex;
    flex-direction: column;
    font-size: var(--f1);
    line-height: 1.5;
    text-align: center;
  }

  .assinatura .areas .progresso {
    width: 100%;
    min-width: auto;
  }

  .assinatura .alunos {
    height: auto;
    width: 50%;
    padding: 20px 0 0 20px;
    margin: 20px 0 0 0;
  }

  .assinatura .alunos p {
    display: flex;
    flex-direction: column;
    font-size: var(--f1);
    line-height: 1.5;
    text-align: center;
  }

  .assinatura .alunos .progresso {
    width: 100%;
    min-width: auto;
  }
}
</style>
