<template>
  <div id="curso">
    <Loading :class="{ mostrar: state.verLoading }" />
    <ModalCriarAula />
    <ModalCriarModulo />
    <ModalEditarCurso />
    <ModalEditarModulo />
    <ModalEditarPosicaoModulos />
    <ModalDuplicarModulo />

    <SectionTopo />
    <SectionCurso />
    <SectionModulos />
  </div>
</template>

<script setup>
import { reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreCursos, useStoreAreas } from '@stores'

import Loading from '@components/global/loading/Loading.vue'
import ModalEditarPosicaoModulos from '@components/areaDeMembros/curso/modals/EditarPosicaoModulos.vue'
import ModalCriarAula from '@components/areaDeMembros/curso/modals/CriarAula.vue'
import ModalCriarModulo from '@components/areaDeMembros/curso/modals/CriarModulo.vue'
import ModalEditarModulo from '@components/areaDeMembros/curso/modals/EditarModulo.vue'
import ModalEditarCurso from '@components/areaDeMembros/cursos/modals/EditarCurso.vue'
import ModalDuplicarModulo from '@components/areaDeMembros/curso/modals/DuplicarModulo.vue'
import SectionTopo from '@components/areaDeMembros/curso/partials/SectionTopo.vue'
import SectionCurso from '@components/areaDeMembros/curso/partials/SectionCurso.vue'
import SectionModulos from '@components/areaDeMembros/curso/partials/SectionModulos.vue'

const storeCursos = useStoreCursos()
const storeAreas = useStoreAreas()
const router = useRouter()

const state = reactive({
  verLoading: true
})

async function carregarCurso() {
  const idArea = storeAreas.area?._id

  if (idArea) {
    const hotlinkCurso = router.currentRoute.value.params.hotlinkCurso
    await storeCursos.receberPorHotlink(idArea, hotlinkCurso)

    document.title = 'Curso ' + storeCursos.curso?.nome + ' | Level Member'

    setTimeout(() => {
      state.verLoading = false
    }, 300)
  }
}

watch(
  () => storeAreas.area?._id,
  (idArea) => {
    if (idArea) {
      carregarCurso()
    }
  },
  { immediate: true }
)
</script>

<style scoped>
#curso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 250px;
}

@media screen and (max-width: 1000px) {
  #curso {
    padding: 60px 0 0 0;
  }
}
</style>
