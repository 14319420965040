<template>
  <Modal nome="editarArea">
    <label>
      Nome da área
      <span>*</span>
    </label>
    <input v-model="state.nome" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Mentoria Turbo" @keydown.enter="salvarDados" />

    <label>
      Descrição da área
      <span>*</span>
    </label>
    <input v-model="state.descricao" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Mentoria com foco em resultados" @keydown.enter="salvarDados" />

    <label>
      URL padrão da sua área
      <span>*</span>
    </label>
    <div class="inputUrl">
      <p>mber.live/</p>
      <input v-model="state.hotlink" type="text" spellCheck="false" autocomplete="false" @input="validarHotlink()" placeholder="sua-area" @keydown.enter="salvarDados" />
    </div>

    <label>
      Logo da área
      <span>*</span>
    </label>
    <div class="inputFile">
      <button @click="carregarImagemLogo()" :disabled="state.uploadImagemLogo.desabilitarBotao">
        <span></span>
        <Svgs nome="arquivo" />
        <h3>Subir Imagem</h3>
        <p>Tamanho: 500x150</p>
        <UploadImagem :dados="state.uploadImagemLogo" ref="uploadImagemLogo" @atualizarDadosImagem="atualizarDadosImagemLogo($event)" />
      </button>
      <div class="previa" :style="`background-image: url('${state.imagemLogo || ''}')`"></div>
    </div>

    <label>
      Imagem de capa da área
      <span>*</span>
    </label>
    <div class="inputFile">
      <button @click="carregarImagem()" :disabled="state.uploadImagem.desabilitarBotao">
        <span></span>
        <Svgs nome="arquivo" />
        <h3>Subir Imagem</h3>
        <p>Tamanho: 600x200</p>
        <UploadImagem :dados="state.uploadImagem" ref="uploadImagem" @atualizarDadosImagem="atualizarDadosImagem($event)" />
      </button>
      <div class="previa" :style="`background-image: url('${state.imagemCapa || ''}')`"></div>
    </div>

    <label>
      Senha padrão dos alunos
      <span>*</span>
    </label>
    <div class="inputInstrucao">
      <p>Novos alunos vão ter por padrão a senha abaixo, copie e salve em algum local seguro que só você tenha acesso! Use letras e números para ser uma senha SEGURA aos seus alunos</p>
      <div class="input">
        <Svgs class="icone" nome="cadeado" />
        <input v-model="state.senhaAlunos" type="text" spellCheck="false" autocomplete="false" @input="validarSenhaAlunos()" placeholder="Digite a senha aqui..." @keydown.enter="salvarDados" />
      </div>
    </div>

    <label>
      E-mail de suporte da área
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="envelope" />
      <input v-model="state.emailSuporte" type="email" spellCheck="false" autocomplete="false" placeholder="email@email.com" @keydown.enter="salvarDados" />
    </div>

    <SalvarModal nome="Salvar Alterações" @click="salvarDados" />

    <ExclusaoModal tipo="Área de Membros" @confirmarExclusao="excluirArea()" />
  </Modal>
</template>

<script setup>
import { inject, reactive, ref, onMounted, watch, computed } from 'vue'
import { useStoreAreas, useStoreModal } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import ExclusaoModal from '@components/global/elementos/ExclusaoModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import UploadImagem from '@components/global/upload/image.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('editarArea'))
const uploadImagem = ref(null)
const uploadImagemLogo = ref(null)

const state = reactive({
  nome: '',
  descricao: '',
  hotlink: '',
  imagemCapa: '',
  imagemLogo: '',
  emailSuporte: '',
  senhaAlunos: '',
  uploadImagem: {
    tamanhoMaximo: 1920,
    urlImagem: '',
    desabilitarBotao: false
  },
  uploadImagemLogo: {
    tamanhoMaximo: 1920,
    urlImagem: '',
    desabilitarBotao: false
  }
})

function atualizarDadosImagem(dados) {
  if (typeof dados.desabilitarBotao === 'boolean') state.uploadImagem.desabilitarBotao = dados.desabilitarBotao
  if (typeof dados.urlImagem === 'string') {
    state.uploadImagem.urlImagem = dados.urlImagem
    state.imagemCapa = state.uploadImagem.urlImagem
  }
}

function carregarImagem() {
  uploadImagem?.value?.enviarImagem()
}

function atualizarDadosImagemLogo(dados) {
  if (typeof dados.desabilitarBotao === 'boolean') state.uploadImagemLogo.desabilitarBotao = dados.desabilitarBotao
  if (typeof dados.urlImagem === 'string') {
    state.uploadImagemLogo.urlImagem = dados.urlImagem
    state.imagemLogo = state.uploadImagemLogo.urlImagem
  }
}

function carregarImagemLogo() {
  uploadImagemLogo?.value?.enviarImagem()
}

function validarHotlink() {
  let hotlink = state.hotlink

  hotlink = hotlink.toLowerCase()
  hotlink = hotlink.replace(/\s+/g, '-')
  hotlink = hotlink.replace(/[^a-z0-9-]/g, '')

  state.hotlink = hotlink
}

function validarSenhaAlunos() {
  let senha = state.senhaAlunos

  senha = senha.replace(/\s+/g, '-')

  senha = senha.replace(/[^a-zA-Z0-9-_.~!*'();:@&=+$,/?%#\[\]]/g, '')

  state.senhaAlunos = senha
}

async function salvarDados() {
  if (!validarDados()) return

  const payload = {
    idArea: storeAreas.area?._id,
    nome: state.nome,
    descricao: state.descricao,
    hotlink: state.hotlink?.trim(),
    imagemLogo: state.imagemLogo,
    imagemCapa: state.imagemCapa,
    senhaPadraoAlunos: state.senhaAlunos,
    emailSuporte: state.emailSuporte
  }

  const editadoOk = await storeAreas.alterarDados(payload)
  if (editadoOk !== true) return

  emitter.emit('atualizarAreas')
  storeModal.fecharModal('editarArea')
  limparCampos()
}

async function excluirArea() {
  const idArea = storeAreas.area?._id
  const delecaoOk = await storeAreas.deletar(idArea)
  if (delecaoOk !== true) return

  emitter.emit('atualizarAreas')
  storeModal.fecharModal('editarArea')
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.descricao || !state.hotlink || !state.emailSuporte) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

function limparCampos() {
  state.nome = ''
  state.descricao = ''
  state.hotlink = ''
  state.emailSuporte = ''
  state.senhaAlunos = ''
  state.imagemCapa = ''
  state.imagemLogo = ''
}

function preencherDados() {
  state.nome = storeAreas.area?.nome
  state.descricao = storeAreas.area?.descricao
  state.hotlink = storeAreas.area?.hotlink
  state.emailSuporte = storeAreas.area?.emailSuporte
  state.senhaAlunos = storeAreas.area?.senhaPadraoAlunos
  state.imagemCapa = storeAreas.area?.imagemCapa
  state.imagemLogo = storeAreas.area?.imagemLogo
}

async function pegarIdArea() {
  if (dadosModal.value.id) {
    await storeAreas.receberPorId(dadosModal.value.id)
    preencherDados()
  }
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarIdArea()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.titulo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(0deg, var(--degrade));
}

.titulo .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}

.titulo .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
}

.titulo .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
  line-height: 1.5;
}

.titulo .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
}

label {
  margin: 20px 0 10px 0;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

.excluirModal {
  margin: 30px 0 0 0;
}

@media screen and (max-width: 1000px) {
  .titulo .nome h3 {
    font-size: var(--f2);
  }

  .titulo .nome p {
    font-size: var(--f1);
  }
}
</style>
