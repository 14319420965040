<template>
  <Modal nome="duplicarModulo">
    <div class="area">
      <div class="capa" :style="`background-image: url('${state.imagemCapa}')`"></div>
      <h3>{{ state.nome }}</h3>
      <p>{{ state.descricao }}</p>
    </div>

    <div class="aviso">
      <div class="item">
        <Svgs nome="play" />
        <p>Todas as aulas serão duplicados</p>
      </div>
      <div class="item">
        <Svgs nome="modulo" />
        <p>Todas as informações do módulo serão duplicadas</p>
      </div>
      <div class="item">
        <Svgs nome="chat" />
        <p>Comentários das aulas serão zerados nessa cópia</p>
      </div>
    </div>

    <SalvarModal nome="Duplicar Módulo" @click="duplicarModulo" />
  </Modal>
</template>

<script setup>
import { inject, reactive, onMounted, computed } from 'vue'
import { useStoreAreas, useStoreModal, useStoreCursos, useStoreModulos } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModulos = useStoreModulos()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('duplicarModulo'))

const state = reactive({
  nome: '',
  descricao: '',
  imagemCapa: ''
})

async function duplicarModulo() {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id

  const editadoOk = await storeModulos.duplicar(idArea, idCurso, idModulo)
  if (editadoOk !== true) return

  emitter.emit('atualizarModulos')
  storeModal.fecharModal('duplicarModulo')
  limparCampos()
}

function limparCampos() {
  state.nome = ''
  state.descricao = ''
  state.imagemCapa = ''
}

async function preencherDados() {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  if (dadosModal.value.id) {
    await storeModulos.receberPorId(idArea, idCurso, dadosModal.value.id)
  }
  state.nome = storeModulos.modulo?.nome
  state.descricao = storeModulos.modulo?.descricao
  state.imagemCapa = storeModulos.modulo?.imagemCapa
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
  emitter.on('carregarCampos', preencherDados)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

.aviso {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 10px 0 5px 0;
}

.aviso .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 0 0;
}

.aviso .item svg {
  width: 20px;
  min-width: 20px;
  max-height: 20px;
  fill: var(--cor-azul-forte);
  margin: 0 10px 0 0;
}

.aviso .item p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  line-height: 1.5;
}

.area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  background-color: var(--cor-branco);
  margin: 30px 0 0 0;
  padding: 40px 30px;
}

.area .capa {
  width: 180px;
  height: 180px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.area h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
  line-height: 1.5;
  margin: 20px 0 0 0;
}

.area p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
}

@media screen and (max-width: 1000px) {
}
</style>
