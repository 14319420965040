import { defineStore } from 'pinia'

export const useStoreModal = defineStore('storeModal', {
  state: () => ({
    modal: {},
    dadosModal: {},
    nomeModal: '',
    modals: ''
  }),
  actions: {
    abrirModal(nome, cor, icone, titulo, subtitulo) {
      this.modal[nome] = true
      this.dadosModal[nome] = {
        cor,
        icone,
        titulo,
        subtitulo
      }
    },
    abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
      this.modal[nome] = true
      this.dadosModal[nome] = {
        cor,
        icone,
        titulo,
        subtitulo,
        id
      }
    },
    fecharModal(nome) {
      this.modal[nome] = false
      this.dadosModal[nome] = {}
    },
    modalAberto(nome) {
      return this.modal[nome] || false
    },
    dadosDoModal(nome) {
      return this.dadosModal[nome] || {}
    }
  }
})
