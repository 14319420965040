<template>
  <div id="modulo">
    <Loading :class="{ mostrar: state.verLoading }" />
    <ModalCriarAula />
    <ModalEditarAula />
    <ModalEditarModulo />
    <ModalEditarPosicaoAulas />
    <ModalDuplicarAula />
    <SectionTopo />
    <SectionCurso />
    <SectionAulas />
  </div>
</template>

<script setup>
import { watch, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreCursos, useStoreAreas, useStoreModulos } from '@stores'

import Loading from '@components/global/loading/Loading.vue'
import ModalEditarPosicaoAulas from '@components/areaDeMembros/modulo/modals/EditarPosicaoAulas.vue'
import ModalCriarAula from '@components/areaDeMembros/curso/modals/CriarAula.vue'
import ModalEditarModulo from '@components/areaDeMembros/curso/modals/EditarModulo.vue'
import ModalEditarAula from '@components/areaDeMembros/modulo/modals/EditarAula.vue'
import ModalDuplicarAula from '@components/areaDeMembros/modulo/modals/DuplicarAula.vue'
import SectionTopo from '@components/areaDeMembros/modulo/partials/SectionTopo.vue'
import SectionCurso from '@components/areaDeMembros/modulo/partials/SectionModulo.vue'
import SectionAulas from '@components/areaDeMembros/modulo/partials/SectionAulas.vue'

const storeModulos = useStoreModulos()
const storeCursos = useStoreCursos()
const storeAreas = useStoreAreas()
const router = useRouter()

const state = reactive({
  verLoading: true
})

async function carregarModulo() {
  const idArea = storeAreas.area?._id
  let idCurso = storeCursos.curso?._id

  if (idArea && !idCurso) {
    const hotlinkCurso = router.currentRoute.value.params.hotlinkCurso
    await storeCursos.receberPorHotlink(idArea, hotlinkCurso)
    idCurso = storeCursos.curso?._id
  }

  if (idArea && idCurso) {
    const hotlinkModulo = router.currentRoute.value.params.hotlinkModulo
    await storeModulos.receberPorHotlink(idArea, idCurso, hotlinkModulo)

    document.title = 'Módulo ' + storeModulos.modulo?.nome + ' | Level Member'

    setTimeout(() => {
      state.verLoading = false
    }, 300)
  }
}

// Watch para observar mudanças em idArea e hotlinkCurso
watch(
  () => [storeAreas.area?._id, router.currentRoute.value.params.hotlinkCurso],
  async ([idArea, hotlinkCurso]) => {
    if (idArea && hotlinkCurso && !storeCursos.curso?._id) {
      await storeCursos.receberPorHotlink(idArea, hotlinkCurso)
    }
    carregarModulo()
  },
  { immediate: true }
)
</script>

<style scoped>
#modulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 250px;
}

@media screen and (max-width: 1000px) {
  #modulo {
    padding: 60px 0 0 0;
  }
}
</style>
