<template>
  <section class="lista">
    <div class="quadro" v-for="aluno in alunosFiltrados" :key="aluno._id">
      <div class="capa">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${aluno.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`"></div>
        </div>
      </div>
      <div class="nome">
        <h3>{{ aluno.nome }}</h3>
        <p>{{ aluno.email }}</p>
      </div>
      <div class="parte cadastro">
        <h3>Cadastro</h3>
        <div class="item">
          <Svgs nome="relogio" />
          <p>{{ formatarData(aluno.dataRegistro) }}</p>
        </div>
      </div>
      <div class="parte acesso">
        <h3>Último Acesso</h3>
        <div class="item">
          <Svgs nome="ultimo" />
          <p>{{ formatarData(aluno.dataUltimoAcesso) }}</p>
        </div>
      </div>
      <div class="parte cursos">
        <h3>Cursos</h3>
        <div class="item">
          <Svgs nome="curso" />
          <p>{{ aluno.numeroCursosComprados }}</p>
        </div>
      </div>
      <div class="parte pontos">
        <h3>Pontos</h3>
        <div class="item">
          <Svgs nome="dinheiro" />
          <p>{{ aluno.pontos }}</p>
        </div>
      </div>
      <button
        @click="
          abrirModalEdicao(
            'editarAluno', // nome do modal
            'degrade-azul', // cor do icone
            'visualizar', // nome do icone
            'Visualizar Aluno', // titulo
            'Administre seu aluno', // subtitulo
            aluno._id // id
          )
        ">
        <p>Ver aluno</p>
        <Svgs nome="seta" />
      </button>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreModal, useStoreAlunos } from '@stores'
import Svgs from '@svgs'

const storeAlunos = useStoreAlunos()
const storeModal = useStoreModal()

const alunosFiltrados = computed(() => {
  const filtroBusca = storeAlunos.filtroBusca.toLowerCase()
  const filtroOrdem = storeAlunos.filtroOrdem
  const filtroCurso = storeAlunos.filtroCurso

  if (!storeAlunos.alunos) return []

  return storeAlunos.alunos
    .filter((aluno) => {
      const condicoes = [aluno.nome.toLowerCase().includes(filtroBusca) || aluno.email.toLowerCase().includes(filtroBusca)]

      if (filtroCurso && filtroCurso !== 'todos') {
        const cursoAdquirido = aluno.cursosComprados.some((curso) => curso.idCurso === filtroCurso)
        condicoes.push(cursoAdquirido)
      }

      return condicoes.every(Boolean)
    })
    .sort((a, b) => {
      if (filtroOrdem === 'nomeAsc') {
        return a.nome.localeCompare(b.nome)
      } else if (filtroOrdem === 'nomeDesc') {
        return b.nome.localeCompare(a.nome)
      } else if (filtroOrdem === 'cadastroMaisRecente') {
        return new Date(b.dataRegistro) - new Date(a.dataRegistro)
      } else if (filtroOrdem === 'cadastroMaisAntigo') {
        return new Date(a.dataRegistro) - new Date(b.dataRegistro)
      } else {
        return 0
      }
    })
})

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}

function formatarData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há segundos'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()
    const hours = String(dataNotificacao.getHours()).padStart(2, '0')
    const minutes = String(dataNotificacao.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} às ${hours}h${minutes}`
  }
}
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 25px 20px 30px;
  overflow-x: scroll;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-branco);
  width: 100%;
  padding: 20px;
  min-width: 1000px;
  margin: 0 0 10px 0;
  border-radius: 10px;
}

.quadro .capa {
  width: 100%;
  max-width: 45px;
}

.quadro .capa .tamanho {
  position: relative;
  padding-bottom: 100%;
  width: 100%;
}

.quadro .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-cinza-2);
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.quadro .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 20px 0 10px;
  transition: all 0.3s;
}

.quadro .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
}

.quadro .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}

.quadro .parte {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--cor-cinza-2);
}

.quadro .parte.cadastro {
  min-width: 175px;
}

.quadro .parte.acesso {
  min-width: 175px;
}

.quadro .parte.cursos {
  min-width: 90px;
}

.quadro .parte.pontos {
  min-width: 90px;
}

.quadro .parte h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  margin: 0 0 10px 0;
}

.quadro .parte .item {
  display: flex;
  align-items: center;
}

.quadro .parte .item svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-cinza);
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

.quadro .parte .item p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-preto);
  transition: all 0.3s;
}

.quadro button {
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 10px 15px;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-cinza-2);
  transition: all 0.3s;
}

.quadro button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-preto);
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

.quadro button:hover {
  border: 2px solid var(--cor-azul-forte);
}

.quadro button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-preto);
}

@media screen and (max-width: 1000px) {
  section.lista {
    flex-direction: column;
    padding: 0 15px 20px 20px;
  }

  .quadro {
    flex-wrap: wrap;
    padding: 15px;
    min-width: auto;
  }

  .quadro .capa {
    width: 100%;
    max-width: 40px;
    margin: 0 0 15px 0;
  }

  .quadro .nome {
    width: calc(100% - 120px);
    padding: 0 0 15px 10px;
  }

  .quadro .nome h3 {
    font-size: var(--f2);
  }

  .quadro .nome p {
    font-size: var(--f1);
  }

  .quadro .parte {
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid var(--cor-cinza-2);
    border-left: none;
  }

  .quadro .parte.cadastro {
    min-width: auto;
  }

  .quadro .parte.acesso {
    min-width: auto;
  }

  .quadro .parte.cursos {
    min-width: auto;
  }

  .quadro .parte.pontos {
    min-width: auto;
  }

  .quadro .parte h3 {
    margin: 0;
  }

  .quadro .parte button {
    padding: 8px 15px;
  }

  .quadro .parte button svg {
    width: 13px;
    min-width: 13px;
    margin: 0 8px 0 0;
  }

  .quadro button {
    margin: 10px 0 0 0;
  }
}
</style>
