<template>
  <Modal nome="criarArea">
    <label>
      Nome da área
      <span>*</span>
    </label>
    <input v-model="state.nome" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Mentoria Turbo" @keydown.enter="criarArea" />

    <label>
      Descrição da área
      <span>*</span>
    </label>
    <input v-model="state.descricao" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Mentoria com foco em resultados" @keydown.enter="criarArea" />

    <label>
      URL padrão da sua área
      <span>*</span>
    </label>
    <div class="inputUrl">
      <p>mber.live/</p>
      <input v-model="state.hotlink" type="text" spellCheck="false" autocomplete="false" @input="validarHotlink()" placeholder="sua-area" @keydown.enter="criarArea" />
    </div>

    <label>
      Logo da área
      <span>*</span>
    </label>
    <div class="inputFile">
      <button @click="carregarImagemLogo()" :disabled="state.uploadImagemLogo.desabilitarBotao">
        <span></span>
        <Svgs nome="arquivo" />
        <h3>Subir Imagem</h3>
        <p>Tamanho: 500x150</p>
        <UploadImagem :dados="state.uploadImagemLogo" ref="uploadImagemLogo" @atualizarDadosImagem="atualizarDadosImagemLogo($event)" />
      </button>
      <div class="previa" :style="`background-image: url('${state.imagemLogo || ''}')`"></div>
    </div>

    <label>
      Imagem de capa da área
      <span>*</span>
    </label>
    <div class="inputFile">
      <button @click="carregarImagem()" :disabled="state.uploadImagem.desabilitarBotao">
        <span></span>
        <Svgs nome="arquivo" />
        <h3>Subir Imagem</h3>
        <p>Tamanho: 600x200</p>
        <UploadImagem :dados="state.uploadImagem" ref="uploadImagem" @atualizarDadosImagem="atualizarDadosImagem($event)" />
      </button>
      <div class="previa" :style="`background-image: url('${state.imagemCapa || ''}')`"></div>
    </div>

    <label>
      Senha padrão dos alunos
      <span>*</span>
    </label>
    <div class="inputInstrucao">
      <p>Novos alunos vão ter por padrão a senha abaixo, copie e salve em algum local seguro que só você tenha acesso! Use letras e números para ser uma senha SEGURA aos seus alunos</p>
      <div class="input">
        <Svgs class="icone" nome="cadeado" />
        <input v-model="state.senhaAlunos" type="text" spellCheck="false" autocomplete="false" @input="validarSenhaAlunos()" placeholder="Digite a senha aqui..." @keydown.enter="criarArea" />
      </div>
    </div>

    <label>
      E-mail de suporte da área
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="envelope" />
      <input v-model="state.emailSuporte" type="email" spellCheck="false" autocomplete="false" placeholder="email@email.com" @keydown.enter="criarArea" />
    </div>

    <label>
      Cor da marca
      <span>*</span>
    </label>
    <div class="corMarca" ref="seletorCor">
      <button class="selecionar" @click="abrirSeletorCor()">
        <h3>Selecionar cor</h3>
        <p>{{ state.corMarca }}</p>
        <div class="cor" :style="`background-color: ${state.corMarca}`"></div>
      </button>
      <div class="seletorCor" v-if="selecionandoCor">
        <div class="nome">
          <h3>Selecionar cor</h3>
          <button class="ocultar" @click="ocultarSeletor()"><Svgs nome="x" /></button>
        </div>
        <ColorPicker class="elemento" :key="corPickerKey" :color="corSelecionada" @update:color="mudarCor" />
      </div>
    </div>

    <label>
      Formato da área
      <span>*</span>
    </label>
    <div class="formatos">
      <button class="ilhas" @click="selecionarFormato('ilhas')" :class="{ ativo: state.formatoArea === 'ilhas' }">
        <div class="capa" style="background-image: url('https://arquivos.levelmember.com/imagens/templates/ilhas/vetor-ilhas.svg')"></div>
        <div class="texto">
          <h3>Ilhas</h3>
          <p>Formato mais gameficado com os conteúdos em trilhas</p>
          <div class="icone">
            <Svgs nome="check" />
          </div>
        </div>
      </button>
      <button class="listas" @click="selecionarFormato('listas')" :class="{ ativo: state.formatoArea === 'listas' }">
        <div class="capa" style="background-image: url('https://arquivos.levelmember.com/imagens/templates/listas/vetor-listas.svg')"></div>
        <div class="texto">
          <h3>Listas</h3>
          <p>Formato mais prático com os conteúdos em listas</p>
          <div class="icone">
            <Svgs nome="check" />
          </div>
        </div>
      </button>
      <button class="quadros" @click="selecionarFormato('quadros')" :class="{ ativo: state.formatoArea === 'quadros' }">
        <div class="capa" style="background-image: url('https://arquivos.levelmember.com/imagens/templates/quadros/vetor-quadros.svg')"></div>
        <div class="texto">
          <h3>Quadros</h3>
          <p>Formato mais visual com os conteúdos em quadros</p>
          <div class="icone">
            <Svgs nome="check" />
          </div>
        </div>
      </button>
    </div>

    <label>
      Tema da área
      <span>*</span>
    </label>
    <div class="temas">
      <button class="claro" @click="selecionarTema('claro')" :class="{ ativo: state.temaArea === 'claro' }">
        <div class="capa" :style="`background-image: url('https://arquivos.levelmember.com/imagens/templates/${state.formatoArea}/vetor-modo-claro.svg')`"></div>
        <div class="texto">
          <h3>Claro</h3>
          <p>Fundo branco com textos pretos</p>
          <div class="icone">
            <Svgs nome="check" />
          </div>
        </div>
      </button>
      <button class="escuro" @click="selecionarTema('escuro')" :class="{ ativo: state.temaArea === 'escuro' }">
        <div class="capa" :style="`background-image: url('https://arquivos.levelmember.com/imagens/templates/${state.formatoArea}/vetor-modo-escuro.svg')`"></div>
        <div class="texto">
          <h3>Escuro</h3>
          <p>Fundo preto com textos brancos</p>
          <div class="icone">
            <Svgs nome="check" />
          </div>
        </div>
      </button>
    </div>

    <SalvarModal nome="Criar nova área" @click="criarArea" />
  </Modal>
</template>

<script setup>
import { inject, onMounted, reactive, ref } from 'vue'
import { useStoreAreas } from '@stores'
import { useStoreIlhasClaro, useStoreIlhasEscuro, useStoreListasClaro, useStoreListasEscuro, useStoreQuadrosClaro, useStoreQuadrosEscuro } from '@components/app/areas/templates'
import { helperClicouFora } from '@helpers'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import UploadImagem from '@components/global/upload/image.vue'
import ColorPicker from '@components/global/elementos/ColorPicker.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeIlhasClaro = useStoreIlhasClaro()
const storeIlhasEscuro = useStoreIlhasEscuro()
const storeListasClaro = useStoreListasClaro()
const storeListasEscuro = useStoreListasEscuro()
const storeQuadrosClaro = useStoreQuadrosClaro()
const storeQuadrosEscuro = useStoreQuadrosEscuro()
const uploadImagem = ref(null)
const uploadImagemLogo = ref(null)
const seletorCor = ref(null)
const corSelecionada = ref('#FFFFFF')
const selecionandoCor = ref(false)
const corPickerKey = ref(0)

const state = reactive({
  nome: '',
  descricao: '',
  hotlink: '',
  imagemCapa: '',
  imagemLogo: '',
  emailSuporte: '',
  formatoArea: 'ilhas',
  temaArea: 'claro',
  senhaAlunos: '',
  personalizacao: {
    telaLogin: {},
    telaAlterarSenha: {},
    telaErro: {},
    telaLoading: {},
    telaCursosArea: {},
    telaCurso: {},
    telaModulo: {},
    telaAula: {},
    telaPerfil: {},
    menu: {}
  },
  uploadImagem: {
    tamanhoMaximo: 1920,
    urlImagem: '',
    desabilitarBotao: false
  },
  uploadImagemLogo: {
    tamanhoMaximo: 1920,
    urlImagem: '',
    desabilitarBotao: false
  }
})

function atualizarDadosImagem(dados) {
  if (typeof dados.desabilitarBotao === 'boolean') state.uploadImagem.desabilitarBotao = dados.desabilitarBotao
  if (typeof dados.urlImagem === 'string') {
    state.uploadImagem.urlImagem = dados.urlImagem
    state.imagemCapa = state.uploadImagem.urlImagem
  }
}

function carregarImagem() {
  uploadImagem?.value?.enviarImagem()
}

function atualizarDadosImagemLogo(dados) {
  if (typeof dados.desabilitarBotao === 'boolean') state.uploadImagemLogo.desabilitarBotao = dados.desabilitarBotao
  if (typeof dados.urlImagem === 'string') {
    state.uploadImagemLogo.urlImagem = dados.urlImagem
    state.imagemLogo = state.uploadImagemLogo.urlImagem
  }
}

function carregarImagemLogo() {
  uploadImagemLogo?.value?.enviarImagem()
}

function mudarCor(novaCor) {
  const hexColor = novaCor.hex || novaCor
  corSelecionada.value = hexColor
  state.corMarca = hexColor
}

function abrirSeletorCor() {
  corSelecionada.value = state.corMarca || '#FFFFFF'
  selecionandoCor.value = true
  corPickerKey.value += 1
}

function ocultarSeletor() {
  selecionandoCor.value = false
}

function selecionarFormato(formato) {
  state.formatoArea = formato
}

function selecionarTema(tema) {
  state.temaArea = tema
}

function configurarEstiloArea() {
  const storeMap = {
    IlhasClaro: storeIlhasClaro.personalizacao,
    IlhasEscuro: storeIlhasEscuro.personalizacao,
    ListasClaro: storeListasClaro.personalizacao,
    ListasEscuro: storeListasEscuro.personalizacao,
    QuadrosClaro: storeQuadrosClaro.personalizacao,
    QuadrosEscuro: storeQuadrosEscuro.personalizacao
  }

  const storeKey = `${capitalizeFirstLetter(state.formatoArea)}${capitalizeFirstLetter(state.temaArea)}`
  const store = storeMap[storeKey]
  console.log(store)
  const logotipo = state.imagemLogo
  const banner = state.imagemCapa
  const corMarca = state.corMarca
  const nome = state.nome

  if (store) {
    // Personalização base
    state.personalizacao.telaLogin = store.telaLogin
    state.personalizacao.telaAlterarSenha = store.telaAlterarSenha
    state.personalizacao.telaLoading = store.telaLoading
    state.personalizacao.telaCursosArea = store.telaInicio
    state.personalizacao.telaCurso = store.telaCurso
    state.personalizacao.telaModulo = store.telaModulo
    state.personalizacao.telaAula = store.telaAula
    state.personalizacao.telaPerfil = store.telaPerfil
    state.personalizacao.menu = store.menu
    // Personalização do Nome
    state.personalizacao.telaLogin.textoTitulo = nome
    state.personalizacao.menu.textoTitulo = nome
    state.personalizacao.telaCursosArea.textoCapaTitulo = nome
    // Personalizando o logotipo
    state.personalizacao.menu.imagemLogo = logotipo
    state.personalizacao.telaLogin.imagemLogo = logotipo
    state.personalizacao.telaAlterarSenha.imagemLogo = logotipo
    state.personalizacao.telaLoading.imagemLogo = logotipo
    // Personalização do Banner
    state.personalizacao.telaPerfil.imagemCapa = banner
    state.personalizacao.telaLogin.imagemFundo = banner
    state.personalizacao.telaAlterarSenha.imagemFundo = banner
    state.personalizacao.telaCursosArea.imagemCapaDesktop = banner
    state.personalizacao.telaCursosArea.imagemCapaMobile = banner
    // Personalizanco cor da marca
    state.personalizacao.telaLogin.corFundoBotao = corMarca
    state.personalizacao.telaAlterarSenha.corFundoBotao = corMarca
    state.personalizacao.telaLoading.corLoading = corMarca
    state.personalizacao.telaCursosArea.corFundoBotao = corMarca
    state.personalizacao.telaCursosArea.corProgressoAtual = corMarca
    state.personalizacao.menu.corIcones = corMarca
    state.personalizacao.telaCurso.corFundoBotao = corMarca
    state.personalizacao.telaCurso.corCaminho = corMarca
    state.personalizacao.telaModulo.corFundoBotao = corMarca
    state.personalizacao.telaModulo.corCaminho = corMarca
    state.personalizacao.telaAula.corLinks = corMarca
    state.personalizacao.telaAula.corArquivos = corMarca
    state.personalizacao.telaAula.corFundoBotaoComentar = corMarca
    state.personalizacao.telaAula.corFundoBotaoAula = corMarca
    state.personalizacao.telaPerfil.corTitulos = corMarca
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function validarHotlink() {
  let hotlink = state.hotlink

  hotlink = hotlink.toLowerCase()
  hotlink = hotlink.replace(/\s+/g, '-')
  hotlink = hotlink.replace(/[^a-z0-9-]/g, '')

  state.hotlink = hotlink
}

function validarSenhaAlunos() {
  let senha = state.senhaAlunos

  senha = senha.replace(/\s+/g, '-')

  senha = senha.replace(/[^a-zA-Z0-9-_.~!*'();:@&=+$,/?%#\[\]]/g, '')

  state.senhaAlunos = senha
}

async function criarArea() {
  configurarEstiloArea()
  if (!validarDados()) return

  const payload = {
    nome: state.nome,
    descricao: state.descricao,
    hotlink: state.hotlink?.trim(),
    imagemCapa: state.imagemCapa,
    imagemLogo: state.imagemLogo,
    senhaPadraoAlunos: state.senhaAlunos,
    emailSuporte: state.emailSuporte,
    personalizacao: {
      telaLogin: state.personalizacao?.telaLogin,
      telaAlterarSenha: state.personalizacao?.telaAlterarSenha,
      telaLoading: state.personalizacao?.telaLoading,
      telaCursosArea: state.personalizacao?.telaCursosArea,
      telaCurso: state.personalizacao?.telaCurso,
      telaModulo: state.personalizacao?.telaModulo,
      telaAula: state.personalizacao?.telaAula,
      telaPerfil: state.personalizacao?.telaPerfil,
      menu: state.personalizacao?.menu
    }
  }

  const adicionadoOk = await storeAreas.adicionar(payload)
  if (adicionadoOk !== true) return

  setTimeout(() => {
    window.open(`/areas/${payload.hotlink}`, '_self')
  }, 1000)
  limparCampos()
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.descricao || !state.hotlink || !state.emailSuporte) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

function limparCampos() {
  state.nome = ''
  state.descricao = ''
  state.hotlink = ''
  state.emailSuporte = ''
  state.senhaAlunos = ''
  state.imagemCapa = ''
  state.imagemLogo = ''
}

helperClicouFora.clicouFora([{ alvo: seletorCor, retornoChamada: ocultarSeletor }])

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.titulo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(0deg, var(--degrade));
}

.titulo .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}

.titulo .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
}

.titulo .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
  line-height: 1.5;
}

.titulo .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
}

.formatos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.formatos button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(33.33% - 5px);
  border-radius: 12px;
  background-color: var(--cor-branco);
  border: 1px solid var(--cor-branco);
  transition: all 0.3s;
}

.formatos button .capa {
  width: 100%;
  height: 90px;
  border-radius: 10px 10px 0 0;
  background-size: cover;
  background-position: center;
}

.formatos button .texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px 10px;
}

.formatos button .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

.formatos button .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  line-height: 1.5;
  margin: 5px 0 10px 0;
}

.formatos button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}

.formatos button .icone svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.formatos button.ativo .icone svg {
  opacity: 1;
  visibility: visible;
}

.formatos button.ativo.ilhas {
  border: 1px solid #fea800;
}

.formatos button.ativo.listas {
  border: 1px solid #90d422;
}

.formatos button.ativo.quadros {
  border: 1px solid #0497ff;
}

.formatos button.ativo.ilhas .icone {
  background-color: #fea800;
}

.formatos button.ativo.listas .icone {
  background-color: #90d422;
}

.formatos button.ativo.quadros .icone {
  background-color: #0497ff;
}

.formatos button.ativo.ilhas .texto h4 span {
  background-color: #fea800;
}

.formatos button.ativo.listas .texto h4 span {
  background-color: #90d422;
}

.formatos button.ativo.quadros .texto h4 span {
  background-color: #0497ff;
}

.temas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.temas button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50% - 5px);
  border-radius: 12px;
  background-color: var(--cor-branco);
  border: 1px solid var(--cor-branco);
  transition: all 0.3s;
}

.temas button .capa {
  width: 100%;
  height: 130px;
  border-radius: 10px 10px 0 0;
  background-size: cover;
  background-position: center;
}

.temas button .texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px 10px;
}

.temas button .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

.temas button .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  line-height: 1.5;
  margin: 5px 0 10px 0;
}

.temas button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}

.temas button .icone svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.temas button.ativo .icone {
  background-color: var(--cor-azul-forte);
}

.temas button.ativo .icone svg {
  opacity: 1;
  visibility: visible;
}

.temas button.ativo {
  border: 1px solid var(--cor-azul-forte);
}

.corMarca {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  z-index: 2;
}

.corMarca button.selecionar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 60px 15px 15px;
  border-radius: 10px;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-branco);
  width: 100%;
  position: relative;
  transition: all 0.3s;
}

.corMarca button.selecionar:hover {
  border: 2px solid var(--cor-cinza-2);
}

.corMarca button.selecionar .cor {
  position: absolute;
  height: 100%;
  width: 35px;
  border-radius: 0 10px 10px 0;
  right: 0;
  top: 0;
}

.corMarca button.selecionar p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-cinza);
}

.corMarca button.selecionar h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

label {
  margin: 20px 0 10px 0;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

@media screen and (max-width: 1000px) {
  .titulo .nome h3 {
    font-size: var(--f2);
  }

  .titulo .nome p {
    font-size: var(--f1);
  }

  .formatos {
    flex-direction: column;
    justify-content: flex-start;
  }

  .formatos button {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 10px;
    position: relative;
  }

  .formatos button .capa {
    width: 130px;
    max-width: 130px;
    min-width: 130px;
    height: 75px;
    border-radius: 10px 0 0 10px;
  }

  .formatos button .texto {
    align-items: flex-start;
    text-align: left;
    padding: 0 10px;
  }

  .formatos button .texto h3 {
    font-size: var(--f2);
  }

  .formatos button .texto p {
    font-size: var(--f0);
    line-height: 1.5;
    margin: 5px 0 0 0;
  }

  .formatos button .icone {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .formatos button.listas {
    margin: 10px 0;
  }

  .formatos button .icone svg {
    width: 8px;
    min-width: 8px;
  }

  .temas button {
    width: calc(50% - 5px);
    border-radius: 10px;
  }

  .temas button .capa {
    width: 100%;
    height: 85px;
  }

  .temas button .texto {
    padding: 15px 10px;
  }

  .temas button .texto p {
    font-size: var(--f0);
    max-width: 90px;
    margin: 5px 0 10px 0;
  }

  .temas button .icone {
    width: 20px;
    height: 20px;
  }

  .temas button .icone svg {
    width: 10px;
    min-width: 10px;
  }
}
</style>
