<template>
  <div id="aula">
    <Loading :class="{ mostrar: state.verLoading }" />
    <ModalEditarAula />
    <SectionModulo />
    <SectionAula />
    <ModalCriarAula/>
  </div>
</template>

<script setup>
import { reactive, watch, inject, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreCursos, useStoreAreas, useStoreModulos, useStoreAulas } from '@stores'

import Loading from '@components/global/loading/Loading.vue'
import ModalCriarAula from '@components/areaDeMembros/curso/modals/CriarAula.vue'
import ModalEditarAula from '@components/areaDeMembros/modulo/modals/EditarAula.vue'
import SectionModulo from '@components/areaDeMembros/aula/partials/SectionModulo.vue'
import SectionAula from '@components/areaDeMembros/aula/partials/SectionAula.vue'

const storeAulas = useStoreAulas()
const storeModulos = useStoreModulos()
const storeCursos = useStoreCursos()
const storeAreas = useStoreAreas()
const emitter = inject('emitter')
const router = useRouter()

const state = reactive({
  verLoading: true
})

async function carregarAula() {
  state.verLoading = true
  const idArea = storeAreas.area?._id
  let idCurso = storeCursos.curso?._id
  let idModulo = storeModulos.modulo?._id

  if (idArea && !idCurso) {
    const hotlinkCurso = router.currentRoute.value.params.hotlinkCurso
    await storeCursos.receberPorHotlink(idArea, hotlinkCurso)
    idCurso = storeCursos.curso?._id
  }

  if (idArea && idCurso && !idModulo) {
    const hotlinkModulo = router.currentRoute.value.params.hotlinkModulo
    await storeModulos.receberPorHotlink(idArea, idCurso, hotlinkModulo)
    idModulo = storeModulos.modulo?._id
  }

  if (idArea && idCurso && idModulo) {
    const hotlinkAula = router.currentRoute.value.params.hotlinkAula
    await storeAulas.receberPorHotlink(idArea, idCurso, idModulo, hotlinkAula)

    document.title = 'Aula ' + storeAulas.aula?.nome + ' | Level Member'

    emitter.emit('atualizarComentarios')

    setTimeout(() => {
      state.verLoading = false
    }, 500)
  }
}

watch(
  () => [
    storeAreas.area?._id,
    router.currentRoute.value.params.hotlinkCurso,
    router.currentRoute.value.params.hotlinkModulo
  ],
  async ([idArea, hotlinkCurso, hotlinkModulo]) => {
    if (idArea && hotlinkCurso && !storeCursos.curso?._id) {
      await storeCursos.receberPorHotlink(idArea, hotlinkCurso)
    }
    if (idArea && storeCursos.curso?._id && hotlinkModulo && !storeModulos.modulo?._id) {
      await storeModulos.receberPorHotlink(idArea, storeCursos.curso._id, hotlinkModulo)
    }
    carregarAula()
  },
  { immediate: true }
)

onMounted(() => {
  emitter.on('atualizarAula', carregarAula)
})
</script>

<style scoped>
#aula {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 250px;
}

@media screen and (max-width: 1000px) {
  #aula {
    padding: 60px 0 0 0;
  }
}
</style>
