<template>
  <section class="topo">
    <div class="titulo">
      <div class="icone">
        <Svgs nome="curso" />
      </div>
      <div class="nome">
        <h2>Cursos da área</h2>
        <p>{{ quantidadeCursos }}</p>
      </div>
    </div>
    <div class="opcoes">
      <button
        class="adicionar"
        @click="
          abrirModal(
            'criarCurso', // nome do modal
            'degrade-azul', // cor do icone
            'curso', // nome do icone
            'Criar novo curso', // titulo
            'Preencha os dados abaixo para criar' // subtitulo
          )
        "
      >
        <Svgs nome="mais" />
        <p>Criar novo curso</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreModal, useStoreCursos } from '@stores'
import Svgs from '@svgs'

const storeCursos = useStoreCursos()
const storeModal = useStoreModal()

function abrirModal(nome, cor, icone, titulo, subtitulo) {
  storeModal.abrirModal(nome, cor, icone, titulo, subtitulo)
}

const quantidadeCursos = computed(() => {
  const quantidade = storeCursos.cursos?.length || 0
  return `${quantidade} ${quantidade === 1 ? 'curso' : 'cursos'} ${
    quantidade === 1 ? 'criado' : 'criados'
  }`
})
</script>

<style scoped>
section.topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
}

.titulo {
  display: flex;
  align-items: center;
}

.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid var(--cor-cinza-3);
  background-color: var(--cor-branco);
  margin: 0 15px 0 0;
}

.titulo .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-preto);
}

.titulo .nome h2 {
  font-family: var(--estilo);
  font-size: var(--f3);
  color: var(--cor-preto);
  text-transform: uppercase;
}

.titulo .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}

.opcoes {
  display: flex;
}

.opcoes button.adicionar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  border-radius: 10px;
  background-color: var(--cor-azul-forte);
  margin: 0 0 0 20px;
  transition: all 0.3s;
}

.opcoes button.adicionar:hover {
  background-color: var(--cor-azul-forte-escuro);
}

.opcoes button.adicionar svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}

.opcoes button.adicionar p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

.opcoes button.tutorial {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: var(--cor-branco);
  margin: 0 0 0 15px;
  padding: 15px 20px;
  position: relative;
}

.opcoes button.tutorial p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-preto);
}

.opcoes button.tutorial svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza);
  transition: all 0.3s;
  margin: 0 10px 0 0;
}

.opcoes button.tutorial:hover svg {
  fill: var(--cor-roxo);
}

@media screen and (max-width: 1000px) {
  section.topo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
  }

  .titulo {
    display: flex;
    align-items: center;
  }

  .titulo .icone {
    width: 35px;
    height: 35px;
    margin: 0 10px 0 0;
  }

  .titulo .icone svg {
    width: 15px;
    min-width: 15px;
  }

  .titulo .nome h2 {
    font-size: var(--f2);
  }

  .titulo .nome p {
    font-size: var(--f1);
    margin: 5px 0 0 0;
  }

  .opcoes {
    padding: 20px 0 0 0;
  }

  .opcoes button.adicionar {
    padding: 13px 20px;
    margin: 0;
  }

  .opcoes button.adicionar p {
    font-size: var(--f1);
  }

  .opcoes button.adicionar svg {
    margin: 0 8px 0 0;
    width: 13px;
    min-width: 13px;
  }

  .opcoes button.tutorial {
    margin: 0 0 0 10px;
    padding: 13px 20px;
  }

  .opcoes button.tutorial svg {
    width: 13px;
    min-width: 13px;
    margin: 0 8px 0 0;
  }
}
</style>
