<template>
  <div class="checklist">
    <button v-for="(item, index) in itens" :key="index" :class="{ selecionado: item.selecionado }" @click="toggleSelecionado(index)">
      <span>
        <Svgs nome="check" />
      </span>
      <p>{{ item.nome }}</p>
    </button>
  </div>
</template>

<script setup>
import { toRefs } from 'vue'
import Svgs from '@svgs'

const props = defineProps({
  dados: {
    type: Array,
    required: true
  }
})

const { dados } = toRefs(props)
const itens = dados

function toggleSelecionado(index) {
  itens.value[index].selecionado = !itens.value[index].selecionado
}
</script>

<style scoped>
.checklist {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.checklist button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--cor-branco);
  margin: 0 0 10px 0;
  transition: all 0.3s;
}

.checklist button.selecionado span {
  background-color: var(--cor-verde);
  border: 2px solid var(--cor-verde);
  box-shadow: 3px 3px 6px 0 #26d65b4f;
}

.checklist button.selecionado span svg {
  fill: var(--cor-branco-fixo);
}

.checklist button.selecionado p {
  color: var(--cor-verde);
}

.checklist button span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  border: 2px solid var(--cor-cinza-2);
  background-color: var(--cor-branco);
  transition: all 0.3s;
}

.checklist button span svg {
  width: 15px;
  min-width: 15px;
  fill: transparent;
}

.checklist button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}
</style>
