<template>
  <section class="modulo">
    <div class="quadro">
      <div class="capa" @click="router.push(`/areas/${storeAreas.area?.hotlink}/${storeCursos.curso?.hotlink}`)">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${storeModulos.modulo?.imagemCapa}')`"></div>
        </div>
      </div>
      <div class="nome" @click="router.push(`/areas/${storeAreas.area?.hotlink}/${storeCursos.curso?.hotlink}/${storeModulos.modulo?.hotlink}`)">
        <h3>{{ storeModulos.modulo?.nome }}</h3>
        <p>{{ storeModulos.modulo?.descricao }}</p>
      </div>
      <div class="parte aulas">
        <Svgs class="icone" nome="play" />
        <h3>{{ storeModulos.modulo?.quantidadeAulas }} <span>{{ textoAulas(storeModulos.modulo?.quantidadeAulas) }}</span> nesse módulo</h3>
        <button @click="abrirModal(
          'criarAula', // nome do modal
          'degrade-azul', // cor do icone
          'play', // nome do icone
          'Criar Aula', // titulo
          'Crie uma nova aula abaixo' // subtitulo
        )">
          <Svgs nome="mais" />
          <p>Criar Aula</p>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStoreModal, useStoreAreas, useStoreCursos, useStoreModulos } from '@stores'
import Svgs from '@svgs'

const router = useRouter()

const storeModal = useStoreModal()
const storeCursos = useStoreCursos()
const storeAreas = useStoreAreas()
const storeModulos = useStoreModulos()

function abrirModal(nome, cor, icone, titulo, subtitulo) {
  storeModal.abrirModal(nome, cor, icone, titulo, subtitulo)
}

const textoAulas = (quantidade) => {
  return `${quantidade === 1 ? 'aula' : 'aulas'}`
}

</script>

<style scoped>
section.modulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 30px 20px 30px;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-branco);
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

.quadro .capa {
  width: 100%;
  max-width: 70px;
  cursor: pointer;
  transition: all 0.3s;
}

.quadro .capa:hover {
  opacity: 0.6;
}

.quadro .capa .tamanho {
  position: relative;
  padding-bottom: 56.52%;
  width: 100%;
}

.quadro .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-cinza-2);
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.quadro .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.quadro .nome:hover {
  opacity: 0.6;
}

.quadro .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
}

.quadro .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}

.quadro .parte {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quadro .parte h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  margin: 0 20px 0 0;
}

.quadro .parte svg.icone {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-preto);
  margin: 0 10px 0 0;
}

.quadro .parte button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-azul-forte);
  padding: 15px 25px;
  border-radius: 10px;
  transition: all 0.3s;
}

.quadro .parte button:hover {
  background-color: var(--cor-azul-forte-escuro);
}

.quadro .parte button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}

.quadro .parte button p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

@media screen and (max-width: 1000px) {
  section.modulo {
    flex-direction: column;
    padding: 20px 20px 20px 20px;
  }

  .quadro {
    flex-wrap: wrap;
    padding: 15px;
  }

  .quadro .capa {
    width: 100%;
    max-width: 60px;
  }

  .quadro .nome {
    width: calc(100% - 120px);
    padding: 0 0 0 15px;
  }

  .quadro .nome h3 {
    font-size: var(--f2);
  }

  .quadro .nome p {
    font-size: var(--f1);
  }

  .quadro .parte {
    flex-direction: row;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    padding: 20px 0 0 0;
  }

  .quadro .parte h3 {
    margin: 0;
    font-size: var(--f1);
  }

  .quadro .parte button {
    margin-left: auto;
  }

  .quadro .parte button svg {
    width: 13px;
    min-width: 13px;
    margin: 0 8px 0 0;
  }

}
</style>
