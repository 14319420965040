<template>
  <ColorPicker class="colorPicker" theme="dark" :color="internalColor" :alpha="true" :sucker-hide="false" :colors-default="null" @changeColor="onColorChange" />
</template>

<script setup>
import { ref, watch } from 'vue'
import { ColorPicker } from 'vue-color-kit'

const props = defineProps({
  color: { type: String, default: '' }
})

const emits = defineEmits(['update:color'])

const internalColor = ref(props.color)

watch(
  () => props.color,
  (newColor) => {
    internalColor.value = newColor
  },
  { immediate: true }
)

function onColorChange(color) {
  const hexColor = color.hex
  if (hexColor !== internalColor.value) {
    internalColor.value = hexColor
    emits('update:color', hexColor)
  }
}
</script>

<style>
.seletorCor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 230px;
  max-width: 230px;
  min-width: 230px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--cor-gelo);
  border: 1px solid var(--cor-cinza-2);
  padding: 0 15px 20px 15px;
  border-radius: 10px;
  animation: fadeIn 0.3s linear;
}

.seletorCor.fixo {
  position: fixed;
  top: 100px;
  left: 410px;
}

.seletorCor .nome {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
}

.seletorCor .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

.seletorCor .nome button {
  background-color: transparent;
}

.seletorCor .nome button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-preto);
  transition: all 0.3s;
}

.seletorCor .nome button:hover svg {
  fill: var(--cor-vermelho);
}
.colorPicker {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.colorPicker > div {
  width: 100%;
}
.color-set {
  display: flex;
  width: 100%;
  margin: 0 0 3px 0;
}
.color-set .saturation {
  width: 160px;
  position: relative;
}
.color-set .saturation .slide {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid var(--cor-branco-fixo);
  position: absolute;
  pointer-events: none;
}
.color-set .saturation canvas {
  border-radius: 5px;
  width: 100%;
  height: 160px;
}
.color-set .hue {
  width: 30px;
  margin: 0 0 0 10px;
  border-radius: 50px;
  position: relative;
}
.color-set .hue .slide {
  width: 30px;
  height: 16px;
  border-radius: 10px;
  border: 2px solid var(--cor-branco-fixo);
  position: absolute;
  pointer-events: none;
}
.color-set .hue canvas {
  border-radius: 5px;
  width: 100%;
  height: 160px;
}
.color-set .color-alpha {
  display: none;
  width: 20px;
  margin: 0 0 0 5px;
  position: relative;
  border-radius: 50px;
}
.color-set .color-alpha .slide {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--cor-branco-fixo);
  position: absolute;
  pointer-events: none;
}
.color-set .color-alpha canvas {
  border-radius: 5px;
  width: 100%;
  height: 165px;
}
.color-show {
  display: none;
}
.color-type {
  display: flex;
}
.color-type:nth-child(4) {
  display: none;
}
.color-type span.name {
  display: none;
}
ul.colors {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  padding: 0;
}
ul.colors li {
  list-style: none;
}
ul.colors .item {
  position: relative;
  width: calc(20% - 5px);
  margin: 8px 0 0 0;
  height: 10px;
}
ul.colors .item .color {
  position: absolute;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}
ul.colors .item:hover .color {
  transform: scale(0.9);
}
@media screen and (max-width: 1000px) {
  .seletor {
    top: 180px;
    left: 20px;
    padding: 0 15px 15px 15px;
  }
}
</style>
