<template>
  <section class="dados">

    <div class="quadro">
      <div class="icone diferente">
        <Svgs nome="camadas" />
      </div>
      <div class="nome">
        <h3>{{ quantidadeAreas }} de membros</h3>
        <p>Crie suas áreas na Level Member</p>
        <button @click="router.push('/areas')">
          Ver áreas
          <Svgs nome="setinha" />
        </button>
      </div>
    </div>

    <div class="quadro">
      <div class="icone">
        <Svgs nome="alunos" />
      </div>
      <div class="nome">
        <h3>{{ quantidadeAlunos }}</h3>
        <p>Total em todas as áreas de membros</p>
        <button @click="router.push('/areas')">
          Ver áreas
          <Svgs nome="setinha" />
        </button>
      </div>
    </div>

    <div class="quadro">
      <div class="icone">
        <Svgs nome="comunicado" />
      </div>
      <div class="nome">
        <h3>Comunicados</h3>
        <p>Comunicados nas áreas de membros</p>
        <button @click="router.push('/comunicados')">
          Em breve
          <Svgs nome="setinha" />
        </button>
      </div>
    </div>

    <div class="quadro notificacoes">
      <div class="icone" :class="{ativo: storePainel.estatisticas?.quantidadeNotificacoesNaoLidas > 0}">
        <Svgs nome="sino" />
      </div>
      <div class="nome">
        <h3>{{ quantidadeNotificacoes }}</h3>
        <p>Notificações no total</p>
        <button @click="router.push('/notificacoes')">
          Ver notificações
          <Svgs nome="setinha" />
        </button>
      </div>
    </div>

  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'
import { useStorePainel } from '@stores'

const router = useRouter()
const storePainel = useStorePainel()

const quantidadeNotificacoes = computed(() => {
  const quantidade = storePainel.estatisticas?.quantidadeNotificacoesNaoLidas || 0
  return `${quantidade} ${quantidade === 1 ? 'notificação' : 'notificações'} ${quantidade === 1 ? 'não lida' : 'não lidas'}`
})

const quantidadeAreas = computed(() => {
  const quantidade = storePainel.estatisticas?.quantidadeAreas || 0
  return `${quantidade} ${quantidade === 1 ? 'área' : 'áreas'}`
})

const quantidadeAlunos = computed(() => {
  const quantidade = storePainel.estatisticas?.quantidadeAlunos || 0
  return `${quantidade} ${quantidade === 1 ? 'aluno' : 'alunos'}`
})
</script>

<style scoped>
section.dados {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px 0 30px;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(50% - 10px);
  background-color: var(--cor-branco);
  border-radius: 10px;
  padding: 40px;
  margin: 0 0 20px 0;
  position: relative;
}
.quadro.notificacoes .icone {
  background-color: var(--cor-cinza-2);
  background: var(--cor-cinza-2);
}

.quadro.notificacoes .icone.ativo {
  background: linear-gradient(0deg, var(--degrade));
}

.quadro.notificacoes .icone.ativo svg {
  animation: pulsandoIcone 1s linear infinite;
}

@keyframes pulsandoIcone {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.quadro .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: linear-gradient(0deg, var(--degrade));
  border-radius: 20px;
}

.quadro .icone.diferente {
  background: linear-gradient(0deg, var(--degrade-azul));
}
.quadro .icone svg {
  width: 45px;
  min-width: 45px;
  fill: var(--cor-branco-fixo);
}

.quadro .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 30px;
}

.quadro .nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-preto);
  margin: 0 0 5px 0;
}

.quadro .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  width: 100%;
  max-width: 250px;
}

.quadro button {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-preto);
  padding: 15px 15px 15px 20px;
  margin: 10px 0 0 0;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-cinza-2);
  border-radius: 50px;
  transition: all 0.3s;
}

.quadro button:hover {
  border: 2px solid var(--cor-azul-forte);
}

.quadro button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-cinza);
  margin: 0 0 0 10px;
}

@media screen and (max-width: 1000px) {
  section.dados {
    flex-direction: column;
    padding: 0 20px 0 20px;
  }

  .quadro {
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    margin: 0 0 20px 0;
  }

  .quadro .icone {
    width: 60px;
    min-width: 60px;
    height: 85px;
  }

  .quadro .icone svg {
    width: 25px;
    min-width: 25px;
  }

  .quadro .nome {
    align-items: flex-start;
    text-align: left;
    padding: 0 0 0 15px;
  }

  .quadro .nome h3 {
    font-size: var(--f2);
    margin: 5px 0 5px 0;
  }

  .quadro .nome p {
    font-size: var(--f1);
    max-width: 300px;
  }

  .quadro button {
    padding: 10px 10px 10px 15px;
  }

  .quadro button svg {
    width: 15px;
    min-width: 15px;
    fill: var(--cor-cinza-2);
    transition: all 0.3s;
  }
}
</style>
