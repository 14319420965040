<template>
  <div id="areas">
    <Loading :class="{ mostrar: state.verLoading }" />
    <ModalCriarArea />
    <ModalEditarArea />
    <ModalDuplicarArea />

    <SectionTopo />
    <SectionLimites />
    <SectionAreas />
  </div>
</template>

<script setup>
import { onMounted, inject, reactive } from 'vue'
import { useStoreAreas } from '@stores'

import Loading from '@components/global/loading/Loading.vue'
import SectionTopo from '@components/app/areas/partials/SectionTopo.vue'
import SectionLimites from '@components/app/areas/partials/SectionLimites.vue'
import SectionAreas from '@components/app/areas/partials/SectionAreas.vue'
import ModalCriarArea from '@components/app/areas/modals/CriarArea.vue'
import ModalEditarArea from '@components/app/areas/modals/EditarArea.vue'
import ModalDuplicarArea from '@components/app/areas/modals/DuplicarArea.vue'

const state = reactive({
  verLoading: true
})

const storeAreas = useStoreAreas()
const emitter = inject('emitter')

async function carregarAreas() {
  await storeAreas.receberTodos()
  setTimeout(() => {
    state.verLoading = false
  }, 300)
}

onMounted(() => {
  carregarAreas()
  emitter.on('atualizarAreas', carregarAreas)
})
</script>

<style scoped>
#areas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 250px;
}

@media screen and (max-width: 1000px) {
  #areas {
    padding: 60px 0 0 0;
  }
}
</style>
