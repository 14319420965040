<template>
  <section class="aula">
    <div class="conteudo">
      <!-- Vídeo -->
      <div class="video">
        <div class="tamanho">
          <iframe :src="`${storeAulas.aula?.urlVideo || ''}`" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowfullscreen></iframe>
        </div>
      </div>
      <!-- Opções -->
      <div class="opcoes">
        <div class="posicao">
          <p>{{ storeAulas.aula?.posicao }}º</p>
        </div>
        <div class="duracao" v-if="storeAulas.aula?.duracao">
          <Svgs nome="relogio" />
          <p>{{ storeAulas.aula?.duracao }} minutos</p>
        </div>
        <button class="editar" @click="abrirModalEdicao(
          'editarAula', // nome do modal
          'degrade-azul', // cor do icone
          'editar', // nome do icone
          'Editar aula', // titulo
          storeAulas.aula?.nome, // subtitulo
          storeAulas.aula?._id // ID
        )">
          <Svgs nome="editar" />
          <p>Editar Aula</p>
        </button>
      </div>
      <!-- Nome -->
      <div class="nome">
        <h3>{{ storeAulas.aula?.nome }}</h3>
        <p>{{ storeAulas.aula?.descricao }}</p>
      </div>
      <!-- Links da Aula -->
      <div class="links" v-if="storeAulas.aula?.links?.length > 0">
        <h3>{{ linksLabel }}</h3>
        <a v-for="link in storeAulas.aula?.links" :href="link.url" target="_blank">
          <Svgs nome="link" />
          <p>{{ link.nome }}</p>
        </a>
      </div>
      <!-- Arqivos da Aula -->
      <div class="arquivos" v-if="storeAulas.aula?.arquivos?.length > 0">
        <h3>{{ arquivosLabel }} para download</h3>
        <a v-for="arquivo in storeAulas.aula?.arquivos" :href="arquivo.url" target="_blank">
          <Svgs nome="link" />
          <p>{{ arquivo.nome }}</p>
        </a>
      </div>
      <SectionComentarios/>
    </div>
    <SectionAulas/>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreModal, useStoreAulas } from '@stores'
import Svgs from '@svgs'
import SectionAulas from '@components/areaDeMembros/aula/partials/SectionAulas.vue'
import SectionComentarios from '@components/areaDeMembros/aula/partials/SectionComentarios.vue'

const storeAulas = useStoreAulas()
const storeModal = useStoreModal()

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}

const arquivosLabel = computed(() => {
  const numeroArquivos = storeAulas.aula?.arquivos?.length || 0
  return `${numeroArquivos} ${numeroArquivos === 1 ? 'arquivo' : 'arquivos'}`
})

const linksLabel = computed(() => {
  const numeroLinks = storeAulas.aula?.links?.length || 0;
  return `${numeroLinks} ${numeroLinks === 1 ? 'link externo' : 'links externos'}`
})

document.addEventListener('keydown', () => {
  if (event.key === 'Escape' && state.mostrarModal) {
    fecharModal()
  }
})
</script>

<style scoped>
section.aula {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 0 30px 80px 30px;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  width: 60%;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100dvh;
  z-index: 12;
}

.loading span {
  width: 100px;
  height: 100px;
  border: 3px solid var(--cor-preto);
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: girando 1s linear infinite;
}

.curso {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 20px 0;
}

.curso h3:hover {
  opacity: 0.6;
}

.curso h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-roxo);
  cursor: pointer;
  transition: all 0.3s;
}

.curso svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-preto);
  margin: 0 10px;
}

.curso p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

.video {
  width: 100%;
}

.video .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.video .tamanho iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  border-radius: 10px;
}

.opcoes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0 0 0;
}

.opcoes .posicao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cor-branco);
  border-bottom: 2px solid var(--cor-cinza-2);
}

.opcoes .posicao p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-cinza);
}

.opcoes .duracao {
  display: flex;
  align-items: center;
  margin: 0 0 0 20px;
}

.opcoes .duracao svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza);
  margin: 0 5px 0 0;
}

.opcoes .duracao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

.opcoes button.editar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid var(--cor-cinza-2);
  margin-left: auto;
  transition: all 0.3s;
}

.opcoes button.editar:hover {
  border: 2px solid var(--cor-azul-forte)
}

.opcoes button.editar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-preto);
  margin: 0 10px 0 0;
}

.opcoes button.editar p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-preto);
  transition: all 0.3s;
}

.nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0 10px 0;
}

.nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
  line-height: 1.5;
}

.nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.links {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-top: 2px solid var(--cor-cinza-2);
  padding: 20px 0 0 0;
  margin: 10px 0 0 0;
}

.links h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-preto);
  width: 100%;
  text-align: left;
  margin: 0 0 10px 0;
}

.links a {
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 0 10px 10px 0;
}

.links a svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-verde-escuro);
  margin: 0 10px 0 0;
}

.links a p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  transition: all 0.3s;
}

.links a:hover p {
  color: var(--cor-verde-escuro);
}

.arquivos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-top: 2px solid var(--cor-cinza-2);
  padding: 20px 0 0 0;
  margin: 10px 0 0 0;
}

.arquivos h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-preto);
  width: 100%;
  text-align: left;
  margin: 0 0 10px 0;
}

.arquivos a {
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 0 10px 10px 0;
}

.arquivos a svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-roxo);
  margin: 0 10px 0 0;
}

.arquivos a p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  transition: all 0.3s;
}

.arquivos a:hover p {
  color: var(--cor-roxo);
}

@media screen and (max-width: 1000px) {
  section.aula {
    flex-direction: column;
    padding: 0 20px 80px 20px;
  }

  .conteudo {
    width: 100%;
  }

  .opcoes {
    margin: 20px 0 0 0;
  }

  .opcoes .posicao {
    width: 35px;
    height: 35px;
  }

  .opcoes .posicao p {
    font-size: var(--f1);
  }

  .opcoes .duracao {
    margin: 0 0 0 15px;
  }

  .opcoes .duracao svg {
    width: 12px;
    min-width: 12px;
  }

  .opcoes .duracao p {
    font-size: var(--f1);
  }

  .opcoes button.editar {
    padding: 13px 20px;
  }

  .opcoes button.editar svg {
    width: 12px;
    min-width: 12px;
    margin: 0 8px 0 0;
  }

  .opcoes button.editar p {
    font-size: var(--f1);
  }

  .nome {
    margin: 15px 0 10px 0;
  }

  .nome h3 {
    font-size: var(--f2);
  }

  .nome p {
    font-size: var(--f1);
  }
  .links h3 {
    font-size: var(--f1);
  }

  .links a svg {
    width: 12px;
    min-width: 12px;
    margin: 0 8px 0 0;
  }

  .links a p {
    font-size: var(--f1);
  }

  .arquivos h3 {
    font-size: var(--f1);
  }

  .arquivos a svg {
    width: 12px;
    min-width: 12px;
    margin: 0 8px 0 0;
  }

  .arquivos a p {
    font-size: var(--f1);
  }

}
</style>
