<template>
  <div id="area">
    <Loading :class="{ mostrar: state.verLoading }" />
    <SectionApresentacao/>
    <SectionDados />
    <SectionOpcoes />
    <SectionSuporte />
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'

import Loading from '@components/global/loading/Loading.vue'
import SectionApresentacao from '@components/areaDeMembros/area/partials/SectionApresentacao.vue'
import SectionDados from '@components/areaDeMembros/area/partials/SectionDados.vue'
import SectionSuporte from '@components/areaDeMembros/area/partials/SectionSuporte.vue'
import SectionOpcoes from '@components/areaDeMembros/area/partials/SectionOpcoes.vue'

const state = reactive({
  verLoading: true
})

onMounted(() => {
  setTimeout(() => {
    state.verLoading = false
  }, 1000);
})

</script>

<style scoped>
#area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 250px;
}

@media screen and (max-width: 1000px) {
  #area {
    padding: 60px 0 0 0;
  }
}
</style>
