<template>
  <section class="areas">
    <div v-for="area in storeAreas.areas" :key="area._id" class="area">
      <div class="capa">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${area.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`"></div>
        </div>
      </div>
      <div class="info">
        <div class="nome">
          <h3>{{ area.nome }}</h3>
          <p>{{ area.descricao }}</p>
          <a :href="`https://mber.live/${area.hotlink}`" target="_blank">
            <Svgs nome="link" />
            <h4>mber.live/{{ area.hotlink }}</h4>
          </a>
        </div>
        <div class="opcoes">
          <button
            class="editar"
            @click="
              abrirModalEdicao(
                'editarArea', // nome do modal
                'degrade', // cor do icone
                'editar', // nome do icone
                'Editar área', // titulo
                'Altere os dados da área', // subtitulo
                area._id // dado
              )
            ">
            <p>Editar</p>
            <Svgs nome="editar" />
          </button>
          <button class="entrar" @click="acessarArea(area.hotlink)">
            <p>Conteúdo</p>
            <Svgs nome="seta" />
          </button>
          <button
            class="duplicar"
            @click="
              abrirModalEdicao(
                'duplicarArea', // nome do modal
                'degrade', // cor do icone
                'duplicar', // nome do icone
                'Duplicar área', // titulo
                'Crie uma cópia dessa área', // subtitulo
                area._id // dado
              )
            ">
            <p>Duplicar</p>
            <Svgs nome="duplicar" />
          </button>
        </div>
      </div>
      <div class="dados">
        <div class="dado">
          <Svgs nome="alunos" />
          <p>
            <span>{{ area.quantidadeAlunos }}</span>
          </p>
          <p>{{ textoAlunos(area.quantidadeAlunos) }}</p>
        </div>
        <div class="dado">
          <Svgs nome="curso" />
          <p>
            <span>{{ area.quantidadeCursos }}</span>
          </p>
          <p>{{ textoCursos(area.quantidadeCursos) }}</p>
        </div>
        <div class="dado">
          <Svgs nome="modulo" />
          <p>
            <span>{{ area.quantidadeModulos }}</span>
          </p>
          <p>{{ textoModulos(area.quantidadeModulos) }}</p>
        </div>
        <div class="dado">
          <Svgs nome="play" />
          <p>
            <span>{{ area.quantidadeAulas }}</span>
          </p>
          <p>{{ textoAulas(area.quantidadeAulas) }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal, useStoreAreas } from '@stores'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeModal = useStoreModal()

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}

function acessarArea(hotlink) {
  window.location.href = `/areas/${hotlink}/cursos`
}

const textoAlunos = (quantidade) => {
  return `${quantidade === 1 ? 'aluno' : 'alunos'}`
}

const textoCursos = (quantidade) => {
  return `${quantidade === 1 ? 'curso' : 'cursos'}`
}

const textoModulos = (quantidade) => {
  return `${quantidade === 1 ? 'módulo' : 'módulos'}`
}

const textoAulas = (quantidade) => {
  return `${quantidade === 1 ? 'aula' : 'aulas'}`
}
</script>

<style scoped>
section.areas {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 0 10px 30px 30px;
}

.area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-branco);
  border-radius: 10px;
  width: calc(50% - 20px);
  margin: 0 20px 20px 0;
}

.area .capa {
  width: 100%;
}

.area .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 35%;
}

.area .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-cinza-2);
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
}

.area .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
}

.area .info .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.area .info .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
  line-height: 1.5;
}

.area .info .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.area .info .nome a {
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 10px 0 0 0;
  transition: all 0.3s;
}

.area .info .nome a:hover {
  opacity: 0.6;
}

.area .info .nome a h4 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-azul-forte);
}

.area .info .nome a svg {
  width: 10px;
  min-width: 10px;
  margin: 0 5px 0 0;
  fill: var(--cor-azul-forte);
}

.area .info .opcoes {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 0 0 10px;
}

.area .info .opcoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-cinza-2);
  margin: 0 0 0 10px;
  padding: 8px 10px;
  transition: all 0.3s;
}

.area .info .opcoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-preto);
  transition: all 0.3s;
}

.area .info .opcoes button.editar {
  margin: 0 0 10px 0;
}

.area .info .opcoes button.duplicar {
  margin: 10px 0 0 0;
}

.area .info .opcoes button:hover {
  border: 2px solid var(--cor-azul-forte);
}

.area .info .opcoes button svg {
  width: 13px;
  min-width: 13px;
  fill: var(--cor-preto);
  margin: 0 0 0 5px;
  transition: all 0.3s;
}

.area .dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 30px 0;
}

.area .dados .dado {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  border-left: 1px solid var(--cor-cinza-2);
}

.area .dados .dado:nth-child(1) {
  border-left: none;
}

.area .dados .dado svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-cinza);
  margin: 0 0 10px 0;
}

.area .dados .dado p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  line-height: 1.3;
  text-align: center;
}

.area .dados .dado p span {
  font-size: var(--f3);
  color: var(--cor-preto);
}

@media screen and (max-width: 1000px) {
  section.areas {
    flex-direction: column;
    width: 100%;
    padding: 0 20px 30px 20px;
  }

  .area {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .area .info {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .area .info .nome {
    margin: 0 0 20px 0;
  }

  .area .info .nome a h4 {
    font-size: var(--f1);
  }

  .area .info .opcoes {
    padding: 0;
    flex-direction: row;
    align-items: center;
  }

  .area .info .opcoes button.editar {
    margin: 0;
  }

  .area .info .opcoes button.duplicar {
    margin: 0 0 0 10px;
  }

  .area .dados {
    padding: 0 0 20px 0;
  }

  .area .dados .dado svg {
    width: 20px;
    min-width: 20px;
    max-height: 15px;
    margin: 0 0 6px 0;
  }

  .area .dados .dado p {
    font-size: var(--f0);
    line-height: 1.3;
  }

  .area .dados .dado p span {
    font-size: var(--f1);
  }
}
</style>
