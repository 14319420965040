<template>
  <div id="painel">
    <Loading :class="{ mostrar: state.verLoading }" />
    <SectionTopo />
    <SectionLimites />
    <SectionDados />
    <SectionSuporte />
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import Loading from '@components/global/loading/Loading.vue'
import SectionDados from '@components/app/painel/partials/SectionDados.vue'
import SectionLimites from '@components/app/painel/partials/SectionLimites.vue'
import SectionSuporte from '@components/app/painel/partials/SectionSuporte.vue'
import SectionTopo from '@components/app/painel/partials/SectionTopo.vue'

const state = reactive({
  verLoading: true
})

onMounted(() => {
  setTimeout(() => {
    state.verLoading = false
  }, 300);
})
</script>

<style scoped>
#painel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 250px;
}

@media screen and (max-width: 1000px) {
  #painel {
    padding: 60px 0 0 0;
  }
}
</style>
