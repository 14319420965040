<template>
  <div class="imagem">
    <div class="conteudo">
      <h2>{{ textoDefinido }}</h2>
    </div>
    <Particles />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Particles from '@components/global/particles/Particles.vue'

const textos = [
  'Crie uma área de membros inesquecível',
  'Inove com nossa plataforma de membros',
  'Ofereça uma experiência única aos seus alunos',
  'Diferencie seu produto com uma área de membros inovadora',
  'Transforme a educação com áreas de membros gasificadas',
  'Crie uma comunidade vibrante para seus alunos',
  'Inovação e interação na sua área de membros',
  'Experiência de aprendizado elevada com nossa plataforma',
  'Destaque-se com uma área de membros diferenciada',
  'Engaje seus alunos como nunca antes',
  'Plataforma de membros que encanta e retém alunos',
  'Áreas de membros inovadoras para um aprendizado superior',
  'Leve seu ensino ao próximo nível',
  'Uma área de membros que seus alunos vão amar',
  'Inovação e diferenciação para seu curso online',
  'Ofereça mais do que conteúdo, ofereça uma experiência',
  'Área de membros que transforma o aprendizado',
  'Engajamento e inovação em cada aula',
  'Crie uma área de membros que inspire',
  'Transforme seu conteúdo em uma experiência memorável'
];

const textoDefinido = ref('')

const renderizarTexto = () => {
  const randomIndex = Math.floor(Math.random() * textos.length)
  textoDefinido.value = textos[randomIndex]
}

onMounted(() => {
  renderizarTexto()
});
</script>

<style scoped>
.imagem {
  width: 50%;
  min-height: 100dvh;
  max-height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: var(--cor-branco);
}

.conteudo {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  background: linear-gradient(90deg, var(--cor-branco-transparente), var(--cor-branco), var(--cor-branco-transparente));
  background: -webkit-linear-gradient(90deg, var(--cor-branco-transparente), var(--cor-branco), var(--cor-branco-transparente));
}

h2 {
  font-family: var(--estilo);
  font-size: var(--f5);
  color: var(--cor-preto);
  text-align: center;
  text-transform: uppercase;
  line-height: 1.1;
  z-index: 4;
  width: 100%;
  max-width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(90deg, var(--degrade), var(--degrade));
  background-size: 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-animation 5s ease-in-out infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.balao {
  position: absolute;
  width: 100%;
  max-width: 200px;
  z-index: 3;
}

.balao.um {
  top: 10%;
  left: 5%;
}

.balao.dois {
  top: 5%;
  right: 5%;
}

.balao.tres {
  bottom: 5%;
  left: 5%;
}

.balao.quatro {
  bottom: 10%;
  right: 5%;
}

@media screen and (max-width: 1000px) {

  .imagem {
    width: 100%;
    min-height: 50dvh;
    max-height: 50dvh;
    position: relative;
    top: auto;
    left: auto;
  }

  .balao {
    max-width: 150px;
  }

}
</style>
