<template>
  <section class="filtros">
    <div class="quadro">
      <div class="input busca">
        <Svgs class="icone" nome="lupa" />
        <input type="text" placeholder="Buscar por aluno" spellcheck="false" @input="buscar" />
      </div>
      <div class="select visualizacao">
        <Svgs class="icone" nome="setinha" />
        <select v-model="storeAlunos.filtroOrdem">
          <option value="todos" selected>Ordem padrão</option>
          <option value="nomeAsc">Alfabética (A-Z)</option>
          <option value="nomeDesc">Alfabética (Z-A)</option>
          <option value="cadastroMaisRecente">Cadastro (mais recentes)</option>
          <option value="cadastroMaisAntigo">Cadastro (mais antigos)</option>
        </select>
      </div>
      <div class="select cursos">
        <Svgs class="icone" nome="setinha" />
        <select v-model="storeAlunos.filtroCurso">
          <option value="todos">Todos os cursos</option>
          <option v-for="curso in storeCursos.cursos" :value="curso._id">{{ curso.nome }}</option>
        </select>
      </div>
    </div>
  </section>
</template>

<script setup>
import { watch, onMounted } from 'vue'
import { useStoreAlunos, useStoreAreas, useStoreCursos } from '@stores'
import Svgs from '@svgs'

const storeAlunos = useStoreAlunos()
const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()

const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}

const buscar = debounce((event) => {
  storeAlunos.filtroBusca = event.target.value
}, 300)

async function carregarCursos() {
  const idArea = storeAreas.area?._id
  if (idArea) {
    await storeCursos.receberTodos(idArea)
  }
}

onMounted(() => {
  if (!storeAlunos.filtroOrdem) storeAlunos.filtroOrdem = 'todos'
  if (!storeAlunos.filtroCurso) storeAlunos.filtroCurso = 'todos'
})

watch(
  () => storeAreas.area?._id,
  (idArea) => {
    if (idArea) {
      carregarCursos()
    }
  },
  { immediate: true }
)
</script>

<style scoped>
section.filtros {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 30px 0 30px;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 10px 0;
  border-radius: 10px;
}

.input.busca {
  max-width: calc(50% - 5px);
}

.select.visualizacao {
  max-width: calc(25% - 5px);
}

.select.cursos {
  max-width: calc(25% - 5px);
}

@media screen and (max-width: 1000px) {
  section.filtros {
    flex-direction: column;
    padding: 0 20px 10px 20px;
  }

  .quadro {
    flex-wrap: wrap;
    margin: 0;
  }

  .input.busca {
    max-width: 100%;
    margin: 0 0 10px 0;
  }

  .select.visualizacao {
    max-width: calc(50% - 5px);
  }

  .select.cursos {
    max-width: calc(50% - 5px);
  }

  .select.visualizacao svg.icone {
    width: 12px;
    min-width: 12px;
    right: 8px;
  }

  .select.visualizacao select {
    padding: 10px;
    font-size: var(--f1);
  }

  .select.cursos svg.icone {
    width: 12px;
    min-width: 12px;
    right: 8px;
  }

  .select.cursos select {
    padding: 10px;
    font-size: var(--f1);
  }
}
</style>
