<template>
  <section class="registrar">
    <DivImagem />
    <div class="conteudo">
      <div class="titulo">
        <Svgs nome="logo-v2" />
        <p>
          Crie sua conta e ganhe
          <span>7 dias grátis para usar a Level Member</span>
          e criar áreas de membros incríveis
        </p>
      </div>
      <label>
        Seu nome
        <span>*</span>
      </label>
      <div class="input">
        <Svgs class="icone" nome="usuario" />
        <input type="text" spellCheck="false" placeholder="João da Silva" autoComplete="name" v-model="state.nome" @keydown.enter="registrar" />
      </div>
      <label>
        Whatsapp
        <span>*</span>
      </label>
      <div class="input">
        <Svgs class="icone" nome="whatsapp" />
        <input type="tel" spellCheck="false" placeholder="(00) 00000-0000" v-mask="mascaraAtual" v-model="numeroTelefone" autoComplete="phone" @keydown.enter="registrar" />
      </div>
      <label>
        E-mail da conta
        <span>*</span>
      </label>
      <div class="input">
        <Svgs class="icone" nome="envelope" />
        <input type="email" spellCheck="false" placeholder="email@email.com" autoComplete="email" v-model="state.email" @keydown.enter="registrar" />
      </div>
      <label>
        Senha de acesso
        <span>*</span>
      </label>
      <div class="input">
        <Svgs class="icone" nome="cadeado" />
        <input :type="state.mostrarSenhaUm ? 'text' : 'password'" spellCheck="false" placeholder="senha#123" autoComplete="current-password" v-model="state.senha" @keydown.enter="registrar" />
        <button class="ocultar" @click="toggleMostrarSenhaUm">
          <Svgs :nome="state.mostrarSenhaUm ? 'oculto' : 'visivel'" />
        </button>
      </div>
      <label>
        Repita a senha de acesso
        <span>*</span>
      </label>
      <div class="input">
        <Svgs class="icone" nome="cadeado" />
        <input :type="state.mostrarSenhaDois ? 'text' : 'password'" spellCheck="false" placeholder="senha#123" autoComplete="current-password" v-model="state.confirmarSenha" @keydown.enter="registrar" />
        <button class="ocultar" @click="toggleMostrarSenhaDois">
          <Svgs :nome="state.mostrarSenhaDois ? 'oculto' : 'visivel'" />
        </button>
      </div>
      <button class="entrar" @click="registrar()" :disabled="state.carregando">
        Liberar 7 dias grátis
        <span></span>
      </button>
      <div class="opcoes">
        <a href="https://wa.me/551831993895">Precisa de ajuda?</a>
        <span></span>
        <router-link to="/">Tem conta? Entre aqui</router-link>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, ref, watch, inject } from 'vue'
import { helperValidar } from '@helpers'
import apiAutenticacao from '@/api/produtor/api-autenticacao'
import Svgs from '@svgs'
import DivImagem from '@components/autenticacao/entrar/partials/DivImagem.vue'

const emitter = inject('emitter')

const state = reactive({
  nome: '',
  email: '',
  senha: '',
  confirmarSenha: '',
  mostrarSenhaUm: false,
  mostrarSenhaDois: false,
  carregando: false
})

const toggleMostrarSenhaUm = () => {
  state.mostrarSenhaUm = !state.mostrarSenhaUm
}

const toggleMostrarSenhaDois = () => {
  state.mostrarSenhaDois = !state.mostrarSenhaDois
}

const numeroTelefone = ref('')
const mascaraAtual = ref('(##) #####-####')

watch(numeroTelefone, (novoValor) => {
  const digitos = novoValor.replace(/\D/g, '')
  if (digitos.length <= 10) {
    mascaraAtual.value = '(##) ####-####'
  } else {
    mascaraAtual.value = '(##) #####-####'
  }
})

function validarDados() {
  let mensagem

  if (state.nome === '' || state.email === '' || numeroTelefone.value === '' || state.senha === '' || state.confirmarSenha === '') mensagem = 'Preencha todos os campos'
  else if (state.nome.length < 3) mensagem = 'Nome precisa de no mínimo 3 caracteres'
  else if (!helperValidar.email(state.email)) mensagem = 'Formato de email inválido'
  else if (numeroTelefone.value.length < 14 && numeroTelefone.value.length !== 0) mensagem = 'Número de telefone inválido'
  else if (state.senha !== state.confirmarSenha) mensagem = 'Senhas não conferem'
  else if (state.senha.length < 6 || state.confirmarSenha.length < 6) mensagem = 'Senha precisa ter 6 caracteres'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', titulo: 'Erro ao criar conta', mensagem: mensagem })
  return false
}

async function registrar() {
  if (!validarDados()) return

  const payload = {
    nome: state.nome,
    email: state.email,
    senha: state.senha,
    contato: {
      whatsapp: numeroTelefone.value
    }
  }

  state.carregando = true

  try {
    await apiAutenticacao.registrar(payload)
    setTimeout(() => entrar(), 1000)
  } catch (error) {
    state.carregando = false
    emitter.emit('mostrarAlerta', { tipo: 'negativo', titulo: 'Erro ao criar conta', mensagem: error })
    return
  }
}

async function entrar() {
  if (!validarDados()) return

  const payload = {
    email: state.email,
    senha: state.senha
  }

  const resp = await apiAutenticacao.entrar(payload)
  localStorage.setItem('level-member-produtor-token', resp.token)

  setTimeout(() => window.location.assign('/painel'), 500)
}
</script>

<style scoped>
section.registrar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: var(--cor-gelo);
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50%;
  padding: 60px 0;
  min-height: 100dvh;
  background-color: var(--cor-gelo);
}

.conteudo .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conteudo .titulo svg {
  width: 150px;
  min-width: 150px;
  margin: 0 0 20px 0;
}

.conteudo .titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 350px;
  margin: 0 0 20px 0;
}

.conteudo .titulo p span {
  font-family: var(--bold);
  color: var(--cor-vermelho);
}

.conteudo .input {
  max-width: 400px;
  margin: 0 0 20px 0;
}

.conteudo label {
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin: 0 0 10px 0;
}

.conteudo button.entrar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--estilo);
  font-size: var(--f3);
  color: var(--cor-branco-fixo);
  background: linear-gradient(180deg, var(--degrade));
  background-size: 100% 100%;
  width: 100%;
  max-width: 400px;
  padding: 20px 20px;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}

.conteudo button.entrar:hover {
  background-size: 200% 200%;
}

.conteudo button.entrar:disabled {
  color: transparent;
}

.conteudo button.entrar:disabled span {
  opacity: 1;
  visibility: visible;
}

.conteudo button.entrar span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--cor-branco-fixo);
  border-top: 2px solid transparent;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: girando 0.5s linear infinite;
  pointer-events: none;
  transition: all 0.3s;
}

.conteudo .opcoes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 30px 0 0 0;
}

.conteudo .opcoes span {
  width: 2px;
  height: 25px;
  background-color: var(--cor-cinza-2);
  margin: 0 15px;
}

.conteudo .opcoes a {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  background-color: transparent;
  line-height: 1.5;
  text-align: center;
  transition: all 0.3s;
}

.conteudo .opcoes a:hover {
  color: var(--cor-azul);
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.registrar {
    flex-direction: column-reverse;
  }

  .conteudo {
    width: 100%;
    padding: 40px 30px;
    min-height: auto;
  }

  .conteudo .titulo svg {
    min-width: 120px;
    width: 120px;
  }

  .conteudo .titulo p {
    max-width: 330px;
  }

  .conteudo button.entrar {
    padding: 20px 20px;
  }
}
</style>
