<template>
  <section class="cursos">
    <div v-for="curso in storeCursos.cursos" :key="curso._id" class="curso">
      <div class="capa">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${curso.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`"></div>
        </div>
      </div>
      <div class="info">
        <div class="nome">
          <h3>{{ curso.nome }}</h3>
          <p>{{ curso.descricao }}</p>
        </div>
        <div class="opcoes">
          <button
            class="editar"
            @click="
              abrirModalEdicao(
                'editarCurso', // nome do modal
                'degrade-azul', // cor do icone
                'editar', // nome do icone
                'Editar curso', // titulo
                curso.nome, // subtitulo
                curso._id // id
              )
            ">
            <p>Editar</p>
            <Svgs nome="editar" />
          </button>
          <button class="entrar" @click="router.push(`/areas/${storeAreas.area?.hotlink}/${curso.hotlink}`)">
            <p>Ver módulos</p>
            <Svgs nome="seta" />
          </button>
          <button
            class="duplicar"
            @click="
              abrirModalEdicao(
                'duplicarCurso', // nome do modal
                'degrade-azul', // cor do icone
                'duplicar', // nome do icone
                'Duplicar curso', // titulo
                'Crie uma cópia desse curso', // subtitulo
                curso._id // id
              )
            ">
            <p>Duplicar</p>
            <Svgs nome="duplicar" />
          </button>
        </div>
      </div>
      <div class="dados">
        <div class="dado">
          <Svgs nome="alunos" />
          <p>
            <span>{{ curso.quantidadeAlunos }}</span>
          </p>
          <p>{{ textoAlunos(curso.quantidadeAlunos) }}</p>
        </div>
        <div class="dado">
          <Svgs nome="modulo" />
          <p>
            <span>{{ curso.quantidadeModulos }}</span>
          </p>
          <p>{{ textoModulos(curso.quantidadeModulos) }}</p>
        </div>
        <div class="dado">
          <Svgs nome="play" />
          <p>
            <span>{{ curso.quantidadeAulas }}</span>
          </p>
          <p>{{ textoAulas(curso.quantidadeAulas) }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal, useStoreAreas, useStoreCursos } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModal = useStoreModal()
const router = useRouter()

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}

const textoAlunos = (quantidade) => {
  return `${quantidade === 1 ? 'aluno' : 'alunos'}`
}

const textoModulos = (quantidade) => {
  return `${quantidade === 1 ? 'módulo' : 'módulos'}`
}

const textoAulas = (quantidade) => {
  return `${quantidade === 1 ? 'aula' : 'aulas'}`
}
</script>

<style scoped>
section.cursos {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 0 10px 30px 30px;
}

.curso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-branco);
  border-radius: 10px;
  width: calc(50% - 20px);
  margin: 0 20px 20px 0;
}

.curso .capa {
  width: 100%;
}

.curso .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 35%;
}

.curso .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
}

.curso .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
}

.curso .info .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.curso .info .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
  line-height: 1.5;
}

.curso .info .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
  line-height: 1.5;
}

.curso .info .opcoes {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 0 0 10px;
}

.curso .info .opcoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-cinza-2);
  margin: 0 0 0 10px;
  padding: 8px 10px;
  transition: all 0.3s;
}

.curso .info .opcoes button.duplicar {
  margin: 10px 0 0 0;
}

.curso .info .opcoes button.editar {
  margin: 0 0 10px 0;
}

.curso .info .opcoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-preto);
  transition: all 0.3s;
}

.curso .info .opcoes button:hover {
  border: 2px solid var(--cor-azul-forte);
}

.curso .info .opcoes button svg {
  width: 13px;
  min-width: 13px;
  fill: var(--cor-preto);
  margin: 0 0 0 5px;
  transition: all 0.3s;
}

.curso .dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 30px 0;
}

.curso .dados .dado {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  border-left: 1px solid var(--cor-cinza-2);
}

.curso .dados .dado:nth-child(1) {
  border-left: none;
}

.curso .dados .dado svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-2);
  margin: 0 10px 0 0;
}

.curso .dados .dado p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  line-height: 1.3;
  text-align: center;
}

.curso .dados .dado p span {
  font-size: var(--f3);
  color: var(--cor-preto);
  margin: 0 5px 0 0;
}

@media screen and (max-width: 1000px) {
  section.cursos {
    flex-direction: column;
    width: 100%;
    padding: 0 20px 30px 20px;
  }

  .curso {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .curso .info {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .curso .info .nome {
    margin: 0 0 20px 0;
  }

  .curso .info .opcoes {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  .curso .info .opcoes button {
    margin: 0 10px 0 0;
  }

  .curso .info .opcoes button.duplicar {
    margin: 0;
  }

  .curso .info .opcoes button.editar {
    margin: 0 10px 0 0;
  }

  .curso .dados {
    padding: 0 0 20px 0;
  }

  .curso .dados .dado svg {
    width: 15px;
    min-width: 15px;
    max-height: 20px;
    margin: 0 5px 0 0;
  }

  .curso .dados .dado p {
    font-size: var(--f0);
    line-height: 1.3;
  }

  .curso .dados .dado p span {
    font-size: var(--f1);
    margin: 0 3px 0 0;
  }
}
</style>
