<template>
  <section class="plataformas">
    <div class="titulo">
      <h3>Plataformas para Integrar</h3>
      <p>Crie integrações com as plataformas abaixo</p>
    </div>
    <div class="itens">
      <div class="item" v-for="opcao in state.opcoes" :key="opcao">
        <div class="capa" :style="`background-color: ${opcao.cor}`">
          <Svgs :nome="`logo-${opcao.nome}`" />
        </div>
        <div class="opcoes">
          <button
            class="integrar"
            :class="{ breve: !opcao.ativo }"
            @click="
              abrirModalEdicao(
                'criarIntegracao', // nome do modal
                'degrade-azul', // cor do icone
                opcao.nome, // nome do icone
                'Integrar com ' + opcao.nome, // titulo
                'Preencha os dados abaixo para integrar', // subtitulo
                opcao.nome // nome do gateway
              )
            ">
            <Svgs nome="ajustes" />
            <p>{{ opcao.ativo ? 'configurar' : 'em breve' }}</p>
          </button>
          <button class="duvida" v-if="opcao.ativo">
            <Svgs nome="duvida" />
            <p>Como integrar?</p>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal } from '@stores'
import { reactive } from 'vue'
import Svgs from '@svgs'

const storeModal = useStoreModal()

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}

const state = reactive({
  opcoes: [
    {
      nome: 'hotmart',
      cor: '#EF4E23',
      ativo: true
    },
    {
      nome: 'kiwify',
      cor: '#2FB56F',
      ativo: true
    },
    {
      nome: 'ticto',
      cor: '#000000',
      ativo: true
    },
    {
      nome: 'eduzz',
      cor: '#FFBC00',
      ativo: false
    },
    {
      nome: 'monetizze',
      cor: '#0030FF',
      ativo: false
    },
    {
      nome: 'digitalguru',
      cor: '#27B495',
      ativo: false
    },
    {
      nome: 'pagarme',
      cor: '#65A300',
      ativo: false
    },
    {
      nome: 'stripe',
      cor: '#635BFF',
      ativo: false
    },
    {
      nome: 'kirvano',
      cor: '#171717',
      ativo: false
    },
    {
      nome: 'mercadopago',
      cor: '#019EE3',
      ativo: false
    },
    {
      nome: 'herospark',
      cor: '#FF2F5C',
      ativo: false
    },
    {
      nome: 'braip',
      cor: '#6D36FA',
      ativo: false
    },
    {
      nome: 'asaas',
      cor: '#0030B9',
      ativo: false
    },
    {
      nome: 'xgrow',
      cor: '#73AE24',
      ativo: false
    },
    {
      nome: 'hubla',
      cor: '#314000',
      ativo: false
    }
  ]
})
</script>

<style scoped>
section.plataformas {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 30px 30px;
  border-top: 1px solid var(--cor-cinza-2);
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 30px;
}

.titulo h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
}

.titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 10px 0 0 0;
}

.itens {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 0 10px 0 0;
}

.itens .item {
  display: flex;
  flex-direction: column;
  width: calc(33.33% - 20px);
  margin: 0 20px 20px 0;
  background-color: var(--cor-branco);
  border-radius: 10px;
}

.itens .item .capa {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 130px;
  border-radius: 10px 10px 0 0;
}

.itens .item .capa svg {
  width: 110px;
  max-width: 110px;
  max-height: 50px;
  fill: var(--cor-branco-fixo);
}

.itens .item .opcoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.itens .item .opcoes button.duvida {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px;
  margin: 0 0 0 10px;
}

.itens .item .opcoes button.duvida:hover p {
  color: var(--cor-preto);
}

.itens .item .opcoes button.duvida p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  transition: all 0.3s;
}

.itens .item .opcoes button.duvida:hover svg {
  fill: var(--cor-preto);
}

.itens .item .opcoes button.duvida svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza);
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

.itens .item .opcoes button.integrar.breve {
  background-color: var(--cor-cinza-2);
  pointer-events: none;
}

.itens .item .opcoes button.integrar {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: var(--cor-azul-forte);
  transition: all 0.3s;
}

.itens .item .opcoes button.integrar:hover {
  background-color: var(--cor-azul-forte-escuro);
}

.itens .item .opcoes button.integrar p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

.itens .item .opcoes button.integrar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  margin: 0 5px 0 0;
}

@media screen and (max-width: 1000px) {
  section.plataformas {
    padding: 0 20px 30px 20px;
  }

  .titulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 30px 20px;
  }

  .titulo h3 {
    font-size: var(--f2);
  }

  .itens {
    padding: 0;
  }

  .itens .item {
    width: 100%;
    margin: 0 0 20px 0;
  }
}
</style>
