<template>
  <section class="filtros">
    <div class="quadro">
      <div class="input busca">
        <Svgs class="icone" nome="lupa" />
        <input type="text" placeholder="Buscar por notificação" spellcheck="false">
      </div>
      <div class="select area">
        <Svgs class="icone" nome="setinha" />
        <select>
          <option value="" disabled selected>Área</option>
          <option v-for="area in storeAreas.areas" :value="`${area._id}`">{{ area.nome }}</option>
        </select>
      </div>
      <div class="select visualizacao">
        <Svgs class="icone" nome="setinha" />
        <select>
          <option value="" disabled selected>Ordem</option>
          <option value="nome">Alfabética (A-Z)</option>
          <option value="nome">Alfabética (Z-A)</option>
          <option value="nome">Não visto</option>
          <option value="nome">Já visto</option>
        </select>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreAreas } from '@stores'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
</script>

<style scoped>
section.filtros {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 30px 0 30px;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 10px 0;
  border-radius: 10px;
}

.input.busca {
  max-width: calc(40% - 5px);
}

.select.area {
  max-width: calc(30% - 5px);
}

.select.visualizacao {
  max-width: calc(30% - 5px);
}

@media screen and (max-width: 1000px) {
  section.filtros {
    flex-direction: column;
    padding: 0 20px 10px 20px;
  }

  .quadro {
    flex-wrap: wrap;
    margin: 0;
  }

  .input.busca {
    max-width: 100%;
    margin: 0 0 10px 0;
  }

  .select.area {
    max-width: calc(50% - 5px);
  }

  .select.area select {
    padding: 10px;
    font-size: var(--f1);
  }

  .select.area svg.icone {
    width: 12px;
    min-width: 12px;
    right: 8px;
  }

  .select.visualizacao {
    max-width: calc(50% - 5px);
  }

  .select.visualizacao svg.icone {
    width: 12px;
    min-width: 12px;
    right: 8px;
  }

  .select.visualizacao select {
    padding: 10px;
    font-size: var(--f1);
  }

}
</style>
