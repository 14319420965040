<template>
  <section class="topo">
    <div class="capa">
      <div class="foto"
        :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`">
      </div>
    </div>
    <div class="opcoes">
      <div class="nome">
        <h3>{{ storePerfil.usuario?.nome }}</h3>
        <p>{{ storePerfil.usuario?.email }}</p>
      </div>
      <button class="notificacao" :class="{ativo: storePainel.estatisticas?.quantidadeNotificacoesNaoLidas > 0}" @click="router.push('/notificacoes')">
        <Svgs nome="sino" />
        <span></span>
      </button>
    </div>
    <Particles />
  </section>
</template>

<script setup>
import { useStorePerfil, useStorePainel } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'
import Particles from '@components/global/particles/Particles.vue'

const storePerfil = useStorePerfil()
const storePainel = useStorePainel()
const router = useRouter()
</script>

<style scoped>
section.topo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.capa {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  background-color: var(--cor-branco-transparente);
  height: 200px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  z-index: 3;
}

.capa .particula {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 200px !important;
  height: 200px !important;
}

.capa .foto {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: var(--cor-cinza-2);
  border: 5px solid var(--cor-gelo);
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 30px;
  bottom: -75px;
}

.opcoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 0 200px;
  width: 100%;
  background-color: var(--cor-gelo);
  z-index: 2;
}

.opcoes .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.opcoes .nome h3 {
  font-family: var(--estilo);
  font-size: var(--f3);
  color: var(--cor-preto);
}

.opcoes .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 8px 0 0 0;
}

.notificacao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--cor-branco);
  margin: 0 0 0 15px;
  position: relative;
}

.notificacao svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-cinza);
  transition: all 0.3s;
}

.notificacao:hover svg {
  fill: var(--cor-azul-forte);
}

.notificacao.ativo span {
  opacity: 1;
  visibility: visible;
}

.notificacao span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--cor-vermelho);
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0;
  visibility: hidden;
  animation: piscandoSpan 1s ease-in-out infinite;
  transition: all 0.3s;
}

@keyframes piscandoSpan {
  0% {
    box-shadow: 0 0 5px 0 var(--cor-vermelho);
    transform: scale(1);
  }

  50% {
    box-shadow: 0 0 15px 0 var(--cor-vermelho);
    transform: scale(1.1);
  }

  100% {
    box-shadow: 0 0 15px 0 var(--cor-vermelho);
    transform: scale(1);
  }
}

@media screen and (max-width: 1000px) {

  .capa {
    height: 150px;
  }

  .capa svg {
    width: 200px;
    max-width: 200px;
    position: absolute;
    filter: blur(2px);
  }

  .capa .foto {
    width: 100px;
    height: 100px;
    left: 15px;
    bottom: -75px;
  }

  .opcoes {
    padding: 20px 20px 30px 130px;
  }

}
</style>
