<template>
  <nav :class="{ ativo: state.menuAberto }" :style="conteudoEstilo">
    <div class="titulo">
      <img v-if="storeAreas.area?.imagemLogo" :src="`${storeAreas.area?.imagemLogo}`" class="logo" />
      <Svgs v-else class="logo" nome="logo-v1" />
      <div class="nome">
        <a :href="`https://mber.live/${storeAreas.area?.hotlink}`" target="_blank" :style="tituloEstilo">mber.live/{{ storeAreas.area?.hotlink }}</a>
      </div>
      <button class="menu" :class="{ ativo: state.menuAberto }" @click="aberturaMenu()" ref="menu">
        <span class="um" :style="spanEstilo"></span>
        <span class="dois" :style="spanEstilo"></span>
      </button>
    </div>
    <div class="opcoes" :style="conteudoEstilo">
      <a class="voltar" @click="abrirPagina('/areas')">
        <Svgs :style="botoesSvgEstilo" nome="seta" />
        <p :style="tituloEstilo">Voltar ao início</p>
      </a>
      <a :class="{ ativo: $route.path === `/areas/${storeAreas.area?.hotlink}` }" @click="abrirPagina(`/areas/${storeAreas.area?.hotlink}`)">
        <Svgs :style="botoesSvgEstilo" nome="camadas" />
        <p :style="tituloEstilo">Resumo</p>
      </a>
      <a
        :class="{
          ativo: $route.path === `/areas/${storeAreas.area?.hotlink}/cursos`
        }"
        @click="abrirPagina(`/areas/${storeAreas.area?.hotlink}/cursos`)">
        <Svgs :style="botoesSvgEstilo" nome="curso" />
        <p :style="tituloEstilo">Cursos</p>
      </a>
      <a
        :class="{
          ativo: $route.path === `/areas/${storeAreas.area?.hotlink}/integracoes`
        }"
        @click="abrirPagina(`/areas/${storeAreas.area?.hotlink}/integracoes`)">
        <Svgs :style="botoesSvgEstilo" nome="webhook" />
        <p :style="tituloEstilo">Integrações</p>
      </a>
      <a
        :class="{
          ativo: $route.path === `/areas/${storeAreas.area?.hotlink}/alunos`
        }"
        @click="abrirPagina(`/areas/${storeAreas.area?.hotlink}/alunos`)">
        <Svgs :style="botoesSvgEstilo" nome="alunos" />
        <p :style="tituloEstilo">Alunos</p>
      </a>
      <a
        :class="{
          ativo: $route.path === `/areas/${storeAreas.area?.hotlink}/personalizacao`
        }"
        @click="abrirPagina(`/areas/${storeAreas.area?.hotlink}/personalizacao`)">
        <Svgs :style="botoesSvgEstilo" nome="personalizar" />
        <p :style="tituloEstilo">Personalização</p>
      </a>
    </div>
  </nav>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreAreas } from '@stores'
import Svgs from '@svgs'

const router = useRouter()
const storeAreas = useStoreAreas()

const menu = ref()
const body = document.querySelector('body')
const state = reactive({
  menuAberto: false
})

const tela = computed(() => storeAreas.area?.personalizacao?.menu || {})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo || '#E1E3EB'
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulo || '#252939'
}))

const spanEstilo = computed(() => ({
  backgroundColor: tela.value.corTitulo || '#252939'
}))

const botoesSvgEstilo = computed(() => ({
  fill: tela.value.corIcones || '#252939'
}))

function aberturaMenu() {
  state.menuAberto = !state.menuAberto
  if (state.menuAberto) {
    body.classList.add('bloqueado')
  } else {
    body.classList.remove('bloqueado')
  }
}

function fecharMenu() {
  state.menuAberto = false
  body.classList.remove('bloqueado')
}

function abrirPagina(pagina) {
  const url = window.location.pathname
  fecharMenu()

  if (pagina === url) return
  else router.push(pagina)
}

async function carregarArea() {
  const hotlink = router.currentRoute.value.params.hotlinkArea

  await storeAreas.receberPorHotlink(hotlink)

  document.title = storeAreas.area?.nome + ' | Level Member'
}

onMounted(() => {
  carregarArea()
})
</script>

<style scoped>
nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100dvh;
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  background-color: var(--cor-branco);
  z-index: 10;
}

nav .titulo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 30px 30px 0 30px;
}

nav .titulo img.logo {
  max-width: 170px;
  max-height: 40px;
}

nav .titulo svg.logo {
  width: 150px;
  min-width: 150px;
}

nav .titulo .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

nav .titulo a {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-azul-forte);
  line-height: 1.5;
  margin: 10px 0 0 0;
  transition: all 0.3s;
}

nav .titulo a:hover {
  opacity: 0.7;
}

nav .titulo button.menu {
  display: none;
  position: absolute;
  right: 20px;
  width: 35px;
  height: 35px;
  background-color: transparent;
}

nav .titulo button.menu span {
  position: absolute;
  background-color: var(--cor-preto);
  border-radius: 10px;
  height: 3px;
  transition: all 0.5s;
}

nav .titulo button.menu:hover span {
  background-color: var(--cor-cinza-2);
}

nav .titulo button.menu span.um {
  top: 10px;
  right: 0;
  width: 25px;
}

nav .titulo button.menu span.dois {
  bottom: 10px;
  right: 0;
  width: 35px;
}

nav.ativo .titulo button.menu span {
  background-color: var(--cor-vermelho);
}

nav.ativo .titulo button.menu span.um {
  transform: rotate(45deg);
  width: 35px;
  top: 16px;
}

nav.ativo .titulo button.menu span.dois {
  transform: rotate(-45deg);
  bottom: 16px;
}

nav .opcoes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px 0 0 0;
}

nav .opcoes .info {
  display: none;
  align-items: center;
  width: 100%;
  padding: 20px 20px 10px 20px;
}

nav .opcoes .info .capa {
  width: 100%;
  max-width: 120px;
}

nav .opcoes .info .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

nav .opcoes .info .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-cinza-2);
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

nav .opcoes .info .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 10px;
}

nav .opcoes .info h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-preto);
  line-height: 1.5;
}

nav .opcoes .info p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  line-height: 1.5;
  margin: 3px 0 3px 0;
}

nav .opcoes .info a {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-roxo);
  line-height: 1.5;
  padding: 0;
  transition: all 0.3s;
}

nav .opcoes .info a:hover {
  opacity: 0.7;
}

nav .opcoes a,
nav .opcoes button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  width: 100%;
  padding: 10px 30px;
  position: relative;
  transition: all 0.3s;
}

nav .opcoes button:hover {
  opacity: 0.6;
}

nav .opcoes button svg {
  fill: var(--cor-ciano) !important;
}

nav .opcoes button p {
  color: var(--cor-ciano) !important;
}

nav .opcoes a.ativo,
nav .opcoes button.ativo {
  pointer-events: none;
  opacity: 0.5;
}

nav .opcoes a.breve {
  pointer-events: none;
}

nav .opcoes a.breve svg {
  fill: var(--cor-cinza-2);
}

nav .opcoes a.ativo svg,
nav .opcoes button.ativo svg {
  fill: var(--cor-azul-forte);
}

nav .opcoes a.breve p {
  color: var(--cor-cinza);
}

nav .opcoes a.voltar svg {
  transform: rotate(180deg);
}

nav .opcoes a svg,
nav .opcoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-cinza);
  margin: 0 10px 0 0;
  z-index: 1;
}

nav .opcoes a p,
nav .opcoes button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  z-index: 1;
  transition: all 0.3s;
}

nav .opcoes a:hover p,
nav .opcoes button:hover p {
  opacity: 0.5;
}

nav .opcoes a.notificacoes {
  position: relative;
}

nav .opcoes a.notificacoes.novo span {
  display: flex;
}

nav .opcoes a.notificacoes span {
  display: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--cor-vermelho);
  position: absolute;
  top: 10px;
  left: 30px;
  animation: zoomSpan 1s linear infinite;
}

@keyframes zoomSpan {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  nav {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    max-height: 60px;
    height: 70px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border-bottom: 2px solid var(--cor-cinza-2);
  }

  nav .titulo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px 0 20px;
    border-bottom: none;
  }

  nav .titulo img.logo {
    max-width: 120px;
    max-height: 25px;
  }

  nav .titulo svg.logo {
    width: 130px;
    min-width: 130px;
  }

  nav .titulo .nome {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: auto;
  }

  nav .titulo a {
    display: none;
  }

  nav .titulo button.menu {
    display: flex;
  }

  nav .opcoes {
    width: 250px;
    height: auto;
    padding: 25px 30px;
    position: fixed;
    top: 70px;
    right: 20px;
    background-color: var(--cor-branco);
    border-radius: 10px 10px 10px 10px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s;
  }

  nav .opcoes .info {
    display: flex;
  }

  nav.ativo .opcoes {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  nav .opcoes a,
  nav .opcoes button {
    padding: 10px 0;
  }

  nav .opcoes a svg,
  nav .opcoes button svg {
    width: 12px;
    min-width: 12px;
  }
}
</style>
