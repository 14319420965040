<template>
  <section class="lista">
    <div class="quadro" v-for="notificacao in storeNotificacoes.notificacoes" :key="notificacao._id">
      <div class="capa" :style="`background-image: url('${pegarImagem(notificacao.area?.id)}')`"></div>
      <div class="nome">
        <h3>{{ maximoTexto(notificacao.texto, 30) }}</h3>
        <p>{{ notificacao.titulo }}</p>
      </div>
      <div class="parte area">
        <h3>{{ notificacao.area?.nome }}</h3>
        <div class="item">
          <Svgs nome="curso" />
          <p>{{ maximoTexto(notificacao.curso?.nome, 20) }}</p>
        </div>
      </div>
      <div class="parte data">
        <h3>Data</h3>
        <div class="item">
          <Svgs nome="relogio" />
          <p>{{ formatarData(notificacao.dataRegistro) }}</p>
        </div>
      </div>
      <div class="parte status nova" v-if="!notificacao?.visto">
        <h3>Status</h3>
        <div class="item">
          <Svgs nome="sino" />
          <p>Não visto</p>
        </div>
      </div>
      <div class="parte status visualizado" v-if="notificacao?.visto">
        <h3>Status</h3>
        <div class="item">
          <Svgs nome="visualizar" />
          <p>Visualizado</p>
        </div>
      </div>
      <button
        @click="
          abrirModalEdicao(
            'verNotificacao', // nome do modal
            'degrade-azul', // cor do icone
            'visualizar', // nome do icone
            'Ver notificação', // titulo
            formatarData(notificacao.dataRegistro), // subtitulo
            notificacao._id // id
          )
        ">
        <p>Ver notificação</p>
        <Svgs nome="seta" />
      </button>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal, useStoreNotificacoes, useStoreAreas } from '@stores'
import Svgs from '@svgs'
import { onMounted, ref } from 'vue'

const storeNotificacoes = useStoreNotificacoes()
const storeModal = useStoreModal()
const storeAreas = useStoreAreas()

function pegarImagem(idArea) {
  return areaMap.value.get(idArea) || ''
}

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}

function maximoTexto(texto, maximo) {
  if (texto.length > maximo) {
    return texto.slice(0, maximo) + '...'
  }
  return texto
}

function formatarData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há segundos'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()
    const hours = String(dataNotificacao.getHours()).padStart(2, '0')
    const minutes = String(dataNotificacao.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} às ${hours}h${minutes}`
  }
}

const areaMap = ref(new Map())

onMounted(async () => {
  await storeAreas.receberTodos()
  storeAreas.areas.forEach((area) => {
    areaMap.value.set(area._id, area.imagemLogo)
  })
})
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 25px 20px 30px;
  overflow-y: scroll;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-branco);
  width: 100%;
  padding: 15px;
  margin: 0 0 10px 0;
  min-width: 1100px;
  border-radius: 10px;
}

.quadro .capa {
  width: 80px;
  height: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.quadro .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
  transition: all 0.3s;
}

.quadro .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

.quadro .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  margin: 7px 0 0 0;
}

.quadro .parte {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--cor-cinza-2);
}

.quadro .parte.area {
  min-width: 200px;
}

.quadro .parte.data {
  min-width: 180px;
}

.quadro .parte.status {
  min-width: 120px;
}

.quadro .parte.nova .item svg {
  fill: var(--cor-vermelho);
}

.quadro .parte.visualizado .item p {
  color: var(--cor-cinza);
}

.quadro .parte h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  margin: 0 0 10px 0;
}

.quadro .parte .item {
  display: flex;
  align-items: center;
}

.quadro .parte .item svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-cinza);
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

.quadro .parte .item p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-preto);
  transition: all 0.3s;
}

.quadro button {
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 10px 15px;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-cinza-2);
  transition: all 0.3s;
}

.quadro button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-cinza);
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

.quadro button:hover {
  border: 2px solid var(--cor-roxo);
  transform: scale(1.05);
}

.quadro button:hover p {
  color: var(--cor-roxo);
}

.quadro button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-roxo);
}

@media screen and (max-width: 1000px) {
  section.lista {
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }

  .quadro {
    flex-wrap: wrap;
    padding: 15px;
    min-width: auto;
  }

  .quadro .capa {
    margin: 0 0 10px 0;
  }

  .quadro .nome {
    width: 100%;
    padding: 0 0 15px 0;
  }

  .quadro .nome h3 {
    font-size: var(--f2);
  }

  .quadro .nome p {
    font-size: var(--f1);
  }

  .quadro .parte {
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid var(--cor-cinza-2);
    border-left: none;
  }

  .quadro .parte h3 {
    margin: 0;
  }

  .quadro .parte button {
    padding: 8px 15px;
  }

  .quadro .parte button svg {
    width: 13px;
    min-width: 13px;
    margin: 0 8px 0 0;
  }
}
</style>
