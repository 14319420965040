<template>
  <section class="curso">
    <div class="quadro">
      <div class="capa" @click="router.push(`/areas/${storeAreas.area?.hotlink}/cursos`)">
        <div class="tamanho">
          <div class="imagem" :style="`background-image: url('${storeCursos.curso?.imagemCapa}')`"></div>
        </div>
      </div>
      <div class="nome" @click="router.push(`/areas/${storeAreas.area?.hotlink}/cursos`)">
        <h3>{{ storeCursos.curso?.nome }}</h3>
        <p>{{ storeCursos.curso?.descricao }}</p>
      </div>
      <div class="parte alunos">
        <Svgs nome="alunos"/>
        <h3>{{ storeCursos.curso?.quantidadeAlunos }} <span>{{ textoAlunos(storeCursos.curso?.quantidadeAlunos) }}</span></h3>
      </div>
      <div class="parte modulos">
        <Svgs nome="modulo"/>
        <h3>{{ storeCursos.curso?.quantidadeModulos }} <span>{{ textoModulos(storeCursos.curso?.quantidadeModulos) }}</span></h3>
      </div>
      <div class="parte aulas" v-if="storeModulos.modulos?.length > 0">
        <Svgs nome="play"/>
        <h3>{{ storeCursos.curso?.quantidadeAulas }} <span>{{ textoAulas(storeCursos.curso?.quantidadeAulas) }}</span></h3>
      </div>
    </div>
    <div class="posicao" v-if="storeModulos.modulos?.length > 1">
      <h3>Altere a ordem dos módulos</h3>
      <button @click="abrirModal(
        'editarPosicaoModulos', // nome do modal
        'degrade-azul', // cor do icone
        'cursor', // nome do icone
        'Posição dos módulos', // titulo
        'Altere a ordem dos módulos' // subtitulo
      )">
        <Svgs nome="cursor" />
        <p>Editar posição</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStoreModal, useStoreAreas, useStoreCursos, useStoreModulos } from '@stores'
import Svgs from '@svgs'

const storeModulos = useStoreModulos()
const storeCursos = useStoreCursos()
const storeAreas = useStoreAreas()
const storeModal = useStoreModal()
const router = useRouter()

function abrirModal(nome, cor, icone, titulo, subtitulo) {
  storeModal.abrirModal(nome, cor, icone, titulo, subtitulo)
}

const textoAlunos = (quantidade) => {
  return `${quantidade === 1 ? 'aluno' : 'alunos'}`
}

const textoModulos = (quantidade) => {
  return `${quantidade === 1 ? 'módulo' : 'módulos'}`
}

const textoAulas = (quantidade) => {
  return `${quantidade === 1 ? 'aula' : 'aulas'}`
}
</script>

<style scoped>
section.curso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 30px 20px 30px;
}

.posicao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid var(--cor-cinza-2);
}

.posicao h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  line-height: 1.5;
}

.posicao button {
  display: flex;
  align-items: center;
  padding: 0 5px 0 5px;
  background-color: transparent;
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

.posicao button:hover svg {
  fill: var(--cor-azul-forte-escuro);
}

.posicao button:hover p {
  color: var(--cor-azul-forte-escuro);
}

.posicao button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-azul-forte);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.posicao button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-azul-forte);
  transition: all 0.3s;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-branco);
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

.quadro .capa {
  width: 100%;
  max-width: 150px;
  cursor: pointer;
  transition: all 0.3s;
}

.quadro .capa:hover {
  opacity: 0.6;
}

.quadro .capa .tamanho {
  position: relative;
  padding-bottom: 40%;
  width: 100%;
}

.quadro .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-cinza-2);
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.quadro .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.quadro .nome:hover {
  opacity: 0.6;
}

.quadro .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
}

.quadro .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}

.quadro .parte {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 30px;
  border-left: 1px solid var(--cor-cinza-2);
}

.quadro .parte.alunos {
  border-left: none;
  padding: 0 30px 0 0;
}

.quadro .parte.aulas {
  padding: 0 10px 0 30px;
}

.quadro .parte h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

.quadro .parte svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-preto);
  margin: 0 10px 0 0;
}

@media screen and (max-width: 1000px) {
  section.curso {
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }

  .posicao {
    padding: 15px;
  }

  .posicao h3 {
    font-size: var(--f1);
  }

  .posicao button svg {
    width: 10px;
    min-width: 10px;
    margin: 0 8px 0 0;
  }

  .posicao button p {
    font-size: var(--f1);
  }

  .quadro {
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px;
  }

  .quadro .capa {
    width: 100%;
    max-width: 120px;
  }

  .quadro .nome {
    width: calc(100% - 120px);
    padding: 0 0 0 15px;
  }

  .quadro .nome h3 {
    font-size: var(--f2);
  }

  .quadro .nome p {
    font-size: var(--f1);
  }

  .quadro .parte {
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 0;
    margin: 20px 0 0 0;
    width: auto;
    max-width: auto;
  }

  .quadro .parte.modulos {
    padding: 0 20px;
  }

  .quadro .parte.alunos {
    border-left: none;
    padding: 0;
  }

  .quadro .parte.aulas {
    padding: 0;
  }

  .quadro .parte h3 {
    margin: 0;
    font-size: var(--f1);
  }

  .quadro .parte svg {
    width: 10px;
    min-width: 10px;
    margin: 0 8px 0 0;
  }

}
</style>
