<template>
  <Modal nome="criarAluno">
    <label>
      Nome do aluno
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="usuario" />
      <input v-model="state.nome" type="text" spellCheck="false" placeholder="Ex: João Silva" @keydown.enter="criarAluno" />
    </div>

    <label>
      E-mail de acesso
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="envelope" />
      <input v-model="state.email" type="email" spellCheck="false" placeholder="email@email.com" @keydown.enter="criarAluno" />
    </div>

    <label>
      Whatsapp do aluno
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="whatsapp" />
      <input type="tel" spellCheck="false" placeholder="(00) 00000-0000" v-mask="mascaraAtual" v-model="numeroTelefone" @keydown.enter="criarAluno" />
    </div>

    <label>
      Senha do aluno
      <span>*</span>
    </label>
    <div class="inputInstrucao">
      <p>Enviaremos um e-mail para o aluno com a senha de acesso e o link da área de membros para acessar a esse conteúdo</p>
    </div>

    <SalvarModal nome="Criar Novo Aluno" @click="criarAluno()" />
  </Modal>
</template>

<script setup>
import { ref, watch, reactive, inject, onMounted } from 'vue'
import { useStoreAreas, useStoreAlunos, useStoreModal } from '@stores'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeAlunos = useStoreAlunos()
const storeModal = useStoreModal()
const numeroTelefone = ref('')
const mascaraAtual = ref('(##) #####-####')

const state = reactive({
  nome: '',
  whatsapp: '',
  email: ''
})

async function criarAluno() {
  if (!validarDados()) return

  const idArea = storeAreas.area?._id

  const payload = {
    nome: state.nome,
    email: state.email,
    contato: {
      whatsapp: numeroTelefone.value
    }
  }

  const adicionadoOk = await storeAlunos.adicionar(idArea, payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarAlunos')
  storeModal.fecharModal('criarAluno')
  limparCampos()
}

function limparCampos() {
  state.nome = ''
  state.email = ''
  state.senha = ''
  state.whatsapp = ''
  numeroTelefone.value = ''
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.email || !numeroTelefone.value) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

watch(numeroTelefone, (novoValor) => {
  const digitos = novoValor.replace(/\D/g, '')
  if (digitos.length <= 10) {
    mascaraAtual.value = '(##) ####-####'
  } else {
    mascaraAtual.value = '(##) #####-####'
  }
})

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

label.link {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
}

label button {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-roxo);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  opacity: 0.6;
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-roxo);
  margin: 0 5px 0 0;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

.iframeVideo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.iframeVideo .descricao {
  display: flex;
  width: 100%;
}

.iframeVideo .descricao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  padding: 20px;
  width: 50%;
  background-color: var(--cor-branco);
  border-bottom: 1px solid var(--cor-cinza-2);
  border-radius: 10px 0 0 0;
}

.iframeVideo textarea {
  border-radius: 0 0 10px 10px;
}

.iframeVideo .video {
  width: 50%;
}

.iframeVideo .video .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.iframeVideo .video .tamanho iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-preto);
  border-radius: 0 10px 0 0;
}

.inputDuplo {
  position: relative;
  align-items: center;
  margin: 10px 0 0 0;
}

.inputDuplo button.excluir {
  position: absolute;
  right: 20px;
  background-color: var(--cor-branco);
  width: 40px;
  height: 40px;
  animation: fadeIn 0.3s ease-in-out;
}

.inputDuplo button.excluir svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-vermelho);
  transition: all 0.3s;
}

.inputDuplo button.excluir:hover svg {
  fill: var(--cor-preto);
}

@media screen and (max-width: 1000px) {
  .iframeVideo .descricao {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .iframeVideo .descricao p {
    font-size: var(--f1);
    width: 100%;
    border-radius: 0;
  }

  .iframeVideo textarea {
    border-radius: 0 0 10px 10px;
  }

  .iframeVideo .video {
    width: 100%;
  }

  .iframeVideo .video .tamanho iframe {
    border-radius: 10px 10px 0 0;
  }
}
</style>
