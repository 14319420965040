<template>
  <Modal nome="criarAula">
    <label>
      Nome da aula
      <span>*</span>
    </label>
    <input v-model="state.nome" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Aula sobre Vendas" @keydown.enter="criarAula" />

    <label>Descrição da aula (opcional)</label>
    <textarea v-model="state.descricao" rows="3" spellCheck="false" autocomplete="false" placeholder="Ex: Aula com foco em vendas" @keydown.enter="criarAula" v-redimensionar-textarea></textarea>

    <label>
      URL do iframe da aula
      <span>*</span>
    </label>
    <div class="iframeVideo">
      <div class="descricao">
        <p>Copie o código do iframe no PandaVideo, Youtube, Vimeo ou qualquer ferramenta de hospedagem de vídeos e cole no campo abaixo</p>
        <div class="video">
          <div class="tamanho">
            <iframe :src="codigoIframe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <textarea rows="3" spellcheck="false" autocomplete="false" v-model="conteudoTextarea" @input="filtrarInput" placeholder="Cole aqui..." @keydown.enter="criarAula" v-redimensionar-textarea></textarea>
    </div>

    <label>
      Imagem de capa da aula
      <span>*</span>
    </label>
    <div class="inputFile">
      <button @click="carregarImagem()" :disabled="state.uploadImagem.desabilitarBotao">
        <span></span>
        <Svgs nome="arquivo" />
        <h3>Subir Imagem</h3>
        <p>Tamanho: 640x360</p>
        <UploadImagem :dados="state.uploadImagem" ref="uploadImagem" @atualizarDadosImagem="atualizarDadosImagem($event)" />
      </button>
      <div class="previa" :style="`background-image: url('${state.imagemCapa || ''}')`"></div>
    </div>

    <label>
      Módulo da aula
      <span>*</span>
    </label>
    <div class="select">
      <Svgs class="icone" nome="setinha" />
      <select v-model="state.modulo">
        <option v-if="storeModulos.modulo" :value="`${storeModulos.modulo?._id}`" selected>Módulo atual: {{ storeModulos.modulo?.nome }}</option>
        <option v-for="modulo in storeModulos.modulos" :key="modulo._id" :value="`${modulo._id}`">{{ modulo.nome }}</option>
      </select>
    </div>

    <label>Duração em minutos (opcional)</label>
    <div class="input">
      <Svgs class="icone" nome="relogio" />
      <input v-model="state.duracao" type="tel" autocomplete="false" spellCheck="false" v-mask="'###'" placeholder="0" @keydown.enter="criarAula" />
    </div>

    <label class="link" :class="{ maior: state.links.length === 0 }">
      Links Externos (opcional)
      <button @click="adicionarLink">
        <Svgs nome="link" />
        Adicionar link
      </button>
    </label>
    <div v-for="(link, index) in state.links" :key="index" class="inputDuplo">
      <input type="text" v-model="link.nome" autocomplete="false" placeholder="Nome do link" spellcheck="false" @keydown.enter="criarAula" />
      <input type="url" v-model="link.url" autocomplete="false" placeholder="https://link.com" spellcheck="false" @keydown.enter="criarAula" />
      <button class="excluir" @click="removerLink(index)">
        <Svgs nome="excluir" />
      </button>
    </div>

    <label class="link" :class="{ maior: state.arquivos.length === 0 }">
      Arquivos para Download (opcional)
      <button @click="adicionarArquivo" :disabled="state.uploadArquivo.desabilitarBotao">
        <Svgs nome="arquivo" />
        Adicionar arquivo
        <UploadArquivo :dados="state.uploadArquivo" ref="uploadArquivo" @atualizarDadosArquivo="atualizarDadosArquivo($event)" />
      </button>
    </label>
    <div v-for="(arquivo, index) in state.arquivos" :key="index" class="inputDuplo">
      <input type="text" v-model="arquivo.nome" autocomplete="false" placeholder="Nome do arquivo" spellcheck="false" @keydown.enter="criarAula" />

      <div class="input">
        <Svgs class="icone" nome="link" />
        <input type="text" :value="extrairNomeArquivo(arquivo.url)" readonly />
      </div>

      <button class="excluir" @click="removerArquivo(index)">
        <Svgs nome="excluir" />
      </button>
    </div>

    <SalvarModal nome="Criar Nova Aula" @click="criarAula()" />
  </Modal>
</template>

<script setup>
import { ref, reactive, inject, onMounted } from 'vue'
import { useStoreAreas, useStoreCursos, useStoreModulos, useStoreAulas, useStoreModal } from '@stores'
import { helperTexto } from '@helpers'
import { useRouter } from 'vue-router'

import UploadArquivo from '@components/global/upload/arquivo.vue'
import UploadImagem from '@components/global/upload/image.vue'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModulos = useStoreModulos()
const storeAulas = useStoreAulas()
const storeModal = useStoreModal()
const conteudoTextarea = ref('')
const codigoIframe = ref('')
const uploadImagem = ref(null)
const uploadArquivo = ref(null)
const router = useRouter()

const state = reactive({
  nome: '',
  descricao: '',
  hotlink: '',
  duracao: '',
  urlVideo: '',
  links: [],
  arquivos: [],
  imagemCapa: '',
  uploadImagem: {
    tamanhoMaximo: 1920,
    urlImagem: '',
    desabilitarBotao: false
  },
  uploadArquivo: {
    tamanhoMaximo: 1920,
    urlArquivo: '',
    desabilitarBotao: false
  }
})

function atualizarDadosImagem(dados) {
  if (typeof dados.desabilitarBotao === 'boolean') state.uploadImagem.desabilitarBotao = dados.desabilitarBotao
  if (typeof dados.urlImagem === 'string') {
    state.uploadImagem.urlImagem = dados.urlImagem
    state.imagemCapa = state.uploadImagem.urlImagem
  }
}

function carregarImagem() {
  uploadImagem?.value?.enviarImagem()
}

function atualizarDadosArquivo(dados) {
  if (typeof dados.desabilitarBotao === 'boolean') state.uploadArquivo.desabilitarBotao = dados.desabilitarBotao
  if (typeof dados.urlArquivo === 'string') {
    state.uploadArquivo.urlArquivo = dados.urlArquivo
    state.arquivos.push({ nome: '', url: dados.urlArquivo })
  }
}

const adicionarArquivo = async () => {
  const urlArquivo = await uploadArquivo?.value?.enviarArquivo()
  if (urlArquivo) {
    state.arquivos.push({ nome: '', url: urlArquivo })
  }
}

function extrairNomeArquivo(url) {
  const ultimoSlash = url.lastIndexOf('/') + 1
  const nomeArquivo = url.substring(ultimoSlash)
  const posicaoHifen = nomeArquivo.indexOf('-') + 1
  return nomeArquivo.substring(posicaoHifen)
}

const removerArquivo = (index) => {
  state.arquivos.splice(index, 1)
}

const adicionarLink = () => {
  state.links.push({ nome: '', url: '' })
}

const removerLink = (index) => {
  state.links.splice(index, 1)
}

function nomeParaHotlink() {
  state.hotlink = helperTexto.converterEmHotlink(state.nome)
}

async function criarAula() {
  if (!validarDados()) return

  nomeParaHotlink()

  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = state.modulo

  const payload = {
    nome: state.nome,
    descricao: state.descricao,
    hotlink: state.hotlink,
    urlVideo: state.urlVideo,
    duracao: state.duracao,
    imagemCapa: state.imagemCapa,
    links: state.links.map((link) => ({ nome: link.nome, url: link.url })),
    arquivos: state.arquivos.map((arquivo) => ({ nome: arquivo.nome, url: arquivo.url }))
  }

  const adicionadoOk = await storeAulas.adicionar(idArea, idCurso, idModulo, payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarAulas')
  storeModal.fecharModal('criarAula')

  limparCampos()
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.urlVideo || !state.imagemCapa) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

function limparCampos() {
  state.nome = ''
  state.descricao = ''
  state.hotlink = ''
  state.urlVideo = ''
  state.duracao = ''
  state.imagemCapa = ''
  state.arquivos = []
  state.links = []
  codigoIframe.value = ''
  conteudoTextarea.value = ''
}

const filtrarInput = async () => {
  const pegarIframe = conteudoTextarea.value.match(/<iframe.*?src="(.*?)"/)
  const pegarUrl = conteudoTextarea.value.match(/https?:\/\/[^\s]+/)

  if (pegarIframe) {
    codigoIframe.value = pegarIframe[1]
    state.urlVideo = codigoIframe.value
  } else if (pegarUrl) {
    codigoIframe.value = pegarUrl[0]
    state.urlVideo = codigoIframe.value
  } else {
    codigoIframe.value = ''
    state.urlVideo = codigoIframe.value
  }

  if (state.urlVideo.includes('youtube.com') || state.urlVideo.includes('youtu.be')) {
    const videoIdMatch = state.urlVideo.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/)
    if (videoIdMatch) {
      const videoId = videoIdMatch[1]
      state.imagemCapa = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
      state.urlVideo = `https://www.youtube.com/embed/${videoId}`
      codigoIframe.value = `https://www.youtube.com/embed/${videoId}`
    }
  }

  if (state.urlVideo.includes('pandavideo.com.br')) {
    const videoIdMatch = state.urlVideo.match(/\/v=([a-z0-9-]+)/i) || state.urlVideo.match(/\/embed\/\?v=([a-z0-9-]+)/i)
    if (videoIdMatch) {
      const videoId = videoIdMatch[1]
      const servidorId = 'b-vz-cce5a623-141' // Supondo que este ID é constante
      state.imagemCapa = `https://${servidorId}.tv.pandavideo.com.br/${videoId}/thumbnail.jpg`
    }
  }

  if (state.urlVideo.includes('vimeo.com')) {
    const videoIdMatch = state.urlVideo.match(/video\/(\d+)/)
    if (videoIdMatch) {
      const videoId = videoIdMatch[1]
      try {
        const response = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`)
        const data = await response.json()
        state.imagemCapa = data[0].thumbnail_large
      } catch (error) {
        console.error('Erro ao buscar os detalhes do vídeo do Vimeo:', error)
      }
    }
  }
}

async function carregarModulos() {
  const hotlinkModulo = router.currentRoute.value.params.hotlinkModulo

  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id

  if (hotlinkModulo) {
    await storeModulos.receberPorHotlink(idArea, idCurso, hotlinkModulo)
    state.modulo = storeModulos.modulo?._id
  }

  if (idArea && idCurso) {
    storeModulos.receberTodos(idArea, idCurso)
  }
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
  emitter.on('carregarCampos', carregarModulos)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
  transition: all 0.3s;
}

label.maior {
  font-size: var(--f2);
  padding: 25px;
  background-color: var(--cor-branco);
  border-radius: 10px;
  width: 100%;
}

label.link {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
}

label button {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-roxo);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  opacity: 0.6;
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-roxo);
  margin: 0 5px 0 0;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

.iframeVideo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.iframeVideo .descricao {
  display: flex;
  width: 100%;
}

.iframeVideo .descricao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  padding: 15px;
  margin: 0 8px 0 0;
  width: 50%;
  background-color: var(--cor-branco);
  border-radius: 10px;
}

.iframeVideo textarea {
  border-radius: 10px;
  margin: 10px 0 0 0;
}

.iframeVideo .video {
  width: 50%;
}

.iframeVideo .video .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.iframeVideo .video .tamanho iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
}

.inputDuplo {
  position: relative;
  align-items: center;
  margin: 10px 0 0 0;
}

.inputDuplo button.excluir {
  position: absolute;
  right: 20px;
  background-color: var(--cor-branco);
  width: 40px;
  height: 40px;
  animation: fadeIn 0.3s ease-in-out;
}

.inputDuplo button.excluir svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-vermelho);
  transition: all 0.3s;
}

.inputDuplo button.excluir:hover svg {
  fill: var(--cor-preto);
}

.inputDuplo .input button.subir {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 25px 25px 25px 50px;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-branco);
  border-radius: 0 10px 10px 0;
  position: relative;
}

.inputDuplo .input button.subir:hover p {
  color: var(--cor-azul);
}

.inputDuplo .input button.subir:hover svg {
  fill: var(--cor-azul);
}

.inputDuplo .input button.subir svg {
  transition: all 0.3s;
}

.inputDuplo .input button.subir p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  transition: all 0.3s;
}

@media screen and (max-width: 1000px) {
  .iframeVideo .descricao {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .iframeVideo .descricao p {
    font-size: var(--f1);
    width: 100%;
    border-radius: 0;
  }

  .iframeVideo textarea {
    border-radius: 0 0 10px 10px;
  }

  .iframeVideo .video {
    width: 100%;
  }

  .iframeVideo .video .tamanho iframe {
    border-radius: 10px 10px 0 0;
  }
}
</style>
