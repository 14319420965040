<template>
  <section class="entrar">
    <DivImagem />
    <div class="conteudo">
      <div class="titulo">
        <Svgs nome="logo-v2" />
        <p>A sua tecnologia de criação inovadora para áreas de membros com foco em diferenciação</p>
      </div>
      <label>
        E-mail do produtor
        <span>*</span>
      </label>
      <div class="input">
        <Svgs class="icone" nome="envelope" />
        <input type="email" spellCheck="false" placeholder="email@email.com" autoComplete="email" v-model="state.email" @keydown.enter="entrar" />
      </div>
      <label>
        Senha de acesso
        <span>*</span>
      </label>
      <div class="input">
        <Svgs class="icone" nome="cadeado" />
        <input :type="state.mostrarSenha ? 'text' : 'password'" spellCheck="false" placeholder="senha#123" autoComplete="current-password" v-model="state.senha" @keydown.enter="entrar" />
        <button class="ocultar" @click="toggleMostrarSenha">
          <Svgs :nome="state.mostrarSenha ? 'oculto' : 'visivel'" />
        </button>
      </div>
      <button class="entrar" @click="entrar()" :disabled="state.carregando">
        Entrar na Level
        <span></span>
      </button>
      <div class="opcoes">
        <a href="https://wa.me/551831993895">Esqueceu sua senha?</a>
        <span></span>
        <router-link to="/registrar">Não tem conta? Teste grátis</router-link>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, inject } from 'vue'
import { helperValidar } from '@helpers'
import apiAutenticacao from '@/api/produtor/api-autenticacao'
import Svgs from '@svgs'
import DivImagem from '@components/autenticacao/entrar/partials/DivImagem.vue'

const emitter = inject('emitter')

const state = reactive({
  email: '',
  senha: '',
  carregando: false,
  mostrarSenha: false
})

const toggleMostrarSenha = () => {
  state.mostrarSenha = !state.mostrarSenha
}

function validarDados() {
  let mensagemDeAviso

  if (!state.email || !state.senha) mensagemDeAviso = 'Email e senha obrigatórios'
  else if (!helperValidar.email(state.email)) mensagemDeAviso = 'Email no formato inválido'
  else if (state.senha.length < 6) mensagemDeAviso = 'Senha deve conter no mínimo 6 caracteres'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', titulo: 'Erro ao fazer login', mensagem: mensagemDeAviso })
  return false
}

async function entrar() {
  if (!validarDados()) return

  const payload = {
    email: state.email,
    senha: state.senha
  }

  state.carregando = true

  try {
    const resp = await apiAutenticacao.entrar(payload)
    localStorage.setItem('level-member-produtor-token', resp.token)
    setTimeout(() => window.location.assign('/painel'), 500)
  } catch (error) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', titulo: 'Erro ao fazer login', mensagem: error })
    state.carregando = false
    return
  }
}
</script>

<style scoped>
section.entrar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: var(--cor-gelo);
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50%;
  min-height: 100dvh;
  background-color: var(--cor-gelo);
}

.conteudo .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conteudo .titulo svg {
  width: 150px;
  min-width: 150px;
  margin: 0 0 20px 0;
}

.conteudo .titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 350px;
  margin: 0 0 20px 0;
}

.conteudo .input {
  max-width: 400px;
  margin: 0 0 20px 0;
}

.conteudo label {
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin: 0 0 10px 0;
}

.conteudo button.entrar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--estilo);
  font-size: var(--f3);
  color: var(--cor-branco-fixo);
  background: linear-gradient(180deg, var(--degrade));
  background-size: 100% 100%;
  width: 100%;
  max-width: 400px;
  padding: 20px 20px;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}

.conteudo button.entrar:hover {
  background-size: 200% 200%;
}

.conteudo button.entrar:disabled {
  color: transparent;
}

.conteudo button.entrar:disabled span {
  opacity: 1;
  visibility: visible;
}

.conteudo button.entrar span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--cor-branco-fixo);
  border-top: 2px solid transparent;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: girando 0.5s linear infinite;
  pointer-events: none;
  transition: all 0.3s;
}

.conteudo .opcoes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 30px 0 0 0;
}

.conteudo .opcoes span {
  width: 2px;
  height: 25px;
  background-color: var(--cor-cinza-2);
  margin: 0 15px;
}

.conteudo .opcoes a {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  background-color: transparent;
  line-height: 1.5;
  text-align: center;
  transition: all 0.3s;
}

.conteudo .opcoes a:hover {
  color: var(--cor-azul);
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.entrar {
    flex-direction: column-reverse;
  }

  .conteudo {
    width: 100%;
    padding: 40px 30px;
    min-height: auto;
  }

  .conteudo .titulo svg {
    min-width: 120px;
    width: 120px;
  }

  .conteudo .titulo p {
    max-width: 330px;
  }

  .conteudo button.entrar {
    padding: 20px 20px;
  }
}
</style>
