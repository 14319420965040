<template>
  <div id="entrar">
    <SectionEntrar />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { helperToken } from '@helpers'
import SectionEntrar from '@components/autenticacao/entrar/partials/SectionEntrar.vue'

function bloquearRotaSeLogado() {
  if (!helperToken.detectarTokenOk()) return
  window.location.assign('/painel')
}

onMounted(() => {
  bloquearRotaSeLogado()
})
</script>

<style scoped>
#entrar {
  position: relative;
}
</style>
