<template>
  <section class="aulas">
    <div class="base" v-if="storeAulas.aulas?.length > 0"></div>
    <div class="conteudo" :class="{ menor: storeAulas.aulas?.length < 3 }">
      <div class="elemento" v-for="aula in aulasOrdenadas" :key="aula._id">
        <div class="aula">
          <div class="capa">
            <div class="tamanho">
              <div class="imagem" :style="`background-image: url('${aula.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`"></div>
            </div>
          </div>
          <div class="info">
            <h5>{{ aula.posicao }}º</h5>
            <div class="nome">
              <h3>{{ formatarTitulo(aula.nome) }}</h3>
              <p>{{ formatarDescricao(aula.descricao) }}</p>
            </div>
            <div class="item" v-if="aula.duracao">
              <Svgs nome="relogio" />
              <p>{{ aula.duracao }} minutos</p>
            </div>
            <div class="botoes">
              <button
                class="editar"
                @click="
                  abrirModalEdicao(
                    'editarAula', // nome do modal
                    'degrade-azul', // cor do icone
                    'editar', // nome do icone
                    'Editar Aula', // titulo
                    aula.nome, // subtitulo
                    aula._id // id
                  )
                ">
                <p>Editar</p>
                <Svgs nome="editar" />
              </button>
              <button class="acessar" @click="router.push(`/areas/${storeAreas.area?.hotlink}/${storeCursos.curso?.hotlink}/${storeModulos.modulo?.hotlink}/${aula.hotlink}`)">
                <p>Assistir</p>
                <Svgs nome="play" />
              </button>
              <button
                class="editar"
                @click="
                  abrirModalEdicao(
                    'duplicarAula', // nome do modal
                    'degrade-azul', // cor do icone
                    'duplicar', // nome do icone
                    'Duplicar Aula', // titulo
                    'Crie uma cópia dessa aula', // subtitulo
                    aula._id // id
                  )
                ">
                <p>Duplicar</p>
                <Svgs nome="duplicar" />
              </button>
            </div>
          </div>
        </div>
        <div class="caminho um"></div>
        <div class="caminho dois"></div>
        <div class="caminho tres"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, watch, inject, computed } from 'vue'
import { useStoreModal, useStoreAreas, useStoreCursos, useStoreModulos, useStoreAulas } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModulos = useStoreModulos()
const storeAulas = useStoreAulas()
const storeModal = useStoreModal()
const router = useRouter()
const emmiter = inject('emitter')

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}

async function carregarAulas() {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id

  if (idArea && idCurso && idModulo) {
    storeAulas.receberTodos(idArea, idCurso, idModulo)
  }
}

function formatarTitulo(titulo) {
  if (titulo.length <= 50) {
    return titulo
  }
  return titulo.substring(0, 50) + '...'
}

function formatarDescricao(descricao) {
  if (descricao.length <= 35) {
    return descricao
  }
  return descricao.substring(0, 35) + '...'
}

const telaMobile = computed(() => window.innerWidth <= 1000)

const aulasOrdenadas = computed(() => {
  if (!storeAulas.aulas) {
    return []
  }

  if (telaMobile.value) {
    return storeAulas.aulas
  }

  const aulas = storeAulas.aulas.slice()
  const ordenado = []

  for (let i = 0; i < aulas.length; i += 6) {
    ordenado.push(...aulas.slice(i, i + 3))
    ordenado.push(...aulas.slice(i + 5, i + 6))
    ordenado.push(...aulas.slice(i + 4, i + 5))
    ordenado.push(...aulas.slice(i + 3, i + 4))
  }

  return ordenado
})

watch(
  () => [storeAreas.area?._id, storeCursos.curso?._id, storeModulos.modulo?._id],
  ([idArea, idCurso, idModulo]) => {
    if (idArea && idCurso && idModulo) {
      carregarAulas()
    }
  },
  { immediate: true }
)

onMounted(() => {
  emmiter.on('atualizarAulas', carregarAulas)
})
</script>

<style scoped>
section.aulas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 30px 0 30px;
  position: relative;
}

.base {
  position: absolute;
  width: 100%;
  height: 100px;
  min-height: 100px;
  bottom: 0;
  left: 0;
  background-color: var(--cor-gelo);
  z-index: 2;
}

.conteudo {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
}

.conteudo.menor {
  justify-content: flex-start;
}

.elemento {
  display: flex;
  justify-content: center;
  position: relative;
}

.elemento:nth-child(3n + 1) {
  width: 33.33%;
  padding: 0 0 100px 0;
}

.elemento:nth-child(3n + 2) {
  width: 33.33%;
  padding: 0 0 100px 0;
}

.elemento:nth-child(3n) {
  width: 33.33%;
  padding: 0 0 100px 0;
}

.elemento:nth-last-child(1) .caminho {
  display: none;
}

.caminho {
  position: absolute;
  z-index: 1;
}

.elemento:nth-child(6n + 1) .caminho.dois {
  display: none;
}

.elemento:nth-child(6n) .caminho.dois {
  display: none;
}

/* Caminho elementos A */

.elemento:nth-child(3n + 1) .caminho.dois {
  width: 2px;
  height: 100px;
  left: 125px;
  bottom: 0px;
  background-color: transparent;
  border-left: 3px dashed var(--cor-cinza-2);
}

.elemento:nth-child(3n + 1) .caminho.tres {
  display: none;
}

.elemento:nth-child(3n + 1) .caminho.um {
  width: calc(50dvw - 530px);
  left: 250px;
  top: 200px;
  height: 2px;
  background-color: transparent;
  border-top: 3px dashed var(--cor-cinza-2);
}

/* Caminho elementos B */

.elemento:nth-child(3n + 2) .caminho.um {
  display: none;
}

.elemento:nth-child(3n + 2) .caminho.tres {
  display: none;
}

.elemento:nth-child(3n + 2) .caminho.dois {
  display: none;
}

/* Caminho elementos C */

.elemento:nth-child(3n) .caminho.um {
  display: none;
}

.elemento:nth-child(3n) .caminho.tres {
  width: calc(50dvw - 530px);
  right: 250px;
  top: 200px;
  height: 2px;
  background-color: transparent;
  border-top: 3px dashed var(--cor-cinza-2);
}

.elemento:nth-child(3n) .caminho.dois {
  width: 2px;
  height: 100px;
  right: 125px;
  bottom: 0px;
  background-color: transparent;
  border-left: 3px dashed var(--cor-cinza-2);
}

.aula {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 300px;
}

.aula .capa {
  width: 100%;
  max-width: 300px;
  z-index: 2;
}

.aula .capa .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.aula .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
}

.aula .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-branco);
  border-radius: 0 0 10px 10px;
  width: 100%;
  position: relative;
  z-index: 3;
}

.aula .info h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cor-gelo);
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-cinza);
  text-align: center;
}

.aula .info .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.aula .info .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-preto);
  line-height: 1.5;
}

.aula .info .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
}

.aula .info .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 20px 10px 20px;
}

.aula .info .item p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
}

.aula .info .item svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza);
  margin: 0 5px 0 0;
}

.aula .info .botoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px 20px 20px;
}

.aula .info .botoes button {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  width: calc(33.33% - 2.5px);
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-cinza-2);
  padding: 12px 0;
  border-radius: 10px;
  transition: all 0.3s;
}

.aula .info .botoes button:hover {
  border: 2px solid var(--cor-azul-forte);
}

.aula .info .botoes button svg {
  width: 25px;
  min-width: 25px;
  max-height: 25px;
  fill: var(--cor-preto);
  margin: 0 0 10px 0;
}

.aula .info .botoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-preto);
  transition: all 0.3s;
}

@media screen and (max-width: 1000px) {
  section.aulas {
    padding: 10px 20px 0 20px;
  }

  .base {
    bottom: -100px;
  }

  .elemento:nth-child(1) {
    padding: 0 0 0 0 !important;
  }

  .elemento:nth-child(3n + 1) {
    justify-content: center;
    width: 100%;
    padding: 100px 0 0 0;
  }

  .elemento:nth-child(3n + 2) {
    width: 100%;
    padding: 100px 0 0 0;
  }

  .elemento:nth-child(3n) {
    justify-content: center;
    width: 100%;
    padding: 100px 0 0 0;
  }

  /* Caminho */

  .caminho {
    position: absolute;
    z-index: 1;
  }

  .elemento:nth-child(6n + 1) .caminho.dois {
    display: flex;
  }

  .elemento:nth-child(6n) .caminho.dois {
    display: flex;
  }

  /* Caminho elementos A */

  .elemento:nth-child(3n + 1) .caminho.dois {
    width: 2px;
    height: 100px;
    left: auto;
    bottom: auto;
    bottom: -100px;
    border-left: 3px dashed var(--cor-cinza-2);
  }

  /* Caminho elementos B */

  .elemento:nth-child(3n + 2) .caminho.um {
    display: flex;
    width: 2px;
    height: 100px;
    bottom: -100px;
    border-left: 3px dashed var(--cor-cinza-2);
  }

  .elemento:nth-child(3n + 2) .caminho.dois {
    width: 2px;
    height: 100px;
    bottom: -100px;
    border-left: 3px dashed var(--cor-cinza-2);
  }

  /* Caminho elementos C */

  .elemento:nth-child(3n) .caminho.dois {
    width: 2px;
    right: auto;
    top: auto;
    bottom: -100px;
    height: 100px;
    border-left: 3px dashed var(--cor-cinza-2);
    border-top: none;
  }

  .aula {
    max-width: 400px;
  }

  .aula .capa {
    max-width: 400px;
  }
}
</style>
