<template>
  <div id="cursos">
    <Loading :class="{ mostrar: state.verLoading }" />

    <ModalEditarCurso />
    <ModalCriarCurso />
    <ModalDuplicarCurso />
    <ModalPosicaoCursos />

    <SectionTopo />
    <SectionPosicao />
    <SectionCursos />
  </div>
</template>

<script setup>
import { inject, onMounted, reactive, watch } from 'vue'
import { useStoreCursos, useStoreAreas } from '@stores'

import Loading from '@components/global/loading/Loading.vue'
import ModalCriarCurso from '@components/areaDeMembros/cursos/modals/CriarCurso.vue'
import ModalEditarCurso from '@components/areaDeMembros/cursos/modals/EditarCurso.vue'
import ModalDuplicarCurso from '@components/areaDeMembros/cursos/modals/DuplicarCurso.vue'
import ModalPosicaoCursos from '@components/areaDeMembros/cursos/modals/EditarPosicaoCursos.vue'

import SectionPosicao from '@components/areaDeMembros/cursos/partials/SectionPosicao.vue'
import SectionCursos from '@components/areaDeMembros/cursos/partials/SectionCursos.vue'
import SectionTopo from '@components/areaDeMembros/cursos/partials/SectionTopo.vue'

const storeCursos = useStoreCursos()
const storeAreas = useStoreAreas()
const emitter = inject('emitter')

const state = reactive({
  verLoading: true
})

async function carregarCursos() {
  const idArea = storeAreas.area?._id
  if (idArea) {
    await storeCursos.receberTodos(idArea)
    setTimeout(() => {
      state.verLoading = false
    }, 300)
  }
}

watch(
  () => storeAreas.area?._id,
  (idArea) => {
    if (idArea) {
      carregarCursos()
    }
  },
  { immediate: true }
)

onMounted(() => {
  emitter.on('atualizarCursos', carregarCursos)
})
</script>

<style scoped>
#cursos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 250px;
}

@media screen and (max-width: 1000px) {
  #cursos {
    padding: 60px 0 0 0;
  }
}
</style>
