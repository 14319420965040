<template>
  <section class="aulas">
    <div class="conteudo">
      <div class="aula" v-for="aula in storeAulas.aulas" :key="aula._id">
        <div class="capa" :style="`background-image: url('${aula.imagemCapa || 'https://arquivos.levelmember.com/imagens/default/background-vertical.svg'}')`">
          <div class="item" v-if="aula.duracao">
            <p>{{ aula.duracao }} min</p>
          </div>
        </div>
        <div class="info">
          <h5>{{ aula.posicao }}º</h5>
          <div class="nome">
            <h3>{{ formatarTitulo(aula.nome) }}</h3>
          </div>
          <div class="botoes">
            <button class="acessar" @click="router.push(`/areas/${storeAreas.area?.hotlink}/${storeCursos.curso?.hotlink}/${storeModulos.modulo?.hotlink}/${aula.hotlink}`)">
              <Svgs nome="seta" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, watch, inject } from 'vue'
import { useStoreAreas, useStoreCursos, useStoreModulos, useStoreAulas } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModulos = useStoreModulos()
const storeAulas = useStoreAulas()
const router = useRouter()
const emmiter = inject('emitter')

function formatarTitulo(titulo) {
  if (titulo.length <= 50) {
    return titulo
  }
  return titulo.substring(0, 50) + '...'
}

async function carregarAulas() {
  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id

  if (idArea && idCurso && idModulo) {
    storeAulas.receberTodos(idArea, idCurso, idModulo)
  }
}

watch(
  () => [storeAreas.area?._id, storeCursos.curso?._id, storeModulos.modulo?._id],
  ([idArea, idCurso, idModulo]) => {
    if (idArea && idCurso && idModulo) {
      carregarAulas()
    }
  },
  { immediate: true }
)

onMounted(() => {
  emmiter.on('atualizarAulas', carregarAulas)
})
</script>

<style scoped>
section.aulas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  position: relative;
  padding: 0 0 0 20px;
}

.conteudo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.aula {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 80px;
  margin: 0 0 10px 0;
}

.aula .capa {
  background-size: cover;
  background-position: center;
  border-radius: 10px 0 0 10px;
  width: 140px;
  min-width: 140px;
  position: relative;
}

.aula .capa .item {
  display: flex;
  position: absolute;
  padding: 3px;
  border-radius: 3px;
  bottom: 5px;
  right: 5px;
  background-color: var(--cor-preto-fixo);
}

.aula .capa .item p {
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-branco-fixo);
}

.aula .info {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-branco);
  border-radius: 0 10px 10px 0;
  padding: 20px;
  width: 100%;
  position: relative;
  z-index: 3;
}

.aula .info h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--cor-branco);
  border-bottom: 2px solid var(--cor-cinza-2);
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-cinza);
  text-align: center;
}

.aula .info .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 20px 0 10px;
}

.aula .info .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  line-height: 1.5;
}

.aula .info .botoes {
  display: flex;
  flex-direction: column;
}

.aula .info .botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-branco);
  border: 2px solid var(--cor-cinza-2);
  padding: 9px;
  border-radius: 10px;
  transition: all 0.3s;
}

.aula .info .botoes button:hover {
  border: 2px solid var(--cor-azul-forte);
}

.aula .info .botoes button.acessar {
  margin: 5px 0 0 0;
}

.aula .info .botoes button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-azul-forte);
}
@media screen and (max-width: 1000px) {
  section.aulas {
    width: 100%;
    padding: 20px 0 0 0;
  }

  .aula {
    min-height: 60px;
  }

  .aula .capa {
    width: 100px;
    min-width: 100px;
  }

  .aula .info {
    padding: 10px;
  }

  .aula .info h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: -15px;
    width: 30px;
    height: 30px;
    font-size: var(--f1);
  }

  .aula .info .nome {
    padding: 0 15px 0 5px;
  }

  .aula .info .nome h3 {
    font-size: var(--f1);
  }

  .aula .info .item {
    padding: 0 5px 3px 5px;
    left: -50px;
  }

  .aula .info .item p {
    font-size: var(--f0);
  }
}
</style>
