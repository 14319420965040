<template>
  <button class="perfil" @click="router.push('/perfil')">
    <div class="nome">
      <h3>{{ storePerfil.usuario?.nome.split(' ')[0] }}</h3>
      <p>{{ storePerfil.usuario?.email }}</p>
    </div>
    <div class="foto"
      :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://arquivos.levelmember.com/imagens/default/perfil-placeholder.png'}')`">
    </div>
  </button>
</template>

<script setup>
import { useStorePerfil } from '@stores'
import { useRouter } from 'vue-router'

const storePerfil = useStorePerfil()
const router = useRouter()
</script>

<style scoped>
.perfil {
  display: flex;
  align-items: center;
  background-color: transparent;
}

.perfil:hover .foto {
  transform: scale(1.1);
}

.perfil .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.perfil .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
}

.perfil .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}

.perfil .foto {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cor-cinza-2);
  background-size: cover;
  background-position: center;
  margin: 0 0 0 10px;
  transition: all 0.3s;
}


@media screen and (max-width: 1000px) {
  .perfil .nome {
    display: none;
  }

  .perfil .foto {
    width: 35px;
    height: 35px;
    margin: 0;
  }
}
</style>
