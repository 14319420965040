<template>
  <div class="excluirModal"  :class="{ mostrar: state.confirmacao }">
    <button class="excluir" @click="abrirExclusao">
      <Svgs nome="excluir" />
      <p>Excluir {{ tipo }}</p>
    </button>
    <div class="confirmacao">
      <div class="icone">
        <Svgs nome="atencao"/>
      </div>
      <p v-if="tipo === 'curso'"><span>Deseja realmente excluir esse {{ tipo }}?</span> Todos os alunos vão perder seus acessos e não será possível recuperar. É uma ação irreversível</p>
      <p v-if="tipo === 'Integração'"><span>Deseja realmente excluir essa {{ tipo }}?</span> Caso seja uma integração recorrente, quando os alunos forem renovar seus acessos não serão liberados. É uma ação irreversível</p>
      <p v-if="tipo === 'Área de Membros'"><span>Deseja realmente excluir essa {{ tipo }}?</span> Todos os cursos, integrações, alunos, módulos, personalização e aulas serão permanentemente excluídos. Essa é uma ação irreversível.</p>
      <p v-if="tipo === 'módulo'"><span>Deseja realmente excluir esse {{ tipo }}?</span> Todas as aulas serão deletadas e não será possível recuperar. É uma ação irreversível</p>
      <p v-if="tipo === 'aula'"><span>Deseja realmente excluir essa {{ tipo }}?</span> Todos os comentários e dados da aula serão excluídos permanentemente. É uma ação irreversível</p>
      <div class="botoes">
        <button class="sim" @click="confirmarExclusao()">Sim, excluir {{ tipo.split(' ')[0] }}</button>
        <button class="nao" @click="fecharExclusao()">Não, cancelar agora</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, toRefs } from 'vue'
import Svgs from '@svgs'

const props = defineProps({
  tipo: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['confirmarExclusao'])

const { tipo } = toRefs(props)

const state = reactive({
  confirmacao: false
})

function abrirExclusao() {
  state.confirmacao = true
}

function fecharExclusao() {
  state.confirmacao = false
}

function confirmarExclusao() {
  emit('confirmarExclusao')
}
</script>

<style scoped>
.excluirModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.excluirModal button.excluir {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  animation: fadeIn 0.3s linear;
}

.excluirModal button.excluir svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-vermelho);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.excluirModal button.excluir:hover svg {
  fill: var(--cor-preto);
}

.excluirModal button.excluir p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-vermelho);
  transition: all 0.3s;
}

.excluirModal button.excluir:hover p {
  color: var(--cor-preto);
}

.excluirModal.mostrar button.excluir {
  display: none;
}

.excluirModal.mostrar .confirmacao {
  display: flex;
  animation: fadeIn 0.3s linear;
}

.excluirModal .confirmacao {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: scale(0.9);
  background-color: var(--cor-gelo);
  width: 100%;
  max-width: 450px;
  bottom: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 3;
}

.excluirModal .confirmacao .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-width: 80px;
  min-height: 80px;
  height: 80px;
  border-radius: 30%;
  background-color: var(--cor-vermelho);
  animation: mexendo 15s linear infinite;
}

.excluirModal .confirmacao .icone svg {
  width: 40px;
  min-width: 40px;
  fill: var(--cor-branco-fixo);
  animation: mexendo 1s linear infinite;
}

@keyframes mexendo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.excluirModal .confirmacao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  padding: 20px 0 10px 0;
}

.excluirModal .confirmacao p span {
  font-family: var(--bold);
}

.excluirModal .confirmacao .botoes {
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
}

.excluirModal .confirmacao .botoes button {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  padding: 15px 25px;
  border-radius: 50px;
  margin: 0 5px;
  transition: all 0.3s;
}

.excluirModal .confirmacao .botoes button.sim {
  background-color: var(--cor-vermelho);
}

.excluirModal .confirmacao .botoes button.nao {
  background-color: var(--cor-verde);
}

.excluirModal .confirmacao .botoes button.sim:hover {
  background-color: var(--cor-vermelho-escuro);
}

.excluirModal .confirmacao .botoes button.nao:hover {
  background-color: var(--cor-verde-escuro);
}

@media screen and (max-width: 1000px) {
  .excluirModal .confirmacao .botoes button {
    font-size: var(--f1);
    padding: 12px 20px;
  }
}
</style>
