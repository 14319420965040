<template>
  <section class="curso">
    <div class="posicao" v-if="storeCursos.cursos?.length > 1">
      <h3>Altere a ordem dos cursos</h3>
      <button
        @click="
          abrirModal(
            'editarPosicaoCursos', // nome do modal
            'degrade-azul', // cor do icone
            'cursor', // nome do icone
            'Posição dos cursos', // titulo
            'Altere a ordem dos cursos' // subtitulo
          )
        ">
        <Svgs nome="cursor" />
        <p>Editar posição</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal, useStoreCursos } from '@stores'
import Svgs from '@svgs'

const storeCursos = useStoreCursos()
const storeModal = useStoreModal()

function abrirModal(nome, cor, icone, titulo, subtitulo) {
  storeModal.abrirModal(nome, cor, icone, titulo, subtitulo)
}
</script>

<style scoped>
section.curso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 30px 20px 30px;
}

.posicao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  border-top: 1px solid var(--cor-cinza-2);
  border-bottom: 1px solid var(--cor-cinza-2);
}

.posicao h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-preto);
  line-height: 1.5;
}

.posicao button {
  display: flex;
  align-items: center;
  padding: 0 5px 0 5px;
  background-color: transparent;
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

.posicao button:hover svg {
  fill: var(--cor-azul-forte-escuro);
}

.posicao button:hover p {
  color: var(--cor-azul-forte-escuro);
}

.posicao button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-azul-forte);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.posicao button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-azul-forte);
  transition: all 0.3s;
}

@media screen and (max-width: 1000px) {
  section.curso {
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }

  .posicao {
    padding: 15px;
  }

  .posicao h3 {
    font-size: var(--f1);
  }

  .posicao button svg {
    width: 10px;
    min-width: 10px;
    margin: 0 8px 0 0;
  }

  .posicao button p {
    font-size: var(--f1);
  }
}
</style>
